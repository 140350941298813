import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirmar-pedido',
  templateUrl: './confirmar-pedido.component.html',
  styleUrls: ['./confirmar-pedido.component.scss']
})
export class ConfirmarPedidoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
