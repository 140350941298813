import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListaClientesComponent } from './lista-clientes/lista-clientes.component';
import { InicioComponent } from './inicio/inicio.component';
import { DetalleCarritoComponent } from './detalle-carrito/detalle-carrito.component';
import { LoginComponent } from './login/login.component';
import { CategoriasComponent } from './categorias/categorias.component';
import { ProductosComponent } from './productos/productos.component';
import { BusquedaComponent } from './busqueda/busqueda.component';
import { SubcategoriasComponent } from './subcategorias/subcategorias.component';
import { QuienesSomosComponent } from './quienes-somos/quienes-somos.component';
import { FaqsComponent } from './faqs/faqs.component';
import { BasesCondicionesComponent } from './bases-condiciones/bases-condiciones.component';
import { InfoEnvioComponent } from './info-envio/info-envio.component';
import { SitioConstruccionComponent } from './sitio-construccion/sitio-construccion.component';
import { RegistroComponent } from './registro/registro.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { MontoMinimoCompraComponent } from './monto-minimo-compra/monto-minimo-compra.component';
import { EnvioDomicilioComponent } from './envio-domicilio/envio-domicilio.component';
import { RetiroTiendaComponent } from './retiro-tienda/retiro-tienda.component';
import { MediosDePagoComponent } from './medios-de-pago/medios-de-pago.component';
import { NuestrasSucursalesComponent } from './nuestras-sucursales/nuestras-sucursales.component';
import { TerminosYCondicionesComponent } from './terminos-y-condiciones/terminos-y-condiciones.component';
import { FormaParteDeNuestroEquipoComponent } from './forma-parte-de-nuestro-equipo/forma-parte-de-nuestro-equipo.component';
import { ContactoComponent } from './contacto/contacto.component';
import { PromocionesBancariasComponent } from './promociones-bancarias/promociones-bancarias.component';
import { InstruccionesPwaComponent } from './instrucciones-pwa/instrucciones-pwa.component';
import { PerfilComponent } from './perfil/perfil.component';
import { RecuperarPassComponent } from './recuperar-pass/recuperar-pass.component';
import { BarcodeComponent } from './barcode/barcode.component';
import { AppComponent } from './app.component';
const routes: Routes = [

  {
    path: 'inicio',
    component: InicioComponent
  },
  {
    path: 'bar-code',
    component: BarcodeComponent
  },
  {
    path: 'carrito',
    component: DetalleCarritoComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'registro',
    component: RegistroComponent
  },
  {
    path: 'categorias/:stringPadre/:id',
    component: CategoriasComponent
  }, 
  {
    path: 'productos/:string/:id',
    component: ProductosComponent
  },
  {
    path: 'subcategoria/:stringPadre/:idPadre/:string/:id',
    component: SubcategoriasComponent
  },
  {
  path: 'lista-clientes',
  component: ListaClientesComponent
  },
  {
    path: 'busqueda/:termino',
    component: BusquedaComponent
    },
  {
    path: 'quienes-somos',
    component: QuienesSomosComponent
    },
  {
    path: 'faqs',
    component: FaqsComponent
    },
  {
    path: 'bases-condiciones',
    component: BasesCondicionesComponent
    },
  {
    path: 'informacion',
    component: InfoEnvioComponent
  },
  {
    path: 'nosotros',
    component: NosotrosComponent
  },
  {
    path: 'monto-minimo-compra',
    component: MontoMinimoCompraComponent
  },
  {
    path: 'envio-domicilio',
    component: EnvioDomicilioComponent
  },
  {
    path: 'retiro-tienda',
    component: RetiroTiendaComponent
  },
  {
    path: 'medios-de-pago',
    component: MediosDePagoComponent
  },
  {
    path: 'nuestras-sucursales',
    component: NuestrasSucursalesComponent
  },
  {
    path: 'terminos-y-condiciones',
    component: TerminosYCondicionesComponent
  },
  {
    path: 'forma-parte-de-nuestro-equipo',
    component: FormaParteDeNuestroEquipoComponent
  },
  {
    path: 'contacto',
    component: ContactoComponent
  },
  {
    path: 'promociones-bancarias',
    component: PromocionesBancariasComponent
  },
  {
    path: 'instrucciones-pwa',
    component: InstruccionesPwaComponent
  },
  {
    path: 'perfil',
    component: PerfilComponent
  },
  {
    path: 'recuperar-contraseña',
    component: RecuperarPassComponent
  },
  {
    path: '',
    component: InicioComponent
  }
];



@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
