// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  API_URL : "http://dr.liberasoft.com.ar/api/callapi",
  API_USR : "user",
  API_PWD : "pwd",
 // esta es la api que se esta usando API_URL2 :  dev -->"http://testdrapi.liberasoft.com.ar/api", --> prod https://drapi.liberasoft.com.ar/api
  API_URL2 : "https://drapi.liberasoft.com.ar/api", // "https://drapi.liberasoft.com.ar/api",
  URL_PROD: "https://drautoservicio.com.ar",
  MP_WS : " https://demo7.syloper.com/dr/mp-ws.php",
  HASH_API : "distribucionRosarioAutoservicio-" + new Date().getFullYear()
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
