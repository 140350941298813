<div>
  <img class="producto-sin-stock img-fluid" src="../../assets/img/DRA_Placa-sin-stock.png" *ngIf="producto.EstadoID == 2"/>
</div>
<div [class]="producto.EstadoID == '2' ? 'isDisabled' : ''">
<section  class="d-none d-md-block" *ngIf="!mobile && !horizontal" (window:resize)="onResize($event)">
    <div class="row justify-content-center cuerpo" style="padding-bottom: 4px;">
        <div  *ngIf="producto.EstadoID !== '2'" style="pointer-events: initial !important;" class="col-12 text-right d-flex justify-self-center">
      
 
            <span style="cursor: pointer;" class="target_ws_left d-lg-inline font-italic " data-toggle="tooltip"  title="ATENCIÓN: Esta es la cantidad por pack por la que el producto viene de fabrica, NO es la cantidad mínima de venta.">
              
              <i style="font-size: 15px !important;" class="fas fa-info-circle text-green"></i>
              Pack x {{producto.prdCantidad}}</span>

         
        
            <a  [href]="sanitizarUrl(producto.prdNombre, producto.prdProductoId)" target="_blank" class="target_ws d-lg-inline" data-action="share/whatsapp/share">
              <i  class="fas fa-share-alt img-fluid shareProdIcon"></i>
          </a>

 


                   
          
        
        
          </div>
     
        <!--<span class="font-weight-bold" style="font-size: 10px; color: gray;">20%</span> -->
     
        <img 
        class="oferta img-fluid"
        src="../../assets/img/Oferta-06.png"
        *ngIf="producto.TieneOfertaSemanal == 1"
         />
        <div class="col-12 text-center d-flex justify-self-center" (click)="goProducto(producto.prdNombre, producto.prdProductoId)">
         
            <img class="oferta-especial img-fluid" src="../../assets/img/desc-especial.png" *ngIf="producto.PrecioDestacado && producto.PrecioDestacado != 0.00"/>
            <img *ngIf="loading" src="/assets/img/loading.gif" class="gif" alt="loading" />
            <img class="mx-auto img-producto" [hidden]="loading" (load)="onLoad()" src="{{producto.img}}" onError="this.src='../../../assets/img/DR-09.png'" />
           
          </div>
        <div class="col-12 text-md-center justify-content-center">
     
      
       
            <div class="text-md-left">
              <span class="font-weight-bold" style="font-size: 10px; color: gray;">{{producto.CODIGO}}</span>
              <div style="min-height: 24px;">
                <span class="producto">{{ producto.Nombre }}</span>
              </div>
                <span class="marca">{{ producto.Marca }}</span>
             
            </div>
         
            <div class="d-flex justify-content-between align-items-center" style="line-height: 0px;">
                <span class="contenido text-left ">{{ producto.Presentacion }}</span>
              
                <div class="d-inline text-right"  style="width: 75%; margin-bottom: 2px;">
                  <span [ngClass]="{'precioTachado':  this.producto?.precio_anterior}" class="producto-item-precio precio text-center font-weight-bold py-2 text-right">{{producto.PrecioConIVA | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
                </div>
            </div>
            <!--  *ngIf="producto.prcDto !== '0'"    *ngIf="producto.prcDto !== '0'"   background-color: #28a745; -->
            <span 
            *ngIf="producto.prcDto !== 0"
            style="
            font-weight: 550;"
            class="producto text-right"
            >
            <span  style="
            color: #ed6c0a; 
           
            line-height: 12px;
            font-size: 12px;
            border-radius: 0.2rem;
            
            ">
            Dto -{{producto.prcDto}}%
            </span>
          
          </span>
        </div>
        <div class="col-12 text-center d-flex justify-content-center">
            <div class="row justify-content-center w-100" style="min-height: 35px;">
                <!-- <ng-container *ngIf="!producto.precios.length && producto.PrecioDestacado == 0.00 && producto.oferta_semanal.length > 0 ">
                  <div  class="slide-item-img" style="width: 50%!important;">
                    <span class="sugerencia producto-item-precio text-center badge badge-secondary py-2 my-1">x3u. {{producto.PrecioConIVA | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
                  </div>
                  <div  class="slide-item-img" style="width: 50%!important;">
                    <span class="sugerencia producto-item-precio text-center badge badge-primary py-2 my-1">x12u. {{producto.PrecioConIVA | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
                  </div>
                </ng-container> -->
                  
                      
                    


                <!-- Precios promo -->
                <ng-container *ngFor="let precios of producto.precios; let i = index">
                    <div  class="slide-item-img" *ngIf="producto.precios.length > 0 && producto.PrecioDestacado == 0.00" 
                      style="width: 50%!important;"
                      [ngStyle]="{'width':  producto.precios.length == 1 ? '100%': '50%'}"
                      >
                        <span class="sugerencia producto-item-precio text-center badge badge-secondary py-2 my-1" *ngIf="i == 0 ">x<strong>{{precios.prcCantidad}}u.</strong> {{producto.PrecioConIVA * (1 - precios.prcDto / 100) | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
                        <span class="sugerencia producto-item-precio text-center badge badge-primary py-2 my-1" *ngIf="i == 1"  
                       
                        >x{{precios.prcCantidad}}u. {{producto.PrecioConIVA * (1 - precios.prcDto / 100) | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
                        <span class="sugerencia producto-item-precio text-center badge badge-secondary py-2 my-1" *ngIf="i == 2">x{{precios.prcCantidad}}u. {{producto.PrecioConIVA * (1 - precios.prcDto / 100) | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
                      </div>
                      <!--
                    <div  class="slide-item-img" *ngIf="producto.precios.length == 1 && producto.PrecioDestacado == 0.00" style="width: 50%!important;">
                      <span class="sugerencia producto-item-precio text-center badge badge-primary py-2 my-1">{{sumarTres(precios.prcCantidad)}}u. {{producto.PrecioConIVA * (1 - precios.prcDto / 100) | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
                    </div>
                  -->
                </ng-container>
                <!-- Precio destacado - descuento especial -->
                <ng-container  *ngIf="producto.PrecioDestacado && producto.PrecioDestacado != 0.00    ">
                  <div class="btn-block">
                    <span class="sugerencia  text-center badge badge-primary btn-block py-2 my-1" id="oferta-especial">x 1u o más <strong>{{ producto.PrecioDestacado | currency: 'ARS':'symbol':undefined:'es-AR' }}</strong></span>
                  </div>
                </ng-container>
                <!-- Oferta semanal -->
                <ng-container  *ngIf="producto.TieneOfertaSemanal == 1">
                  <div *ngFor="let item of producto.oferta_semanal" class="btn-block">
                    <span class="sugerencia  text-center badge badge-primary btn-block py-2 my-1" id="oferta-semanal">Oferta <label> X{{ item.Cant}}U</label> <strong>{{ item.Precio | currency: 'ARS':'symbol':undefined:'es-AR' }}</strong></span>
                  </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row justify-content-center" >
       <!-- PRODUCTO CARD CATEGORIA INPUT -->
        <div class="col-12">
            <button 
            type="button" 
            class="btn btn-primary btn-block py-2"
             style="margin-top: 2px;" 
             *ngIf="this.noClickeado"
         (click)="addProducto(producto)"
             >
                AGREGAR
            </button>
            <div class="row justify-content-center">
                <div class="col-12">
                  <!-- *ngIf="!this.noClickeado" -->
                    <div  *ngIf="producto.EsDeBalanza !== '1' && !this.noClickeado ? true : false" class="unidades d-flex justify-content-between align-items-center" >
                        <span class="label">Agregar</span>
                        <div class="ml-auto d-inline contenedor">
                            <button class="minus" id="minus" (click)="minusQty(producto.prdProductoId, 1, producto.prdNombre)">-</button>
                            <input class="num" id="{{producto.prdNombre }}" 
                            (keydown)="inputValidator($event)" 
                            (keyup.enter)="enterChange(producto.prdProductoId, $event.target.value)" 
                            name="quantity" 
                            [value]="Qty()" 
                            type="number" 
                            (blur)="enterChange(producto.prdProductoId, $event.target.value)">
                            <button class="plus" (click)="plusQty(producto.prdProductoId, 1, producto.prdNombre, producto)">+</button>
                        </div>
                    </div>
                    <!-- AGREGAR PRUDUCTOS X GRAMOS END -->
                    <div *ngIf="!this.noClickeado  && producto.EsDeBalanza == '1' ? true : false"
                     class="unidadesGramos d-flex justify-content-between align-items-center">
                      <span class="label">Kilos </span>
                      <div class="ml-auto d-inline contenedor">
                          <button 
                          class="minus"
                           id="minus" 
                          (click)="minusQty(producto.prdProductoId, 0.1, producto.prdNombre)"
                          >-</button>
                          <input
                          readonly 
                          id="{{producto.prdNombre }}"
                          class="num" 
                          name="quantity"
                          [value]="Qty()"
                          (change)="Qty()"
                          type="number" 
                          >
                          <button class="plus" 
                          (click)="plusQty(producto.prdProductoId, 0.1, producto.prdNombre, producto)">+</button>
                      </div>
                  </div>
                  <!-- AGREGAR PRUDUCTOS X GRAMOS END -->
                </div>
            </div>
        </div>
          <!-- PRODUCTO CARD CATEGORIA INPUT END-->
    </div>
</section>
<!--PRODUCTO MOBILE -->
 <section class="d-block d-md-none" *ngIf="mobile  && !horizontal" (window:resize)="onResize($event)">
    <div class="card" >
      <span style="font-style: italic !important; margin-left: 1rem !important;" class="contenido" data-toggle="tooltip"  title="ATENCIÓN: Esta es la cantidad por pack por la que el producto viene de fabrica, NO es la cantidad mínima de venta."> <i style="font-size: 15px !important;" class="fas fa-info-circle text-green"></i> Pack x {{producto.prdCantidad}}</span> 
      <a [href]="sanitizarUrlMobile(producto.prdNombre, producto.prdProductoId)" target="_blank" class="target_ws d-lg-inline mobile-share" data-action="share/whatsapp/share">
            <i class="fas fa-share-alt img-fluid"></i>
        </a>
      
        <img class="oferta img-fluid" src="../../assets/img/Oferta-06.png" *ngIf="producto.TieneOfertaSemanal == 1"/>
        <img class="oferta-especial img-fluid" src="../../assets/img/desc-especial.png" *ngIf="producto.PrecioDestacado && producto.PrecioDestacado != 0.00"/>
        <div class="container">
          <div class="row">
            <div class="col-4 p-0 text-center d-flex justify-content-center align-items-center"  (click)="goProducto(producto.prdNombre, producto.prdProductoId)">
              <img class="mx-auto img-producto" src="{{producto.img}}" onError="this.src='../../../assets/img/DR-10.png'" />
            </div>
            <div class="col-8">
              <div class="row h-100">
                <div class="col-12 px-1">
                  <div class="row mx-2">
                    <div class="col-12  d-inline d-md-none p-0">
                      <span class="font-weight-bold" style="font-size: 10px; color: gray;">{{ producto.CODIGO}}</span>
                      <span class="producto">{{producto.Nombre }}</span>
                      <span class="producto d-none d-md-inline">{{producto.Nombre }}</span>
                      <span class="font-weight-bold marca">{{ producto.Marca}}</span>
                    </div>
                   
                            
                    <div class="col-6 p-0"> 
                        <span class="contenido">{{ producto.Presentacion }}</span>
                   

                      </div>
                    
                   
                    <div class="col-6 d-flex align-items-end p-0">
                      <span class="precio ml-auto" [ngClass]="{'precioTachado':  producto?.precio_anterior}">{{ producto.PrecioConIVA | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
                    </div>
                    <span 
                    *ngIf="producto.prcDto !== 0" 
                    style="
                    font-weight: 550;"
                    class="producto text-right col-12 p-0"
                    >
                    <span  style="
                    color: #ed6c0a; 
                   
                    line-height: 12px;
                    font-size: 12px;
                    border-radius: 0.2rem;">
                    Dto -{{producto.prcDto}}%
                    </span>
                    </span>
                    <ng-container *ngIf="producto.precios.length > 0 && producto.PrecioDestacado == 0.00"  >
                      <div class="col-6 px-0" [ngClass]="{'col-12': producto['precios'].length == 1}" *ngFor="let i of producto['precios']; let j = index" >
                        <span class="sugerencia producto-producto-precio text-center badge badge-secondary py-2 my-1" *ngIf="j == 0" >x{{i.prcCantidad}}u. {{producto.PrecioConIVA * (1 - i.prcDto / 100) | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
                        <span class="sugerencia producto-producto-precio text-center badge badge-primary py-2 my-1" *ngIf="j == 1">x{{i.prcCantidad}}u. {{producto.PrecioConIVA * (1 - i.prcDto / 100) | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
                      </div>
                    </ng-container>
                    <ng-container  *ngIf="producto.PrecioDestacado && producto.PrecioDestacado != 0.00">
                      <div class="btn-block">
                        <span class="sugerencia  text-center badge badge-primary btn-block py-2 my-1" id="oferta-especial">x 1u o más <strong>{{ producto.PrecioDestacado | currency: 'ARS':'symbol':undefined:'es-AR' }}</strong></span>
                      </div>
                    </ng-container>
                    <ng-container  *ngIf="producto.TieneOfertaSemanal == 1">
                      <div class="btn-block" *ngFor="let item of producto.oferta_semanal">
                        <span class="sugerencia  text-center badge badge-primary btn-block py-2 my-1" id="oferta-semanal">Oferta <label> X{{ item.Cant}}U</label> <strong>{{ item.Precio | currency: 'ARS':'symbol':undefined:'es-AR' }}</strong></span>
                      </div>
                    </ng-container>
                         <!-- PRODUCTO Mobile -->
                    <div class="col-12 my-0 px-0" style="margin-top: 5px!important;">     
                <button type="button" class="btn btn-primary btn-block" style="padding: 5px 0!important;" *ngIf="this.noClickeado" (click)="addProducto(producto)">
                  AGREGAR
              </button>
              <div class="row justify-content-center">
                  <div class="col-12">
                      <div *ngIf="producto.EsDeBalanza !== '1' && !this.noClickeado ? true : false"
                       class="unidades d-flex justify-content-between align-items-center" >
                       
                        <span class="label">Agregar</span>
                          <div class="ml-auto d-inline contenedor">
                              <button class="minus" id="minus" (click)="minusQty(producto.prdProductoId, 1, producto.prdNombre)">-</button>
                              <input class="num" id="{{producto.prdNombre }}" (keydown)="inputValidator($event)"
                               (keyup.enter)="enterChange(producto.prdProductoId, $event.target.value)"
                                name="quantity" [value]="Qty()"
                                 type="number"
                                 (blur)="enterChange(producto.prdProductoId, $event.target.value)">
                              <button class="plus" 
                              (click)="plusQty(producto.prdProductoId, 1, producto.prdNombre, producto)"
                              >+</button>
                          </div>
                      </div>
<!-- Producto X Gramos -->
                      <div *ngIf="!this.noClickeado  && producto.EsDeBalanza == '1' ? true : false"
                       class="unidadesGramos d-flex justify-content-between align-items-center" >
                        <span class="label">Agregar</span>
                          <div class="ml-auto d-inline contenedor">
                              <button class="minus" id="minus" (click)="minusQty(producto.prdProductoId, 0.1, producto.prdNombre)">-</button>
                              <input 
                              class="num" 
                              id="{{producto.prdNombre }}" 
                              (keydown)="inputValidator($event)"
                               (keyup.enter)="enterChange(producto.prdProductoId, $event.target.value)"
                                name="quantity"
                                [value]="Qty()"
                                 type="number"
                                 readonly 
                                 (change)="Qty()"                                                                                                                
                                 (blur)="enterChange(producto.prdProductoId, $event.target.value)">
                                 <button class="plus" 
                              (click)="plusQty(producto.prdProductoId, 0.1, producto.prdNombre, producto)"
                              >+</button>
                          </div>
                      </div>
<!-- Producto X Gramos END -->
                  </div>
              </div>




                   <!--
                      <button type="button" class="btn btn-primary btn-block" style="padding: 5px 0!important;" *ngIf="this.noClickeado" (click)="addProducto(producto)">
                          AGREGAR
                      </button>
                      <div class="row justify-content-center">
                          <div class="col-12">
                              <div class="unidades d-flex justify-content-between align-items-center" *ngIf="!this.noClickeado">
                               

                                <span class="label">Agregar2</span>
                                  <div class="ml-auto d-inline contenedor">
                                      <button class="minus" id="minus" (click)="minusQty(producto.prdProductoId, 0.1, producto.prdNombre)">-</button>
                                      <input class="num" id="{{producto.prdNombre }}" (keydown)="inputValidator($event)" (keyup.enter)="enterChange(producto.prdProductoId, $event.target.value)" name="quantity" [value]="Qty()" type="number" (blur)="enterChange(producto.prdProductoId, $event.target.value)">
                                      <button class="plus" (click)="plusQty(producto.prdProductoId, 0.1, producto.prdNombre, producto)">+</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                    -->
                  
                  
                    </div>
                       <!-- PRODUCTO ?? END-->
                  </div>
                </div>
                <!-- <div class="col-6 d-flex align-productos-md-end px-1 pb-md-3" style="flex-direction: column-reverse;">
                  <div class="w-100">
                    <div class="unidades">
                      <button class="minus" id="minus" (click)="minusQty(producto.prdProductoId, 1, producto.prdNombre, producto)">-</button>
                      <input class="num" id="{{producto.prdNombre}}"   name="quantity" value="{{ producto.qty | number}}" type="number" ng-model="producto.qty">
                      <button class="plus" (click)="plusQty(producto.prdProductoId, 1, producto.prdNombre, producto)">+</button>
                    </div>
                  </div>
                  <div class="justify-content-around d-flex suma w-100">
                    <i class="fas fa-trash-alt" (click)="(producto.prdProductoId)"></i>
                    <span class="font-weight-bold">{{ this.totalByproducto(producto.prdProductoId) }}</span>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

</section>
<section class="producto-individual w-100" *ngIf="horizontal" (window:resize)="onResize($event)">
  <div class="card">
    <img class="oferta img-fluid" src="../../assets/img/Oferta-06.png" *ngIf="producto.TieneOfertaSemanal == 1"/>
      <img class="oferta-especial img-fluid" src="../../assets/img/desc-especial.png" *ngIf="producto.PrecioDestacado && producto.PrecioDestacado != 0.00"/>
        

    <div class="container">
      <div class="row">
        <div class="col-4 p-0 text-center d-flex justify-content-center align-items-center">
          <img class="mx-auto img-producto img-individual" src="{{producto.img}}" onError="this.src='../../../assets/img/DR-10.png'" />
        </div>
        <div class="col-8 p-0">
          <div class="row h-100">
            <div class="col-12 d-inline d-md-none p-0">
              <span style="font-size: 10px;">{{ producto.prdCodigo }}</span>
              <span class="producto">{{ producto.Nombre}}</span>
            </div>
            <div class="col-6 px-1 info">
              <div>

            
                <span class="producto d-none d-md-inline">{{ producto.Nombre}}</span>
                <span class="font-weight-bold marca">{{ producto.Marca }}</span>
                <span class="contenido">{{ producto.Presentacion }}</span>
                <span class="contenido" data-toggle="tooltip"  title="ATENCIÓN: Esta es la cantidad por pack por la que el producto viene de fabrica, NO es la cantidad mínima de venta."> <i style="font-size: 15px !important;" class="fas fa-info-circle text-green"></i> Pack x {{producto.prdCantidad}}</span>

                <br>
                <span class="precio" [ngClass]="{'precioTachado':  producto?.precio_anterior}" >{{ producto.PrecioConIVA | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
              </div>
              <span 
              *ngIf="producto.prcDto !== 0" 
              style="
              font-weight: 550;"
              class="producto text-left"
              >
              <span  style="
              color: #ed6c0a; 
             
              line-height: 12px;
              font-size: 12px;
              border-radius: 0.2rem;
              
              ">
              Dto -{{producto.prcDto}}%
              </span>
              </span>
              <div class="d-flex align-items-end text-dark" style="min-height: 70px;" [ngStyle]="{'flex-direction': producto['precios'].length == 1 ? 'initial' : (producto['precios'].length > 1 ? 'column' : null) }">
                <ng-container  *ngIf="producto.precios.length > 0 && producto.PrecioDestacado == 0.00" >
                  <ng-container *ngFor="let i of producto['precios']; let j = index" >
                    <span class="sugerencia w-100 producto-item-precio text-center badge badge-secondary " style="padding: .75rem; max-height: 33px;" *ngIf="j == 0">x{{i.prcCantidad}}u. {{producto.PrecioConIVA * (1 - i.prcDto / 100) | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
                    <span class="sugerencia w-100 producto-item-precio text-center badge badge-primary " style="padding: .75rem;" *ngIf="j == 1">x{{i.prcCantidad}}u. {{producto.PrecioConIVA * (1 - i.prcDto / 100) | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
                  </ng-container>
                </ng-container>
                <!--  -->
                <ng-container  *ngIf="producto.PrecioDestacado && producto.PrecioDestacado != 0.00">
                  <div class="btn-block">
                    <span class="sugerencia  text-center badge badge-primary btn-block py-2 my-1" id="oferta-especial">x 1u o más <strong>{{ producto.PrecioDestacado | currency: 'ARS':'symbol':undefined:'es-AR' }}</strong></span>
                  </div>
                </ng-container>
                <ng-container  *ngIf="producto.TieneOfertaSemanal == 1">
                  <div class="btn-block" *ngFor="let item of producto.oferta_semanal">
                    <span class="sugerencia  text-center badge badge-primary btn-block py-2 my-1" id="oferta-semanal">Oferta <label> X{{ item.Cant}}U</label> <strong>{{ item.Precio | currency: 'ARS':'symbol':undefined:'es-AR' }}</strong></span>
                  </div>
                </ng-container>
                <!--  -->
              </div>
            </div>

              <!-- PRODUCTO DETALLE INPUT -->
            <div class="col-6 d-flex align-items-md-end px-1" style="flex-direction: column-reverse;">
              <button  type="button" class="btn btn-primary btn-block py-2 individual d-flex justify-content-center align-items-center"
               style="margin-top: 2px;" *ngIf="this.noClickeado" (click)="addProducto(producto)">
                AGREGAR 
              </button>
              <div class="row justify-content-center">
                <div class="col-12 ">
                  <div  *ngIf="producto.EsDeBalanza !== '1' && !this.noClickeado ? true : false"
                   class="unidades d-flex justify-content-between align-items-center" >
                    <span class="label" style="font-size: small;">Agregar</span>
                    <div class="ml-auto d-inline contenedor">
                        <button class="minus" id="minus" (click)="minusQty(producto.prdProductoId, 1, producto.prdNombre)">-</button>
                        <input class="num" id="{{producto.prdNombre }}" 
                        (keydown)="inputValidator($event)" 
                        (keyup.enter)="enterChange(producto.prdProductoId, $event.target.value)" 
                        name="quantity" 
                        [value]="Qty()" 
                        type="number" 
                        (blur)="enterChange(producto.prdProductoId, $event.target.value)">
                        <button class="plus" (click)="plusQty(producto.prdProductoId, 1, producto.prdNombre, producto)">+</button>
                    </div>
                </div>
                <!-- AGREGAR PRUDUCTOS X GRAMOS END -->
                <div *ngIf="!this.noClickeado  && producto.EsDeBalanza == '1' ? true : false"
                 class="unidadesGramos d-flex justify-content-between align-items-center">
                  <span class="label">Kilos </span>
                  <div class="ml-auto d-inline contenedor">
                      <button 
                      class="minus"
                       id="minus" 
                      (click)="minusQty(producto.prdProductoId, 0.1, producto.prdNombre)"
                      >-</button>
                      <input
                      readonly 
                      id="{{producto.prdNombre }}"
                      class="num" 
                      name="quantity"
                      [value]="Qty()"
                      (change)="Qty()"
                      type="number" 
                      >
                      <button class="plus" 
                      (click)="plusQty(producto.prdProductoId, 0.1, producto.prdNombre, producto)">+</button>
                  </div>
              </div>
                </div>
              </div>
            </div>
            <!-- PRODUCTO DETALLE INPUT END -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</div>