import { Component, OnInit, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { CategoriasService } from '../services/categorias.service';
import { CarritoSharedService } from '../services/carrito-shared.service';
import { ProductosService } from '../services/productos.service';
import { forkJoin, Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil, map, finalize } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
export let browserRefresh = false;
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgSelectComponent } from '@ng-select/ng-select';
@Component({
  selector: 'app-subcategorias',
  templateUrl: './subcategorias.component.html',
  styleUrls: ['./subcategorias.component.scss']
})
export class SubcategoriasComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  public categorias = [];
  public categoriasPadre = [];
  public productos = [];
  public id:string;
  public string:string;
  public idPadre:string;
  public stringPadre:string;
  private noClickeado = true;
  public singleSlideOffset = true;
  public noWrap = true;
  itemsPerSlide: number;
  innerWidth: any;
  public hover: boolean = false;
  //Variables para scroll infinite  
  public arregloProd: Array<object>;
  private finishPage = 15;
  private actualPage: number;  	
  public showGoUpButton: boolean;
  showScrollHeight = 400;
  hideScrollHeight = 200;
  public selectedProduct = '0';
  limit = 40;
  offset = 0;
  private destroy = new Subject<void>();
  firstTime= true;
  isSafari: boolean;
  noProducts: boolean = false;
  subCatloading: boolean = false;
  subCat40loading: boolean = false;

mobileMode = 0;

  multiSelectCustomClass = false;
  
  //dialog modal
  modalRef: BsModalRef;
 //MultiSelect
 multiSelectList = [];
selectedItems = [];
str_marcas = '';
filterActive: boolean = false;
  constructor(
    private Router : Router,
    private categoriasService: CategoriasService,
    private carritoService: CarritoSharedService,
    private ProductosService: ProductosService,
    private active: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private devSvc: DeviceDetectorService,
    private modalService : BsModalService,
    ) {     
      this.Router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
    }; 
      //Scrol infinite
      this.actualPage = 0;
      this.showGoUpButton = false;
      this.arregloProd = new Array<object>();
      //this.loader();
      //---- o ---
      this.active.paramMap.subscribe(ParamMap => {
        this.stringPadre = ParamMap.get('stringPadre');
        this.idPadre = ParamMap.get('idPadre');
        this.string = ParamMap.get('string');
        this.id = ParamMap.get('id');
      });
    }
    @HostListener('window:scroll', [])
    onWindowScroll() {
      if ((window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop) > this.showScrollHeight) {
        this.showGoUpButton = true;
      } else if (this.showGoUpButton &&
        (window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop) <
        this.hideScrollHeight) {
        this.showGoUpButton = false;
      }
    }
    // @HostListener('mouseenter') 
    // onMouseEnter() {
    //   this.hover = true;
    // }
  
    // @HostListener('mouseleave') 
    // onMouseLeave() {
    //   this.hover = false;
    // }

  ngOnInit(): void {
  
    
    this.noProducts = false;
    this.isSafari = this.getBrowser();
   
    this.inicio('0');    
   var currentWidth = window.outerWidth;

    if(currentWidth <= 992) {
      this.mobileMode = 1;
    }else {
      this.mobileMode = 0;
    }
    /*
    this.active.paramMap //Escucha cambios de ruta
    .pipe(
      map(paraMap => paraMap.get('stringPadre')), //Obtiene la ruta actual
      takeUntil(this.destroy) //Siempre limpia
    )
    .subscribe(routePathParam => this.categoriasService.actualizarPAthParam(routePathParam));
    */
    //actualizar servicio
    this.onGetSubCatMarcas();
  }



  onGetSubCatMarcas() {
    this.ProductosService.getMarcasSubCategorias(this.id).subscribe(resp => {
   
    let arr_temp =  [];
    resp['data'].map(m => {
     let subCatObj = {
        name: m.marDescripcion,
        id: m.marMarcaId
      } 
      arr_temp.push(subCatObj);
    });
    this.multiSelectList = arr_temp;
    });
  }



  onFilter() {
    this.selectedProduct = '0';
    this.offset = 0;
    this.limit = 40;
    this.firstTime = true;
     // Call to clear
     if(this.mobileMode == 0) {
      this.inicio('0', 999999); 
      this.ngSelectComponent.close();   
    }
     if(this.mobileMode == 1) {
      this.inicio('0', 999999); 
      this.modalRef.hide();
     }
   
  }
 
  onItemSelect(item: any) {
    $('.ng-clear-wrapper').removeAttr( "title" );
   
    let arr_temp = [];
    item.map(m => {
      arr_temp.push(m.id) 
    });
    this.str_marcas = arr_temp.join(',');
  
     if(item.length !== 0) {
      this.filterActive = true;
    }else {
      this.filterActive = false;
    }
  }
  onFilterRemove(e) {

    this.offset = 0;
    this.firstTime = true;
    this.limit = 40;
    this.actualPage = 0;
    this.inicio(this.offset, this.limit);
  }

  onSelectAll(items: any) {
   // console.log(items);
  }
  onMultiSelectClear() {
    this.actualPage = 0;
    this.offset = 0;
    this.limit = 40;
    this.firstTime = true;
    this.actualPage = -1;
   
    this.inicio(this.selectedProduct);
    if(this.mobileMode == 0) {
    this.ngSelectComponent.close();
    }
    if(this.mobileMode == 1) {
      //this.inicio(0, this.offset, this.limit);
      this.modalRef.hide();
    }
  }




  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.categoriasService.actualizarPAthParam(null);
  }

  async inicio(orden: any = 0, filtered?){
    //this.loader();
    this.spinner.show();
    this.subCat40loading = true;
    this.innerWidth = await window.innerWidth;
    //Load Categorias
    await this.itemSlideResponsive( this.innerWidth );
    //cambiar este metodo
    this.categoriasService.getAllSubCategorias(this.idPadre)
    .subscribe((data: any) => {  
      this.categorias = data['data'];
      if(filtered == 1) {
        this.offset = 0;
        this.limit = 10;
      }
     
      this.ProductosService.getAllBySubCat(this.id, orden, this.offset, this.limit, this.str_marcas)
      .pipe(finalize(() => { 
         this.spinner.hide();
        this.subCat40loading = false 
       }))
      .subscribe((data: any) => { 
       // console.log(data);
        
      

        //cada posición de data[i] coincide con las posiciones del array que mandamos con forkjoin
        this.productos = data['data'];
         //Scroll infinite
       
        if (this.productos.length > 0) {

          this.noProducts = false;
          this.subCatloading = true;
          this.arregloProd = new Array<object>();
          this.add40items();
        }
        if(this.productos.length == 0) {

          this.subCatloading = false;
          this.noProducts = true;
          this.arregloProd  = [];
        }

        
      });
    });
    //get productos x categorias
    this.categoriasService.getAll().subscribe(data => {
      this.categoriasPadre = data['data']; //cada posición de data[i] coincide con las posiciones del array que mandamos con forkjoin
      this.categoriasPadre = this.categoriasService.formatearArreglo(this.stringPadre, this.categoriasPadre);        
    });
  }

  goSubCat(seo,id){
    this.Router.navigateByUrl('/subcategoria/' + this.stringPadre + '/' + this.idPadre +'/' + seo + '/' + id);
  }
  goCat(seo, id){
    this.Router.navigateByUrl('/categorias/' + seo + '/' +id);
  }
  itemSlideResponsive(width) {
    if (width > 768) {
      // window width is at least 768px
      this.itemsPerSlide = 6;
    } else {    
      this.itemsPerSlide = 3;    
    }
  }
    //Scroll infinite
  //Scroll infinite
  async add40items() {
   // this.loader();

    await this.crearArray();
   
  }
  onScroll() {
   
    if (this.actualPage < this.finishPage) {
      if(this.subCat40loading == false) {
       
        this.add40items();
      }
    } else {
    }
  }

  crearArray(){
    if(this.productos.length <= this.limit ){

      // let lineCounter = this.arregloProd.length;
      // for (let i = 0; i < this.limit; i ++) {
      //   this.arregloProd.push(this.productos[i+lineCounter]);
      // }
      if(this.firstTime){
        this.arregloProd = this.arregloProd.concat(this.productos);
      }
      this.offset+=this.limit; 
      //console.log(this.offset);
      this.ProductosService
      .getAllBySubCat(this.id, this.selectedProduct, this.offset ,this.limit, this.str_marcas)
      .subscribe( resp=> {
        this.productos = resp['data']; 
        this.arregloProd = this.arregloProd.concat(this.productos);
        this.firstTime = false;
        this.finishPage = Math.trunc(this.arregloProd.length/this.limit);
        this.actualPage++;
      });
    }
    else {
      this.arregloProd = this.productos;

    }
  }
    scrollTop() {
      document.body.scrollTop = 0; // Safari
      document.documentElement.scrollTop = 0; // Other
    }
/*
    loader(){
      spinner starts on init 
     this.subCat40loading = true;
    this.spinner.show();
    setTimeout(() => {
       this.spinner.hide();
      this.subCat40loading = false;
     }, 1000);
   }
*/

    ChangingValue(event){
      this.offset = 0;
      this.actualPage = 0;
      this.firstTime = true;
      this.inicio(this.selectedProduct);
    }
    
    getBrowser() {
      const info = this.devSvc.getDeviceInfo();
      if ( info.browser === 'Safari' ) {
        //console.log('browser: ', info.browser);
        return true;
      }
      else {
        return false;
      }
    }

    onDialogOpen(smModal: TemplateRef<any>){
     
      // this.modalRef = this.modalService.show(smModal);
       this.modalRef = this.modalService.show(smModal, {
         class: 'fixed-top'
       });
     }

     



}
