import { Component, OnInit, Input,Output, EventEmitter, AfterViewInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ThrowStmt } from '@angular/compiler';
import { ProductosService } from '../services/productos.service';
import { from, of, forkJoin } from 'rxjs';
import { indexOf } from 'lodash';
import { CarritoSharedService } from '../services/carrito-shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-producto-item',
  templateUrl: './producto-item.component.html',
  styleUrls: ['./producto-item.component.scss'],
})
export class ProductoItemComponent implements OnInit {
 

  public productos = [];
  public masvendidos = [];
  public innerWidth: any;
  public itemsPerSlide: any;
  public loading: boolean = true;
  public ofertasSemanales: boolean = true;
  constructor(
    private productosService : ProductosService,
    private carritoService : CarritoSharedService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { 
    // this.router.url
    console.error(this.router.url);
    if(this.router.url == '/') {
      this.ofertasSemanales = false;
    }
  }

  ngOnInit() {
   this.inicio();
  }


  onNavigateOfertas() {
    this.router.navigate(['/inicio', {url: 'ofertas'}])
  }
  
  ngOnChanges() {
    let httpCalls = forkJoin( //permite hacer varias llamadas de una
      this.productosService.getDestacados(),
      this.productosService.getMasVendidos()
    );
   
    httpCalls.subscribe( //para cualquier respuesta 
      (data: any) => {
        this.productos = data[0].data; //cada posición de data[i] coincide con las posiciones del array que mandamos con forkjoin
        this.masvendidos = data[1].data;

      },
      (error) => { //en caso de error http
      
      }
    );
    
  }
  async inicio(){
    
    await this.spinner.show();
    this.innerWidth = await window.innerWidth;
    await this.itemSlideResponsive( this.innerWidth );
  
    let httpCalls = await forkJoin( //permite hacer varias llamadas de una
      this.productosService.getofertasemanal(),
      this.productosService.getMasVendidos()
    );
   
    await httpCalls.subscribe( //para cualquier respuesta 
      (data: any) => {
        this.productos = data[0].data; //cada posición de data[i] coincide con las posiciones del array que mandamos con forkjoin
        this.masvendidos = data[1].data;
        this.loading = false;
       // this.spinner.hide();
      },
      (error) => { //en caso de error http
        this.loading = false;
       // this.spinner.hide();
      }
    );
    
  }

  addProducto(producto) {
    this.carritoService.agregarProducto(producto);
  }
  itemSlideResponsive(width) {
    
    if (width > 768) {
      // window width is at least 768px
      this.itemsPerSlide = 4;
    } 
    else if (width > 1200) {    
      this.itemsPerSlide = 5;
    }
    else {    
      this.itemsPerSlide = 1;    
    }
}
}