import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  usuario: UsuarioModel = new UsuarioModel();
  user: any;
  mensajeError = "El usuario o contraseña son incorrectos"
  constructor(private router: Router, private login: LoginService, private toastr: ToastrService) { }

  ngOnInit() {}
  onSubmit(form: NgForm) {
    if (form.invalid) { return; }
  }
  onUpdate() {
    this.login.resetPass(this.usuario)
      .subscribe( async res => {
        //console.log("REQUEST: ", res);
        if ( this.login.estaAutenticado() === true ) {
          //console.log('Succesfully update!');
          this.router.navigate(['/inicio']);
          this.toastr.success("Cambios de contraseña exitoso");

        }else {
          //console.log('Not succesfully update!');
          this.toastr.error(this.mensajeError);
        }
      }, (err) => {
        //console.log("error" + err);
        this.toastr.error(this.mensajeError);

      });
  }
  asd(){
    this.login.showAPi();
  }

}
export class UsuarioModel {
  email: string;
  password: string; 
  newPassword: string; 
}
