import { AfterViewInit, Component, ContentChild, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import Quagga from '@ericblade/quagga2';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import {takeWhile} from 'rxjs/operators';
import { ICamDevices } from '../interface/idevices';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.css'],
  providers: [BsModalRef],
})






export class BarcodeComponent implements OnInit, AfterViewInit, OnDestroy {
public codigo2 = '';
refresher: Observable<any>;
public alive:boolean = true;
public interval;
public devices: ICamDevices[] = [];
public modalRef: BsModalRef;
public selectedDeviceId: string;
public camId;
@ViewChild('quaggaDiv') quaggaDiv: any; 
@ViewChild('barcodeTemplate') barcodeTemplate: any; 
//@ContentChild('barcodeTemplate') barcodeTemplate: any;

  constructor(public router: Router, 
    public commonService: CommonService, 
    private modalService : BsModalService,
    ) {
      this.router.events.subscribe(event => {
        if(event instanceof NavigationStart) {
        this.modalRef.hide();
        }
      });
    }


  ngOnInit(): void {
    localStorage.removeItem('code');
    this.loadUntilCode();
  }

  ngAfterViewInit() {
    this.phoneCamInitialize();
    }
    
  


    public loadUntilCode() {
      this.refresher = new Observable<number>(observer => {
        let index = -1;
        this.interval = setInterval(() => {
          index++;
          observer.next(index);
        }, 100);
        // teardown
        return () => {
          clearInterval(this.interval);
        };
      });
      this.refresher.pipe(takeWhile(() => this.alive))
        .subscribe(() => {
          
          
          if(localStorage.getItem('code')) {
            this.alive = false;
          }
         // this.getNotasEnprepar();
        },
          (error) => console.error(error), () => {
            this.onNavigateMostrarProducto();
            console.log('[takeWhile] complete');
          } );
    }


onNavigateMostrarProducto()  {
  let code = localStorage.getItem('code');
  var prodId;
  this.commonService.getProductoByBarCode(code).subscribe(res => {
  console.log(res);
  if(res['Rta'] == 0) {
    this.router.navigate(['/busqueda/', 'Código:#'+code]);
  }else {
    console.log(res['Val'][0]['prdProductoId']);
    prodId = res['Val'][0]['prdProductoId'];
    this.router.navigate(['/productos/CODIGO_LEIDO:' + code, prodId]);
  }
 // this.router.navigate(['/productos/CODIGO_LEIDO:' + code,'9317']);

});
}

reOpenDialog()  {
  //location.reload();
  Quagga.stop();
  this.openModal(this.barcodeTemplate);
}

/*
openDialog() {
  this.camId = localStorage.getItem('camId');
  const dialogRef = this.dialog.open(DialogCamDialogComponent, {
    width: '250px',
    data: {camId: this.camId,
    camOptions: this.devices
  }
  });
  dialogRef.afterClosed().subscribe(result => {
    console.log(result);
    localStorage.setItem('camId', result);
    this.quagga2Initialize(result);
    console.log('The dialog was closed');
  // this.animal = result;
  });
}
*/

//Abro modal
openModal(template?: TemplateRef < any > ) {
    
  // Limite De Precio Compra Total
  // modalRef2
  //actualizo items del carrito 
  //Abre el modal
  this.modalRef = this.modalService.show(this.barcodeTemplate, {
    class: 'modal-sm barcodeModal',
    backdrop : 'static',
  });
 
  //Carga datos jwt

}




changingValue(event){
  localStorage.setItem('camId', this.selectedDeviceId);
 this.quagga2Initialize(this.selectedDeviceId);
 this.modalRef.hide();
}

quagga2Initialize(camId:string) {
 
  var w = window.innerWidth;
  var h = window.innerHeight;
  Quagga.init({
    inputStream : {
      name : "Live",
      type : "LiveStream",
      target: document.querySelector('#quagga2Example'),
      // ()ELEMENT TYPE ASSIGN target: html(document.querySelector('#yourElement'))    // Or '#yourElement' (optional)
      constraints: {
        width: w,
        height: h,
        deviceId: camId,
    },
    },
 
    decoder : {
     /*
       "code_128_reader",
        'ean_5_reader', 
        'ean_2_reader',
        "ean_reader",
        "ean_8_reader",
        "code_39_reader",
        "code_39_vin_reader",
        "codabar_reader",
        "upc_reader",
        "upc_e_reader",
        "i2of5_reader",
        "2of5_reader",
        "code_93_reader",
        "code_32_reader",
        "ean_13_reader",
        'ean_5_reader', 
        'ean_2_reader',
        code_93_reader
            {
          format: "ean_reader",
          config: {
              supplements: [
               'ean_5_reader', 'ean_2_reader', "ean_8_reader"
              ]
          }
      }
     */
      readers : [ 
        "ean_reader",
        
    ],
    multiple: false,
    debug: {
      drawBoundingBox: true,
      showFrequency: true,
      drawScanline: true,
      showPattern: true,
  }
    }
  }, function(err) {
      if (err) {
          console.log(err);
          alert(err + "\n INTENTE CON OTRO NAVEGADOR"); 
          return
      }
      console.log("Initialization finished. Ready to start");
      Quagga.start();

      //detect the code
      let codes = []
      Quagga.onDetected(function (result) {

        codes.push(result.codeResult.code);
        if (codes.length < 3) return
        let is_same_all = false;
        if (codes.every(v => v === codes[0])) {
          is_same_all = true;
        }
        if (!is_same_all) {
          codes.shift()
          return
        }
        console.log(codes);
      
         localStorage.setItem('code', result.codeResult.code);
         Quagga.stop();
        
      });
  });
}

phoneCamInitialize() {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        //stop camera if is open
      stream.getTracks().forEach(track => track.stop());
        //get all camera devices
      Quagga.CameraAccess.enumerateVideoDevices().then(x => {
        var facingFront = "facing front"; 
        var facingBack = "facing back";
        this.devices = x;
        var testString;
        var nroCamTraceras = [];
        let storageId = localStorage.getItem('camId');
          this.devices.map(m => {
            //Options to spanish
            if(m.label.includes(facingFront)) {          
              m.labelES = m.label;
              testString = m.labelES.replace(facingFront, '');
              m.labelES = testString.slice(7) + "Camara Frontal";
            }if(m.label.includes(facingBack)) {
              m.labelES = m.label;
              testString = m.labelES.replace(facingBack, '');
              m.labelES = testString.slice(7) + "Camara Trasera";
            
              nroCamTraceras.push(m);
               // Camera Facing Back selected by localstorage
              if(storageId !== undefined) {
                if(nroCamTraceras.length < 2) {
                  this.camId =  storageId;
                  this.quagga2Initialize(storageId);
                }   
                if(nroCamTraceras.length > 2) {
                  this.openModal();
                }  
            } else {
                // Camera Facing Back selected by default
              this.quagga2Initialize(m.deviceId);
            }            
          }
            
          });
       console.log(this.devices);
    });

      console.log(document.querySelector('#quagga2Example'));

    }).catch(function (err) {
         console.log(err);
      });
    }
}













    
ngOnDestroy() {
 //stop camera if is open on destroy
  const video = document.querySelector('video');
  const mediaStream = video.srcObject;

  // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
  (<MediaStream>mediaStream).getTracks().forEach( stream => {
   
    stream.stop();
  });

  clearInterval(this.interval);
}

}


