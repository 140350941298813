

<div class="container p-md-0 pb-md-5">
    <div class="row justify-content-center mt-md-5">
        <div class="col-8 py-5 text-center margen">
            <span class="text-uppercase font-weight-bold carrito-texto mt-5">carrito de compras</span>
        </div>
    </div>

    <div class="row" *ngIf="items?.length">
        <div class="col-12 px-0">
          
<div class="total d-md-none " style="top: 140px !important;">
  <div class="container">
    <div class="row">
      <div class="col-6 px-3">
        <div class="btn btn-block" routerLink="/inicio">
          Seguir de compras
        </div>
      </div>
      <div class="col-6 px-3">
        <div class="btn btn-block" (click)="openModal(template)" style="font-weight: 500; background-color: #ed7516;">
          Completar el pedido
        </div>
      </div>
      <hr>
      <div class="col-6 px-3">
        <div class="btn btn-block" (click)="clearCart()">
          Vaciar el carrito<i class="fas fa-trash-alt mx-2"></i>
        </div>
      </div>
      <div class="col-6 px-3 resumen">
        <span class="font-weight-bold text-light">Total</span>
        <span class="precio">{{ this.total() | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
      </div>
    </div>
  </div>
</div>
<div class="container d-md-block d-none">
  <div class="row justify-content-end">
    <div class="col-12 total mx-0 ">
      <div class="row px-3 resumen justify-content-between">                      
        <div class="col d-flex align-items-center justify-content-center mx-auto">
          <button type="button" class="btn" routerLink="/inicio" style="background-color: inherit;">Seguir de compras</button>
        </div>
        <ng-container *ngIf="items?.length">
          <div class="col d-flex align-items-center justify-content-center mx-auto">
            <div class="btn " (click)="clearCart()">
              Vaciar el carrito<i class="fas fa-trash-alt mx-2"></i>
            </div>
          </div>
          <span style="color: #ffa500a6; font-size: 25px; font-weight: 100;">|</span>
          <div class="col d-flex align-items-center justify-content-center mx-auto">
            <button type="button" class="btn" (click)="openModal(template)" style="background-color: #ed7516; font-weight: 500;">
              Completar Pedido
            </button>
          </div>
        </ng-container>
        <div class="col d-flex align-items-center justify-content-center mx-auto">
          <span class="font-weight-bold text-light mr-2">Total:</span>
          <span class="precio">{{ this.total() | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
          <ng-container *ngFor="let item of items">
            <div class="card"   >
              <img class="oferta img-fluid" src="../../assets/img/Oferta-06.png" *ngIf="item.TieneOfertaSemanal == 1" />
              <img class="oferta-especial img-fluid" src="../../assets/img/desc-especial.png" *ngIf="item.PrecioDestacado && item.PrecioDestacado != 0.00"/>

              <div class="container">
                <div class="row">
                  <div class="col-4 p-0 text-center d-flex justify-content-center align-items-center">
                    <img class="mx-auto img-producto" src="{{item.img}}" onError="this.src='../../../assets/img/DR-10.png'" />
                  </div>
                  <div class="col-8">
                    <div class="row h-100">
                      <div class="col-12 d-inline d-md-none p-0">
                     
                        <span class="producto">{{ item.Nombre | excerpt:50}}</span>
                      </div>
                    
                      <div class="col-6 px-1 info">

                        <div>
                          <span class="font-weight-bold d-block" style="font-size: 10px; color: gray;">{{item.CODIGO}}</span>
                          <span class="producto d-none d-md-inline">{{ item.Nombre | excerpt:25}}</span>
                          <span class="font-weight-bold marca">{{ item.Marca }}</span>
                          <span class="contenido">{{ item.Presentacion }}</span>
                          <span style="cursor: pointer;" class="target_ws_left d-lg-inline  contenido " data-toggle="tooltip"  title="ATENCIÓN: Esta es la cantidad por pack por la que el producto viene de fabrica, NO es la cantidad mínima de venta."> <i style="font-size: 15px !important;" class="fas fa-info-circle text-green"></i> Pack x {{item.prdCantidad}}</span>
                          <br>

                          <span class="precio" [ngClass]="{'precioTachado':  item?.precio_anterior}" >{{ item.PrecioConIVA | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
                        </div>
                        
                     
                
                        <div class="d-flex align-items-end text-dark" style="min-height: 70px;" [ngStyle]="{'flex-direction': item['precios'].length == 1 ? 'initial' : (item['precios'].length > 1 ? 'column' : null) }">
                          <ng-container *ngIf="item['precios'].length > 0 && item.PrecioDestacado == 0.00">
                            <ng-container *ngFor="let i of item['precios']; let j = index">
                              <span class="sugerencia w-100 producto-item-precio text-center badge badge-secondary " style="padding: .75rem; max-height: 33px;" *ngIf="j == 0">x{{i.prcCantidad}}u. {{item.PrecioConIVA * (1 - i.prcDto / 100) | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
                              <span class="sugerencia w-100 producto-item-precio text-center badge badge-primary " style="padding: .75rem;" *ngIf="j == 1">x{{i.prcCantidad}}u. {{item.PrecioConIVA * (1 - i.prcDto / 100) | currency: 'ARS':'symbol':undefined:'es-AR' }}</span>
                            </ng-container>
                          </ng-container>
                          <!-- Precio destacado - descuento especial -->
                          <ng-container  *ngIf="item.PrecioDestacado && item.PrecioDestacado != 0.00">
                            <div class="btn-block">
                              <span class="sugerencia  text-center badge badge-primary btn-block py-2 my-1" id="oferta-especial">x 1u o más <strong>{{ item.PrecioDestacado | currency: 'ARS':'symbol':undefined:'es-AR' }}</strong></span>
                            </div>
                          </ng-container>
                          <!-- Oferta semanal -->
                          <ng-container  *ngIf="item.TieneOfertaSemanal == 1">
                            <div *ngFor="let oferta of item.oferta_semanal" class="btn-block">
                              <span class="sugerencia  text-center badge badge-primary btn-block py-2 my-1" id="oferta-semanal">Oferta <label> X{{ oferta.Cant}}U</label> <strong>{{ oferta.Precio | currency: 'ARS':'symbol':undefined:'es-AR' }}</strong></span>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                     <!-- CARRITO AGREGAR PLUS MIN INPUT -->
                      <div class="col-6 d-flex align-items-md-end px-1" style="flex-direction: column-reverse;">
                        <div class="w-100">
                          <div 
                          *ngIf="
                          item.EsDeBalanza !== '1' ? true : false"                         
                          class="unidades my-md-1">
                            <button class="minus" id="minus" (click)="minusQty(item.prdProductoId, 1, item.prdNombre, item)">-</button>
                            <input
                            class="num" 
                            id="{{item.prdNombre}}"
                            (keydown)="inputValidator($event)" 
                            (keyup.enter)="enterChange(item.prdProductoId, $event.target.value)"
                            name="quantity"
                            [value]="Qty(item)"
                            type="number"
                            (blur)="enterChange(item.prdProductoId, $event.target.value)">
                             <button class="plus" (click)="plusQty(item.prdProductoId, 1, item.prdNombre, item)">+</button>
                          </div>
                          <!--PRODUCTO X GRAMOS -->
                          <div 
                          *ngIf="
                          item.EsDeBalanza == '1' ? true : false
                           " 
                            class="unidades my-md-1">
                            <button class="minus"
                             id="minus"
                             (click)="minusQty(item.prdProductoId, 0.1, item.prdNombre, item)"
                             >-</button>
                            <input
                            class="num" 
                            id="{{item.prdNombre}}"
                            (keydown)="inputValidator($event)" 
                            (keyup.enter)="enterChange(item.prdProductoId, $event.target.value)"
                            name="quantity"
                            [value]="Qty(item)"
                            (change)="Qty(item)"
                            readonly
                            type="number"
                            (blur)="enterChange(item.prdProductoId, $event.target.value)">
                             <button
                             class="plus"
                             (click)="plusQty(item.prdProductoId, 0.1, item.prdNombre, item)"
                             >+</button>
                          </div>
                        <!-- PRODUCTO X GRAMOS END -->
                        </div>
                        <div class="justify-content-around d-flex suma w-100">
                          <i class="fas fa-trash-alt" (click)="quitarProd(item.prdProductoId)"></i>
                          <span class="font-weight-bold">
                                                      
                            {{ this.totalByItem(item.prdProductoId) }}     
                            <span  
                            *ngIf="item?.prcDto !== 0"
                            style="
                            color: #ed6c0a; 
                            line-height: 12px;
                            font-size: 12px;
                            border-radius: 0.2rem;
                            
                            ">
                         Dto -{{item?.prcDto}}%
                            </span> 
                                    
                         
                           

                          </span>
                        </div>
                      </div>

                       <!-- CARRITO AGREGAR PLUS MIN INPUT END -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

  
     

        </div>
    </div>
    <ng-container *ngIf="items?.length == 0">
        <div class="row animated fadeIn fast my-5">
            <div class="col-md-12">
                <div class="alert alert-info">
                    No existen pedidos agregados.
                </div>
            </div>
        </div>
    </ng-container>
</div>



<ng-template #template>
  <form [formGroup]="forma" (submit)="guardar()">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Confirmar Pedido</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label >Condición IVA</label>
            <!-- <input 
                  class="form-control"
                  name="condIva" 
                  class="form-control"
                  formControlName="condIva"
                  [class.is-invalid]="condIvaNoValido" /> -->
                  <select class="custom-select form-control"
                          id="condIva"
                          formControlName="condIva"
                          name="condIva"
                          [class.is-invalid]="condIvaNoValido" >
                    <option [value]="1">Resp. Inscripto</option>
                    <option [value]="3">Consumidor Final</option>
                    <option [value]="4">Exento</option>
                    <option [value]="5">Resp. Monotributo</option>
                  </select>
                  <small *ngIf="condIvaNoValido" class="text-danger">complete el campo</small>
          </div>
          <div class="form-group">
            <label>Nombre completo</label>
            <input 
                  type="text" 
                  name="nombre" 
                  class="form-control"
                  formControlName="nombre"
                  [class.is-invalid]="nombreNoValido" />
                  <small *ngIf="nombreNoValido" class="text-danger">Complete su nombre y apellido</small>

          </div>
          <div class="form-group" *ngIf="!isConsmudorFinal">
            <label>Cuit</label>
            <input 
                  class="form-control"
                  type="number"
                  name="cuit" 
                  class="form-control"
                  placeholder="CUIT"
                  formControlName="cuit"
                  [class.is-invalid]="cuitNoValido" />
                  <small *ngIf="cuitNoValido" class="text-danger">Ingrese 11 dígitos</small>
          </div>
          <div class="form-group" *ngIf="isConsmudorFinal">
            <label>Numero de documento</label>
            <input 
                  class="form-control"
                  type="number"
                  name="dni" 
                  class="form-control"
                  placeholder="DNI"
                  formControlName="dni"
                  [class.is-invalid]="dniNoValido" />
                  <small *ngIf="dniNoValido" class="text-danger">Ingrese 8 dígitos</small>
          </div>
          <div class="form-group">
            <label>Email</label>
            <input 
                  class="form-control"
                  name="email" 
                  class="form-control"
                  formControlName="email"
                  [class.is-invalid]="emailNoValido" />
                  <small *ngIf="emailNoValido" class="text-danger">Ingrese un email válido</small>
          </div>          
          <div class="form-group">
            <label>Ciudad</label>
            <!-- <input 
                  class="form-control"
                  type="number"
                  name="ciudadID" 
                  class="form-control"
                  formControlName="ciudadID"
                  [class.is-invalid]="ciudadIdNoValido" /> -->
                  <select class="custom-select form-control"
                          id="inputGroupSelect01"
                          formControlName="ciudadID"
                          name="ciudadID" 
                          [class.is-invalid]="ciudadIdNoValido" >
                    <option [value]="1">Rosario</option>
                    <option [value]="36">Funes</option>
                    <option [value]="37">Baigorria </option>
                    <option [value]="37">V.G. Galvez</option>
                  </select>
                  <small *ngIf="ciudadIdNoValido" class="text-danger">complete el campo</small>
          </div>
          <div class="form-group">
            <label>Dirección de entrega</label>
            <input 
                  type="text" 
                  name="direccion" 
                  class="form-control"
                  formControlName="direccion"               
                  [class.is-invalid]="direccionNoValido" />
                  <small *ngIf="direccionNoValido" class="text-danger">complete el campo</small>
                  <div class="form-group">
                    <label>Teléfono</label>
                    <input 
                          class="form-control"
                          name="tel" 
                          type="number"
                          class="form-control"
                          formControlName="tel"
                          [class.is-invalid]="telNoValido" />
                          <small *ngIf="telNoValido" class="text-danger">complete el campo</small>
                  </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Total con IVA</label>
            <input 
                  class="form-control"
                  name="totalConIva" 
                  readonly
                  class="form-control"
                  formControlName="totalConIva" />
          </div>
          <div class="form-group d-none">
            <label>Total sin IVA</label>
            <input 
                  class="form-control"
                  name="totalSinIva" 
                  class="form-control"
                  readonly
                  formControlName="totalSinIva" />
          </div>
          <div class="form-group">
            <label>Costo Envío <span *ngIf="this.costoEnvioVal == 0"><b>(Bonificado)</b></span></label>
            <input 
                  class="form-control"
                  name="costoEnvio" 
                  class="form-control"
                  readonly
                  [value]="this.costoEnvioVal"
                  formControlName="costoEnvio"/>
          </div>
          <div class="form-group">
            <label>Aclaraciones</label>
            <input 
                  name="aclaraciones"
                  class="form-control"
                  formControlName="aclaraciones"                                    
                  [class.is-invalid]="aclaracionesNoValido" />
                  <small *ngIf="aclaracionesNoValido" class="text-danger">complete el campo</small>

          </div>
          <div class="form-group">
            <label>Horario de entrega</label>
            
            <select class="custom-select form-control"
            id="hsEntrega"
            formControlName="hsEntrega"
            name="hsEntrega"
            [ngModel]="horarioAcordarCon"
           >
            <option value="Acordar con el operador">Acordar con el operador</option>
             </select>

            <!--
            <select class="custom-select form-control"
                    id="hsEntrega"
                    formControlName="hsEntrega"
                    name="hsEntrega"
                    [class.is-invalid]="hsEntregaNoValido" >
              <option [value]="'9-14'">9 a 14 hs</option>
              <option [value]="'15-19'">15 a 19 hs</option>
               <option [value]="'19-21'">19 a 21 hs</option>   
            </select>
    
           <small *ngIf="hsEntregaNoValido" class="text-danger">complete el campo</small>
          -->
                  
          </div>
           <!-- 
          <div class="form-group">
            <label>Criterio de selección de Reemplazo</label>
           
                //comentado
               <input 
                  class="form-control"
                  name="criterioId" 
                  class="form-control"
                  formControlName="criterioId"
                  [class.is-invalid]="criterioIdNoValido" /> 
                  //comentado
                  <select class="custom-select form-control"
                          id="criterioId"
                          formControlName="criterioId"
                          name="criterioId" 
                          [class.is-invalid]="criterioIdNoValido" >
                    <option [value]="1">Criterio DR</option>
                    <option [value]="2">No reemplazar</option>
                  </select>
                  <small *ngIf="criterioIdNoValido" class="text-danger">complete el campo</small>
          </div>
        -->
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group" [class.is-invalid]="formaPagoNoValido">
                <h6>Forma de pago</h6>
                <div class="custom-control custom-radio">
                  <input  type="radio"
                          id="contra-entrega" 
                          required
                          formControlName="formaPago" 
                          value="1" 
                          (click)="bifurcarPago(false)" 
                          name="formaPago"
                          class="custom-control-input">
                  <label class="custom-control-label" for="contra-entrega">Contra-entrega.</label>
                </div>
                <!-- <div class="custom-control custom-radio">
                  <input  type="radio"
                          id="mercadoPago"
                          required
                          formControlName="formaPago"  
                          value="2" 
                          (click)="bifurcarPago(true)" 
                          name="formaPago"
                          class="custom-control-input">
                  <label class="custom-control-label" for="mercadoPago">Mercado pago.</label>
                </div> -->
                <small *ngIf="formaPagoNoValido" class="text-danger">Seleccione una opción</small>
              </div>
            </div>
            <div class="col-12 col-md-6">          
              <div class="form-group">
                <h6>Forma de retiro</h6>
                <div class="custom-control custom-radio" [class.is-invalid]="formaEnvioNoValido">
                  <input type="radio"
                  id="customRadio1" 
                        (click)="mostrarRadios(false)" 
                        name="formaEnvio"
                        value="1"
                        required
                        formControlName="formaEnvio"  
                        class="custom-control-input">
                  <label class="custom-control-label" for="customRadio1">Retirar en sucursal.</label>
                </div>
                <div class="custom-control custom-radio">
                  <input type="radio"
                        id="customRadio2"
                        name="formaEnvio"
                        (click)="mostrarRadios(true)" 
                        value="2"
                        required
                        formControlName="formaEnvio"  
                        class="custom-control-input">
                  <label class="custom-control-label" for="customRadio2">Enviar pedido.</label>
                </div>
                <small *ngIf="formaEnvioNoValido" class="text-danger">Seleccione una opción</small>
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-secondary" style="padding: 5px 10px!important;" (click)="confirmarPedido(contraEntrego, mp)" [disabled]="disableBtn" >Confirmar pedido</button>
        </div>
        <div class="col-12">
            <!-- Verificar -->
          <div id="MyDIv" class="container"  #pdfTable >
            <h3>Resumen</h3>
            <ul class="resumen">
              <li class="cart-item" *ngFor="let item of items">
                {{item.CODIGO}} - {{ item.prdNombre }} x {{ getQty(item) }}u. - {{ this.totalByItem(item.prdProductoId) }}
              </li>
            </ul>
          </div>
          <!-- //Botones de impresion -->
          <!-- <div> <button  (click)="downloadAsPDF()">Export To PDF</button></div>
          <div class="btn btn-primary" title="Share As PDF" (click)="exportAsPDF('MyDIv');">imprimir <img src="assets/img/pdf.png"></div> -->
          <div class="row justify-content-center">
            <div class="col-12">
              <h6 class="text-uppercase font-weight-bold pb-3 text-left medios-pago-texto">Medios de pago</h6>
            </div>
            <div class="col-12 mx-auto">
              <div class="row justify-content-between">
                <div class="col-2 d-flex justify-self-center">
                  <div class="mx-auto" style="width: 50px">
                    <img class="mx-auto py-3" style="width:100%; height: auto!important;"
                      src="../../../assets/medios-pagos/342-3421828_imagenes-de-diners-club-hd-png-download.png" alt="">
                  </div>
                </div>
                <div class="col-2 d-flex justify-self-center">
                  <div class="mx-auto" style="width: 50px">
                    <img class="mx-auto py-3" style="width:100%; height: auto!important;"
                      src="../../../assets/medios-pagos/1200px-American_Express_logo_(2018).svg.png" alt="">
                  </div>
                </div>
                <div class="col-2 d-flex justify-self-center">
                  <div class="mx-auto" style="width: 50px">
                    <img class="mx-auto py-3" style="width:100%; height: auto!important;"
                      src="../../../assets/medios-pagos/1200px-Mastercard-logo.svg.png" alt="">
                  </div>
                </div>
                <div class="col-2 d-flex justify-self-center">
                  <div class="mx-auto" style="width: 50px">
                    <img class="mx-auto py-3" style="width:100%; height: auto!important;"
                      src="../../../assets/medios-pagos/cabal_credito.png" alt="">
                  </div>
                </div>
                <div class="col-2 d-flex justify-self-center">
                  <div class="mx-auto" style="width: 50px">
                    <img class="mx-auto py-3" style="width:100%; height: auto!important;"
                      src="../../../assets/medios-pagos/Cencosud.png" alt="">
                  </div>
                </div>
                <div class="col-2 d-flex justify-self-center">
                  <div class="mx-auto" style="width: 50px">
                    <img class="mx-auto py-3" style="width:100%; height: auto!important;"
                      src="../../../assets/medios-pagos/CMR_Falabella.svg" alt="">
                  </div>
                </div>
                <div class="col-2 d-flex justify-self-center">
                  <div class="mx-auto" style="width: 50px">
                    <img class="mx-auto py-3" style="width:100%; height: auto!important;"
                      src="../../../assets/medios-pagos/como-obtener-resument-tarjeta-naranja_foro.jpg" alt="">
                  </div>
                </div>
                <div class="col-2 d-flex justify-self-center">
                  <div class="mx-auto" style="width: 50px">
                    <img class="mx-auto py-3" style="width:100%; height: auto!important;"
                      src="../../../assets/medios-pagos/descarga.jpeg" alt="">
                  </div>
                </div>
                <div class="col-2 d-flex justify-self-center">
                  <div class="mx-auto" style="width: 50px">
                    <img class="mx-auto py-3" style="width:100%; height: auto!important;"
                      src="../../../assets/medios-pagos/nativa-nacion-logo.jpg" alt="">
                  </div>
                </div>
                <div class="col-2 d-flex justify-self-center">
                  <div class="mx-auto" style="width: 50px">
                    <img class="mx-auto py-3" style="width:100%; height: auto!important;"
                      src="../../../assets/medios-pagos/tarjeta-argencard-logo.jpg" alt="">
                  </div>
                </div>
                <div class="col-2 d-flex justify-self-center">
                  <div class="mx-auto" style="width: 50px">
                    <img class="mx-auto py-3" style="width:100%; height: auto!important;"
                      src="../../../assets/medios-pagos/unnamed.png" alt="">
                  </div>
                </div>
                <div class="col-2 d-flex justify-self-center">
                  <div class="mx-auto" style="width: 50px">
                    <img class="mx-auto py-3" style="width:100%; height: auto!important;"
                      src="../../../assets/medios-pagos/visa.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    
  </form>
</ng-template>
<ng-template #contraEntrego>

  <form>
    <div class="modal-header">
    <h4 class="modal-title pull-left">Pedido realizado</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body completar-body0">
    <h4>Elegiste el método de pago contra entrega.</h4>
    <h4>Su código de pedido es: {{ this.referencia }}. Tenga presente este código por cualquier consulta sobre su pedido.</h4>
    <h4>En instantes un operador se va a comunicar a su número registrado, para coordinar la entrega. Muchas gracias por elegirnos.</h4>
  </div>
</form>
</ng-template>
<ng-template #mp>
  <form>
    <div class="modal-header">
    <h4 class="modal-title pull-left">Pedido realizado</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body completar-body0">
    <h3 >Continuar con el pago</h3>
    <button class="btn btn-secondary" style="padding: 5px 10px!important;" (click)="mpLink()" >Pagar pedido</button>
    <h4>Su código de pedido es: {{ this.referencia }}. Tenga presente este código por cualquier consulta sobre su pedido.</h4>
    <h4>En instantes un operador se va a comunicar a su número registrado, para coordinar la entrega. Muchas gracias por elegirnos.</h4>
  </div>  
</form>
</ng-template>
