<div class="container-fluid p-0 contprin">
  <div class="row justify-content-center mx-md-0">


    <div class="row animated fadeIn fast" *ngIf="producto.length == 0 && loading">
      <div class="col-md-12">
        <div class="alert alert-info">
          <img *ngIf="loading" src="/assets/img/loading.gif" class="gif" alt="loading" />
        </div>
      </div>
    </div>
    <div class="row animated fadeIn fast" *ngIf="producto.length == 0 && !loading">
      <div class="col-md-12">
        <div class="alert alert-info">
          No existen resultados para este producto
        </div>
      </div>
    </div>
    <div class="row justify-content-center  p-0 m-0" *ngIf="producto.length > 0">
      <div class="col-12 text-center my-4">
        <span class="cat-titulo">
          {{string}}
        </span>
      </div>
      <div class="col-12 mx-0 my-md-3">
          <app-producto 
         
           [producto]="producto[0]" 
           [horizontal]="true"
           ></app-producto>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple"
>
<p style="font-size: 20px; color: white">Cargando...</p>
</ngx-spinner>    