import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-contador-carrito',
  templateUrl: './contador-carrito.component.html',
  styleUrls: ['./contador-carrito.component.scss']
})
export class ContadorCarritoComponent implements OnInit {
  @Input() qty: number;
  constructor() { }

  ngOnInit(): void {
  

  }


ngOnChanges(changes: SimpleChanges): void {
    if ('qty' in changes) {
  }
  
}


  




}
