import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ListaClientesService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.post(`${environment.API_URL}/api/stage/suscripciones/fuerzaDeVenta/v1/buscarPlanesST`,{ name : "ss"});
  //  return of(DATA);
  }
}
