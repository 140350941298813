<ng-template #template >
   <div  style="z-index:9999">
      <div class="modal-header" v>
        <h4 class="modal-title pull-left">Filtrar Por</h4>
        <button type="button"
         class="close pull-right" 
         aria-label="Close"
         (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">

                <div  *ngIf="this.mobileMode == 1" 
                class="input-group justify-content-end">
                   <div class="input-group-prepend ">
                     <label style="height: 2.4rem;"
                       class="input-group-text select-orden" 
                     for="inputGroupSelect02">Filtrar</label>
                   </div>
                
                  
                   <ng-select  
                   
                   [searchable]="true"
                   [clearable]="true"          
                   (clear)="onMultiSelectClear()"  
                   [closeOnSelect]="false"
                     class="multiSelect"
                   [multiple]="true"
                   (change)="onItemSelect($event)"
                    [items]="multiSelectList"
                    [(ngModel)]="selectedItems" 
                    placeholder="Marca"
                    bindLabel="name"
                    bindValue="id">
                   
                     <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                       <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/>  {{item.name}}
                       </ng-template>
                       <ng-template ng-footer-tmp>
                        <label  
                        style="cursor: pointer; justify-content: center; color: #202a5d; font-weight: 500;"
                      (click)="onFilter()" 
                       class="m-1  input-group-text select-orden"
                       for="filtrar">Filtrar</label>
                       </ng-template>
                   </ng-select>
           </div> 


            </div>
          </div>
        </div>
    </div>
   
</div>
</ng-template>

<div class="container-fluid p-0 contprin mt-md-5"  infiniteScroll [infiniteScrollDistance]="2"  (scrolled)="onScroll()" >
    <div class="row justify-content-center mt-md-5">
        <div class="col-12 text-center mt-2 py-3 d-block d-lg-none categorias cat-fixed">
            <carousel class="d-block d-lg-none " [showIndicators]="false" [itemsPerSlide]="itemsPerSlide2" [singleSlideOffset]="singleSlideOffset" [noWrap]="noWrap" [interval]="false">
                <slide *ngFor="let categoria of categorias" class="slide-item-img">
                    <div (click)="goCat(categoria.catDescripcion, categoria.catCategoriaId)">
                        <span class="text-center w-100 cat-span rlink">{{categoria.catDescripcion}}</span>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
    <div class="row justify-content-center mx-0">

        <div class="col-12 text-center p-0 carousel-prod mt-md-5">
            <!-- <carousel class="d-none d-md-block mt-5 sub-cat-fixed" [showIndicators]="false" [itemsPerSlide]="itemsPerSlide" [singleSlideOffset]="singleSlideOffset" [noWrap]="noWrap" [interval]="false">
                <slide *ngFor="let categoria of subCategorias" class="slide-item-img">
                    <div (click)="goSubCat(categoria.scaDescripcion, categoria.scaSubCategoriaId)">
                        <span class="text-center w-100 cat-span rlink">{{categoria.scaDescripcion}}</span>
                    </div>  
                </slide>
            </carousel> -->
            <div class="btn-group btn-block d-block d-lg-none p-md-0 sub-cat-fixed" >
                <button type="button" class="btn btn-danger dropdown-toggle btn-block btn-sub text-uppercase"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Subcategorias
                </button>
                <div class="dropdown-menu" [ngClass]="{'show': hover == true}" >
                    <ng-container *ngFor="let categoria of subCategorias; index as i;">                        
                        <a class="dropdown-item" (click)="goSubCat(categoria.scaDescripcion, categoria.scaSubCategoriaId)">{{categoria.scaDescripcion}}</a>
                        <div class="dropdown-divider" *ngIf="!(i == subCategorias.length)"></div>
                    </ng-container>
                </div>
              </div>
        </div>

        
        <div class="col-12 pb-5 pt-3">
            <div class="row row-prod">
                <div class="col-6  mb-5 mt-3">
                 <div  class="input-group">
                    <div class="input-group-prepend ">
                      <label class="input-group-text select-orden" for="inputGroupSelect01">Ordenar por</label>
                    </div>
                    <select class="col-md-2 custom-select"
                     id="inputGroupSelect01"
                      (change)="ChangingValue($event)" 
                     [(ngModel)]="selectedProduct">
                      <option [value]="0"> A->Z</option>
                      <option [value]="1">Z->A </option>
                      <option [value]="2">Precio menor</option>
                      <option [value]="3">Precio mayor</option>
                    </select>
                 </div>
                </div>
                <div class="col-6 pt-3 d-flex justify-content-end">
                    <div class="d-flex justify-content-end">
                        <div  *ngIf="this.mobileMode == 0" 
                         class="input-group justify-content-end">
                            <div class="input-group-prepend ">
                              <label style="height: 2.4rem;"
                                class="input-group-text select-orden" 
                              for="inputGroupSelect02">Filtrar por</label>
                            </div>
                         


                            <!--Using ng-option and for loop-->

             <!--         
<ng-select [(ngModel)]="selectedItems">
  <ng-option *ngFor="let item of multiSelectList" 
  [value]="item.id"
  >{{item.name}}</ng-option>
</ng-select>
[searchable]="false"
 [clearable]="false" 
                   [disabled]="true"
-->
<ng-select
[searchable]="true"
[clearable]="true"          
(clear)="onMultiSelectClear()"
[closeOnSelect]="false"
class="multiSelect"
[multiple]="true"
(remove)="onFilterRemove($event)"
(change)="onItemSelect($event)"
 [items]="multiSelectList"
 [(ngModel)]="selectedItems" 
 placeholder="Marca"
 bindLabel="name"
 bindValue="id">

	<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
		<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
    </ng-template>
		<ng-template ng-footer-tmp>
      <label  
          style="cursor: pointer; justify-content: center; color: #202a5d; font-weight: 500;"
          (click)="onFilter()" 
             class="m-1  input-group-text select-orden"
        for="filtrar">Filtrar</label>
		</ng-template>
</ng-select>
<!--
<ng-select [items]="multiSelectList"
           bindLabel="name"
           autofocus
           bindValue="id"
           bindLabel="name" 
           [(ngModel)]="selectedItems">
</ng-select>
-->
<!--Using items input-->
<!--
<ng-select 
          [items]="multiSelectList" 
          bindLabel="name" 
          bindValue="id" 
          [(ngModel)]="selectedItems">
</ng-select>
-->
                            <!--
                            <ng-multiselect-dropdown
                            
                            [placeholder]="'Por Marca&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'"
                            [settings]="dropdownSettings"
                            [data]="dropdownList"
                            [(ngModel)]="selectedItems"
                            (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)"
                          >
                          </ng-multiselect-dropdown>
                        -->
                  
                  
                        </div> 
                        <label [ngClass]="{'filterMarcasMobile': filterActive}" 
                        (click)="onDialogOpen(template)"
                         *ngIf="this.mobileMode == 1" 
                        style="height: 2.4rem; width: 7rem; cursor: pointer;"
                         class="input-group-text">Filtrar por <i 
                         [ngStyle]="{'color': filterActive ? '#202a5d' : '' }"
                         class="pl-2 fas fa-sliders-h"></i></label>   
                    </div>
                  </div>
                 
            </div>
            <div class="row">
                <ng-container *ngFor="let producto of arregloProd">
                    <div class="col-12 col-md-4 col-lg-3 col-xl-2 mx-0 my-md-3  p-0" *ngIf="producto">
                        <div class="producto-item p-md-2">
                            <app-producto   [producto]="producto"></app-producto>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<button [ngClass]="'no-hidden'"
*ngIf="showGoUpButton" class="btn btn-dark"
(click)="scrollTop()"><i class="fas fa-arrow-circle-up"></i></button>
<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple"
>
<p style="font-size: 20px; color: white">Cargando...</p>
</ngx-spinner>  