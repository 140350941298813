import { Component, OnInit, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ProductosService } from '../services/productos.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BusquedaService } from '../services/busqueda.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgSelectComponent } from '@ng-select/ng-select';


@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.scss']
})
export class BusquedaComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  public productos: Array<object> = new Array<object>();
  public terminos: any;
  public arregloProd: Array<object>;
  private finishPage = 15;
  private actualPage: number;  	
  public showGoUpButton: boolean;
  showScrollHeight = 400;
  hideScrollHeight = 200;
  offset = 0;
  limit = 80;
  public selectedProduct = "0";
  public loading: boolean = true;

 //MultiSelect
 multiSelectList = [];
selectedItems = [];
multiSelectCustomClass = false;

//dialog modal
modalRef: BsModalRef;
mobileMode;
str_marcas = '';
filterActive: boolean = false;
  constructor(private BusquedaService: BusquedaService, 
              private productosService: ProductosService, 
              private activatedRoute: ActivatedRoute, 
              private Router: Router,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService
              ) {
    this.actualPage = 1;
    this.showGoUpButton = false;
    // this.loader();
    this.activatedRoute.params.subscribe(routeParams => {
      this.terminos = routeParams.termino;
    }); 
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {

    if ((window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop) > this.showScrollHeight) {
      this.showGoUpButton = true;
    } else if (this.showGoUpButton &&
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop) <
      this.hideScrollHeight) {
      this.showGoUpButton = false;
    }
  }
  ngOnInit(): void {
  $('.ng-clear-wrapper').removeAttr( "title" );


    this.inicio(0, this.offset, this.limit);

    var currentWidth = window.outerWidth;
    if(currentWidth <= 992) {
      this.mobileMode = 1;
     
    }else {
      this.mobileMode = 0;
    }
    this.onGetMarcasBusqueda();
  }

onGetMarcasBusqueda() {
  const routeid = this.terminos.replaceAll('/busqueda/');
  
  this.productosService.getMarcasBusqueda(routeid).subscribe(resp => {
   
    let arr_temp =  [];
    resp['data'].map(m => {
     let busquedaObj = {
        name: m.marDescripcion,
        id: m.marMarcaId
      } 
      arr_temp.push(busquedaObj);
    });
    this.multiSelectList = arr_temp;
     
  });
}



  // ngOnChanges(): void {
  //   // this.loader();
  //   this.activatedRoute.params.subscribe(routeParams => {
  //     this.terminos = routeParams.termino;
  //   });  
  //   let httpCalls = forkJoin( //permite hacer varias llamadas de una
  //     this.productosService.getProductos(this.terminos, '0', 40, 40) 
  //   );
  //   httpCalls.subscribe( //para cualquier respuesta 
  //     (data: any) => {
  //       this.productos = data[0].data; //cada posición de data[i] coincide con las posiciones del array que mandamos con forkjoin
  //         //Scroll infinite
  //         if (this.productos.length > 0) {
  //           this.arregloProd = new Array<object>();
  //           this.add40items();
  //           this.loading = false;

  //         }
  //     },
  //     (error) => { //en caso de error http              
  //       //console.log(error);
  //       this.loading = false;

  //     });
    
  // }
  async inicio(orden: any = 0, offset, limit){
    
    await this.spinner.show();
    let httpCalls = await forkJoin( //permite hacer varias llamadas de una
      this.productosService.getProductos(
        this.terminos,
         orden,
         offset,
         limit,
         this.str_marcas
         ) 
      );
      
    await httpCalls.subscribe( //para cualquier respuesta 
        (data: any) => {
          this.productos = data[0].data; //cada posición de data[i] coincide con las posiciones del array que mandamos con forkjoin
          this.spinner.hide();
          this.loading = false;
          // this.spinner.hide();    
          if (this.productos.length > 0) {

            this.arregloProd = new Array<object>();
            this.add40items(); 
            //console.log(this.productos, this.loading);

          }          
        },
        (error) => { //en caso de error http
          this.spinner.hide();
          //console.log("error: ", error);
          this.loading = false;

      });
  }
  loader(){
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 3 seconds */
      this.spinner.hide();
    }, 3000); 
  }
  async add40items() {
    await this.spinner.show();
    await this.crearArray();
    await this.spinner.hide(); 
  }
  crearArray(){
    if(this.productos.length - this.arregloProd.length >= 40 ){

      let lineCounter = this.arregloProd.length;
      for (let i = 0; i < 40; i ++) {
        this.arregloProd.push(this.productos[i+lineCounter]);
       // console.log(this.productos[i+lineCounter]);

      }
      // this.actualizarProd(this.selectedProduct);
      this.productosService.getProductos(
        this.terminos,
        this.selectedProduct,
        this.offset,
        this.limit,
        this.str_marcas
        )
      .subscribe( resp=> {
        this.productos = resp['data']; //cada posición de data[i] coincide con las posiciones del array que mandamos con forkjoin
        this.finishPage = this.productos.length/40;
        this.limit+=80; 
        //console.log("finishPage:", this.finishPage, "prod length:", this.productos.length,"arreglo length:", this.arregloProd.length);
      })
    }
    else {
      this.arregloProd = this.productos;

    }
    
  }
  actualizarProd(orden){
      this.productosService
      .getProductos(this.terminos, orden, this.offset ,this.limit, this.str_marcas)
      .subscribe( resp=> {
        this.productos = resp['data']; //cada posición de data[i] coincide con las posiciones del array que mandamos con forkjoin
        this.finishPage = this.productos.length/40;
        this.limit+=40; 
        //console.log("finishPage:", this.finishPage, "prod length:", this.productos.length,"arreglo length:", this.arregloProd.length);
      })
  }
  onScroll() {
    if(this.arregloProd.length !== 0) {
  //console.log("scroll", this.actualPage, this.finishPage);
  if (this.actualPage < this.finishPage) {
    this.add40items();
    this.actualPage ++;
  } else {
  }
    }
  
  }
  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }
  ChangingValue( event ) {
    this.actualPage = 0;
    this.inicio(this.selectedProduct, this.offset, this.limit);
  }

  onDialogOpen(smModal: TemplateRef<any>){
    // this.modalRef = this.modalService.show(smModal);
     
     this.modalRef = this.modalService.show(smModal, {
       class: 'fixed-top'
     });
   }

   onFilter() {
   
   
    
     // Call to clear
     this.inicio(0, 0, 999999);
     if(this.mobileMode == 0) {
      //this.inicio(0, this.offset, this.limit);
      this.ngSelectComponent.close();   
    }
     if(this.mobileMode == 1) {
      //this.inicio(0, this.offset, this.limit);
      this.modalRef.hide();
     }
     //console.log(this.offset,this.limit);
  }
  
  onItemSelect(item: any) {
    $('.ng-clear-wrapper').removeAttr( "title" );
    
    let arr_temp = [];
    item.map(m => {
      arr_temp.push(m.id) 
    });
    this.str_marcas = arr_temp.join(',');
    
     if(item.length !== 0) {
      this.filterActive = true;
    }else {
      this.filterActive = false;
    }
  }
  onSelectAll(items: any) {

  }

  onFilterRemove(e) {
  
    this.offset = 0;
    this.limit = 40;
    this.actualPage = 0;
    this.inicio(this.selectedProduct, this.offset, this.limit);
  }

  onMultiSelectClear() {
    this.offset = 0;
    this.limit = 40;
    this.actualPage = -1;
    this.inicio(this.selectedProduct, this.offset, this.limit);
    if(this.mobileMode == 0) {
    this.ngSelectComponent.close();
    }
    if(this.mobileMode == 1) {
      this.modalRef.hide();
    }
  
    if(this.mobileMode == 1) {
     
      this.modalRef.hide();
    }
    
  }

}

