<div  class="container-fluid mt-10" *ngIf="!loading">
    <div   class="row justify-content-center">
        <div class="col-12 text-center justify-content-center d-flex">
            <h4 class="text-uppercase font-weight-bold pb-3 mt-3">Ofertas semanales</h4>
        </div>


        <div style="cursor: pointer;" 
        
        *ngIf="this.router.url == '/'"
        class="mb-4">    
        <img  (click)="onNavigateOfertas()" class="img-fluid" src="assets/img/ofertas_inicio.jpeg">
        </div>


        <div class="col-12 pb-5" *ngIf="ofertasSemanales">
           
                <div class="row row-ofertas p-0 ">

                    <div class="col-12 col-md-4 col-xl-2 mx-0 my-md-2 p-0" *ngFor="let producto of productos">
                     
                        <div class="producto-item p-md-2 my-md-0">
                            <app-producto [producto]="producto"></app-producto>
                         
                        </div>

                    </div>
                </div>
           
        </div>

        <div class="col-12 text-center justify-content-center d-flex">
            <h4 class="text-uppercase font-weight-bold pb-3">Productos más vendidos</h4>
        </div>
        <div class="col-12 pb-5 carousel">
            <carousel class="carousel-prod carousel-mas-vendido" [showIndicators]="false" [itemsPerSlide]="itemsPerSlide" [singleSlideOffset]="true" [noWrap]="true">
                <div class="row prod-row p-0 justify-content-center">

                    <slide *ngFor="let producto of masvendidos">
                        <div class="col-12 col-md-10 mx-auto my-2 p-0">
                            <!-- Esto se podria automizar -->
                            <div class="producto-item p-md-2 my-0">
                                <app-producto [producto]="producto"></app-producto>
                            </div>
                        </div>
                    </slide>
                </div>
            </carousel>
        </div>
    </div>
</div>
<div class="container-fluid mt-10" *ngIf="loading">
    <div class="row justify-content-center">
        <img src="/assets/img/loading.gif" class="gif" alt="loading" />
    </div>
</div>
