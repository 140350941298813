import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, NgForm, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProductosService } from '../services/productos.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {
  forma: any;
  usuario: UsuarioModel = new UsuarioModel();

  constructor(private fb: FormBuilder, private prdSvc: ProductosService, private toastr: ToastrService, private router: Router) { }

  ngOnInit() {
    this.crearFormulario();
  }
  singIn(){}
  registrar(form: NgForm) {
    //console.log(form);
    if( form.invalid){
      Object.values(form.controls).forEach(control =>{
        control.markAsTouched();
      });
      return;
    }
    this.prdSvc.registro(form.value)
    .subscribe( resp => {
      if(resp['error'] == true ) {
        this.toastr.error(resp['error_msg']);
      }
      else {
        this.toastr.success('Gracias por registrarte, estamos corroborando los datos que nos dejaste y te enviaremos tu usuario y contraseña al mail que nos indicaste. La demora máxima es de 2 hs! Cualquier inconveniente o consulta podes escribirnos al 3415952021');
        this.forma.reset();
        this.router.navigateByUrl('/inicio');
      } 
    });
  }
  crearFormulario() {
    this.forma = this.fb.group({
      correo: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      apellido: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      direccion: ['', [Validators.required]],
      dirEntrega: ['', [Validators.required]],
      condIva: ['', [Validators.required]],
      tipoDoc: ['', [Validators.required]],
      numDoc: ['', [Validators.required]],
      ciudad: ['', [Validators.required]],
      pcia: ['Santa Fe', [Validators.required]],
    });
  }
  get correoNoValido() {return this.forma.get('correo').invalid && this.forma.get('correo').touched}
  get nombreNoValido() {return this.forma.get('nombre').invalid && this.forma.get('nombre').touched}
  get apellidoNoValido()  {return this.forma.get('apellido').invalid && this.forma.get('apellido').touched}
  get telefonoNoValido() {return this.forma.get('telefono').invalid && this.forma.get('telefono').touched}
  get direccionNoValido() {return this.forma.get('direccion').invalid && this.forma.get('direccion').touched}
  get dirEntregaNoValido() {return this.forma.get('dirEntrega').invalid && this.forma.get('dirEntrega').touched}
  get condIvaNoValido() {return this.forma.get('condIva').invalid && this.forma.get('condIva').touched}
  get tipoDocNoValido() {return this.forma.get('tipoDoc').invalid && this.forma.get('tipoDoc').touched}
  get numDocNoValido() {return this.forma.get('numDoc').invalid && this.forma.get('numDoc').touched}
  get ciudadNoValido() {return this.forma.get('ciudad').invalid && this.forma.get('ciudad').touched}
  get pciaNoValido() {return this.forma.get('pcia').invalid && this.forma.get('pcia').touched}

}
export class UsuarioModel {
  email: string;
  password: string; 
}
