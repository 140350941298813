import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CrearHashService } from './crear-hash.service';
@Injectable({
  providedIn: 'root'
})
export class 
CategoriasService {

  string: string;
  stringPadre: string; 
  selectedItem: any; 
  firstTime = true;
  private pathParamState = new BehaviorSubject<string | undefined>(undefined);
  pathParam: Observable<string>;
  constructor(private http: HttpClient, private active: ActivatedRoute, private hashSvc: CrearHashService) { 
      this.pathParam = this.pathParamState.asObservable();
  }
  
  actualizarPAthParam(newPathParam: string){
      this.pathParamState.next(newPathParam);
  }
 
  getAll() {

    let headers = new HttpHeaders();
    let hash = this.hashSvc.crearHash('dr-cat-');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Cache-Control', 'no-cache');
    headers.append('authorization', hash);
    
    let params = new HttpParams();
    params = params.append('hash', hash);
    //params = params.append('0', '1');
    const options = { params: params, headers: headers };
    return this.http.post<Response>(`${environment.API_URL2}` + "/getcategorias", "" , options);
  }

  getAllSubCategorias(parentCatId) {
    let hash = this.hashSvc.crearHash('dr-subcat-');
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Cache-Control', 'no-cache');
    headers = headers.append('authorization', hash);
    
    let params = new HttpParams();
    params = params.append('catid', parentCatId);
    const options = { params: params, headers: headers };

    return this.http.post<Response>(`${environment.API_URL2}`+ "/getsubcategoriasbycatid", "" , options);
    
  }
  formatearArreglo(id: string, array: any){
      let arrayFormateado = [];
      let j= 0;
      for(let i of array ) {
          if(id === i.catDescripcion) {
              arrayFormateado[0] = i;
            }
            else {
                j++;
                arrayFormateado[j] = i;
          }
      }
    //   return arrayFormateado;
    return arrayFormateado;

  }
  getRuta(){
    this.active.paramMap.subscribe(ParamMap => {
          return ParamMap.get('stringPadre');
      });
      
  }
}