import { Component, OnInit } from '@angular/core';
import { ListaClientesService } from '../services/lista-clientes.service';
import { CategoriasService } from '../services/categorias.service';
import { from, forkJoin } from 'rxjs';

@Component({
  selector: 'app-lista-clientes',
  templateUrl: './lista-clientes.component.html',
  styleUrls: ['./lista-clientes.component.scss']
})
export class ListaClientesComponent implements OnInit {

  public clientes = [];
  public categorias = [];
  
  constructor(
    private listaClientesService : ListaClientesService,
    private categoriasService : CategoriasService
  ) {

  }
  
  ngOnInit() {

    let httpCalls = forkJoin( //permite hacer varias llamadas de una
      this.categoriasService.getAll()
    );
   
    httpCalls.subscribe( //para cualquier respuesta 
      (data: any) => {
        this.categorias = data[0]; //cada posición de data[i] coincide con las posiciones del array que mandamos con forkjoin
      },
      (error) => { //en caso de error http
      
      }
    );
   
  }
}
