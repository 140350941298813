import { Component, OnInit, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { CategoriasService } from '../services/categorias.service';
import { from, of, forkJoin, Subject } from 'rxjs';
import { ProductosService } from '../services/productos.service';
import { CarritoSharedService } from '../services/carrito-shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from "jquery";
import { map, takeUntil } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgSelectComponent } from '@ng-select/ng-select';


@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss']
})
export class CategoriasComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  public categorias = [];
  public categoriasMobile = [];
  public subCategorias = [];
  public productos = [];
  public id:string;
  public idNum:string;
  public string:string;
  public primProd:string;
  private noClickeado = true;
  public singleSlideOffset = true;
  public noWrap = true;
  activeSlideIndex: number;
  itemsPerSlide: number;
  itemsPerSlide2 = 1;
  innerWidth: any;
  public hover: boolean = false;
  //Variables para scroll infinite
  public arregloProd: Array<object>;
  private finishPage: Number = new Number();
  private actualPage: number;
  public showGoUpButton: boolean;
  showScrollHeight = 400;
  hideScrollHeight = 200;
  public selectedProduct = '0';
  offset = 0;
  limit = 40;
  infiniteScrollStatus: boolean = true;
  private destroy = new Subject<void>();
  firstTime = true;
  mobileMode;


  //MultiSelect 
  multiSelectList = [];
  selectedItems = [];


  //dialog modal
  modalRef: BsModalRef;
  str_marcas = '';
  filterActive: boolean = false;
  constructor(
    private Router : Router,
    private categoriasService: CategoriasService,
    private carritoService: CarritoSharedService,
    private ProductosService: ProductosService,
    private active: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private modalService : BsModalService,
    ) {
      //Scrol infinite
      this.actualPage = 0;
      this.showGoUpButton = false;
      // this.loader();
      //---- o ---
      this.active.paramMap.subscribe(ParamMap => {
        this.id = ParamMap.get('id');
        this.idNum = ParamMap.get('idnumerico');
        this.string = ParamMap.get('stringPadre');

  });
    }
    @HostListener('window:scroll', [])
    onWindowScroll() {
      if ((window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop) > this.showScrollHeight) {
        this.showGoUpButton = true;
      } else if (this.showGoUpButton &&
        (window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop) <
        this.hideScrollHeight) {
        this.showGoUpButton = false;
      }
    }

  ngOnInit(): void {
 
    this.inicio(0, this.offset, this.limit);
    //logica tab menu activos
    this.active.paramMap //Escucha cambios de ruta
      .pipe(
        map(paraMap => paraMap.get('stringPadre')), //Obtiene la ruta actual
        takeUntil(this.destroy) //Siempre limpia
      )
      .subscribe(routePathParam => this.categoriasService.actualizarPAthParam(routePathParam));
      //actualizar servicio
      var currentWidth = window.outerWidth;

       if(currentWidth <= 992) {
         this.mobileMode = 1;  
       }else {
         this.mobileMode = 0;
       }
  this.onGetMarcasCategorias();
  }

  onGetMarcasCategorias() {
    this.ProductosService.getMarcasCategorias(this.id).subscribe(resp => {
      let tempArr = [];
      resp['data'].map(m => {
        let multiSelectObj = {
          name: m.marDescripcion,
          id: m.marMarcaId
        }
        tempArr.push(multiSelectObj);
      });
      this.multiSelectList = tempArr;
    });
  }

  onDialogOpen(smModal: TemplateRef<any>){
   // this.modalRef = this.modalService.show(smModal);
    
    this.modalRef = this.modalService.show(smModal, {
      class: 'fixed-top'
    });
  }


  onFilter() {
    this.selectedProduct = '0';
    this.offset = 0;
    this.limit = 40;
    this.firstTime = true;
     this.inicio(0, 0, 40);
     if(this.mobileMode == 0) {
      //this.inicio(0, this.offset, this.limit);
      this.ngSelectComponent.close();   
    }
     if(this.mobileMode == 1) {
      //this.inicio(0, this.offset, this.limit);
      this.modalRef.hide();
     }
     //console.log(this.offset,this.limit);
   
  }

  onFilterRemove(e) {
    console.log(e);
    this.offset = 0;
    this.limit = 40;
    this.firstTime = true;
    this.actualPage = 0;
    this.inicio(this.selectedProduct, this.offset, this.limit);
  }
 
  onItemSelect(item: any) {
    $('.ng-clear-wrapper').removeAttr( "title" );
    console.log(item);
    let arr_temp = [];
    item.map(m => {
      arr_temp.push(m.id) 
    });
    this.str_marcas = arr_temp.join(',');
    if(item.length !== 0) {
      this.filterActive = true;
    }else {
      this.filterActive = false;
    }
     console.log(this.str_marcas);
  }
  onSelectAll(items: any) {
  //  console.log(items);
  }
  onMultiSelectClear() {
    this.offset = 0;
    this.limit = 40;
    this.firstTime = true;
    this.actualPage = 0;
 
    this.inicio(this.selectedProduct, this.offset, this.limit);
    if(this.mobileMode == 0) {
    this.ngSelectComponent.close();
    }
 
    if(this.mobileMode == 1) {
      console.log(this.mobileMode);
      //this.inicio(0, this.offset, this.limit);
      this.modalRef.hide();
    }
  }



  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.categoriasService.actualizarPAthParam(null);
  }

  async inicio(orden: any = 0, offset, limit){

    await this.spinner.show();
    
    //logica cantodad de items responsive
    this.innerWidth = await window.innerWidth;
    await this.itemSlideResponsive( this.innerWidth );

    //Logica de categorias
   

    let httpCalls = await forkJoin( //permite hacer varias llamadas de una
      this.ProductosService.getAllByCat(
        this.id,
        orden,
        offset, 
        limit,
        this.str_marcas
        ),
      this.categoriasService.getAllSubCategorias(this.id),
      this.categoriasService.getAll()
    );

    await httpCalls.subscribe( //para cualquier respuesta
      (data: any) => {
       
        this.productos = data[0].data; //cada posición de data[i] coincide con las posiciones del array que mandamos con forkjoin
        this.subCategorias = data[1].data; 

        this.categorias = data[2].data;
       
        
        this.categorias = this.categoriasService.formatearArreglo(this.string, this.categorias);
        this.primProd = this.subCategorias["0"].scaDescripcion;
        // this.finishPage = this.productos.length/40;
        // //console.log("finishPage", this.finishPage);

        //Elimino categorias con nombres vacíos
        this.subCategorias.forEach(item => {
          if(item.scaDescripcion == undefined || item.scaDescripcion == "") {
            this.subCategorias.splice(this.subCategorias.indexOf(item), 1);
          }
        });

        //Scroll infinite
        if (this.productos.length > 0) {
          this.arregloProd = new Array<object>();
          this.add40items();

        }

      },
      (error) => { //en caso de error http
        this.spinner.hide();

      }
    );

  }

  ngAfterViewInit(): void {
    this.getActualCat();
  }
  
  goSubCat(seo,id){
    this.Router.navigateByUrl('/subcategoria/' + this.string + '/' + this.id +'/' + seo + '/' + id);
  }
  goCat(seo, id){
    this.Router.navigateByUrl('/categorias/' + seo + '/' +id);
  }

  addProducto(producto) {
    this.carritoService.agregarProducto(producto);
    this.noClickeado = false;
  }
  itemSlideResponsive(width) {
    if (width > 768) {
      // window width is at least 768px
      this.itemsPerSlide = 6;
    } else {
      this.itemsPerSlide = 3;
    }
  }
  getActualCat(){
    setTimeout(() => { this.activeSlideIndex = 4; }, 3);
  } 
  async add40items() {
 
    await this.spinner.show();
    await this.crearArray();
    await this.spinner.hide(); 

  }
  onScroll() {
 
    //console.log("scroll", this.actualPage, this.finishPage);
    if (this.actualPage < this.finishPage) {
      this.add40items();
    } else {
    }
  }

  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }
  crearArray(){
   
    if(this.productos.length <= this.limit ){
      
      // let lineCounter = this.arregloProd.length;
      // for (let i = 0; i < this.limit; i ++) {
      //   this.arregloProd.push(this.productos[i+lineCounter]);
      // }
      if(this.firstTime) {
        this.arregloProd = this.arregloProd.concat(this.productos);
      }
      this.offset+=this.limit;
      //console.log(this.offset);
      console.log(this.selectedProduct);
      console.log(this.id);
      console.log(this.offset);
      console.log(this.limit);
      console.log(this.str_marcas);


      this.ProductosService.getAllByCat(
        this.id, 
        this.selectedProduct,
        this.offset,
        this.limit,
        this.str_marcas
        )
      .subscribe( resp=> {
       
        this.productos = resp['data']; 
       // console.log(this.productos);
      
        this.arregloProd = this.arregloProd.concat(this.productos);
      
        this.finishPage = Math.trunc(this.arregloProd.length/this.limit);
    
        this.actualPage++;
        this.firstTime = false;
        //console.log("finishPage:", this.finishPage, "prod length:", this.productos.length,"arreglo length:", this.arregloProd.length);
      })
    }
    else {
      this.arregloProd = this.productos;
     // console.log(this.arregloProd);
    }
  }


  ChangingValue(event){
    this.actualPage = 0;
    this.offset = 0;
    this.firstTime = true;
    this.inicio(this.selectedProduct, this.offset, this.limit);
  }

  loader(){
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 3 seconds */
      this.spinner.hide();
    }, 3000); 
  }

}
