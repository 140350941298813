import { Injectable } from '@angular/core';
import { ProductoItemComponent } from '../producto-item/producto-item.component';

@Injectable({
  providedIn: 'root'
})
export class CarritoSharedService {

  items = [];

  agregarProducto(product) {
    let carrito = JSON.parse(localStorage.getItem('carrito'));


    if(carrito == null) { //primer pedido
      product.qty = 1; //cantidad 1 por defecto
      let carrito = [];
      carrito.push(product);
      localStorage.setItem('carrito', JSON.stringify(carrito));
    } else { //ya hay elementos en el carrito
      if(this.findItemByIdInCart(product.prdProductoId)) {
        this.addQtyToItem(product.prdProductoId, 1);
      } else {
        //qty en 1
        product.qty = 1;
        carrito.push(product);
        localStorage.setItem('carrito', JSON.stringify(carrito))
      }
    }
   
  }

  totalCal():number {
    let items = this.getItems();
    let prcDto = 0;
    let prcCantidad = 0;
    let i = 0;
    let total = 0;
    if(items){
      for (let productos of items) {
      
        let precio = this.totalPriceByItem(productos.prdProductoId);
        precio = precio - (precio * parseFloat(productos.prcDto) / 100);
        total = total + precio; 
      } 
      return total;
  }
}

  addQtyToItem(id, qty) {
    let carrito = JSON.parse(localStorage.getItem('carrito'));
    if(carrito != null) {
      //console.log("is not null");
      carrito.forEach((item, index) => {
        if(item.prdProductoId == id) {  //si ya hay un item en el carro con el mismo id, incremento 1 la cantidaD (qty)
          carrito[index].qty = (item.qty) + qty;
        } 
      });
        localStorage.setItem('carrito', JSON.stringify(carrito));
    }
    else {
      //console.log("is null");
    }
  }
  
  substractQtyToItem(id, qty) {
    let carrito = JSON.parse(localStorage.getItem('carrito'));
    if(carrito != null) {
      carrito.forEach( (item, index) => {
        if(item.prdProductoId == id) {  //si ya hay un item en el carro con el mismo id, incremento 1 la cantidaD (qty)
        carrito[index].qty = (item.qty) - qty;
        } 
      });
        localStorage.setItem('carrito', JSON.stringify(carrito));
    }
  }
  setQtyToItem(id, qty: number) {
    let carrito = JSON.parse(localStorage.getItem('carrito'));
    if(carrito != null) {
      carrito.forEach((item, index) => {
        if(item.prdProductoId == id) {  //si ya hay un item en el carro con el mismo id, incremento 1 la cantidaD (qty)
          delete carrito[index].qty;
          carrito[index].qty = qty;
        } 
      });
        localStorage.setItem('carrito', JSON.stringify(carrito));
    }
  }
  
  totalPriceByItem(itemId):number {
    let item: any = this.findItemByIdInCart(itemId);
    let prcDto = 0;
    let prcCantidad = 0;
    let uFaltantes = 0;
    let i = 0;
    if (item.precios) {
      //if (item.precios.length > 0 && item.qty > 2) {
      if (item.precios.length > 0 && item.qty > 0) {
        for (let element of item.precios) {
     
          //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
          if (item.qty >= element.prcCantidad) {

            prcDto = element.prcDto;
            prcCantidad = element.prcCantidad;
            //en caso que sea la ultima promocion calcula el valor
            if (i == (item.precios.length - 1)) {
              let  nFixed = item.qty * (item.PrecioConIVA * (1 - element.prcDto / 100));
             
              return Number.parseFloat(nFixed.toFixed(2));
              //return this.CurrencyPipe.transform(item.qty * (item.PrecioConIVA * (1 - element.prcDto / 100)));
            }
            i++;
          } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
            let  nFixed = item.qty * (item.PrecioConIVA * (1 - prcDto / 100));
           
            return Number.parseFloat(nFixed.toFixed(2));
            //return this.CurrencyPipe.transform(item.qty * (item.PrecioConIVA * (1 - prcDto / 100)));
          }
        }
        uFaltantes = prcCantidad - item.qty;
        // });
      } 
      else if (item.oferta_semanal && item.oferta_semanal.length > 0 && item.qty > 0) {
        for (let element of item.oferta_semanal) {
          //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
          if (item.qty >= element.Cant) {    
            //en caso que sea la ultima promocion calcula el valor
            if (i >= (item.oferta_semanal.length - 1)) {
              //aca
              return item.qty * element.Precio;
              //return this.CurrencyPipe.transform(item.qty * element.Precio);
            }
            i++;
          } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
            //return this.CurrencyPipe.transform(item.qty * element.Precio);

            return item.qty * item.PrecioConIVA;
            //return this.CurrencyPipe.transform(item.qty * item.PrecioConIVA);
          }
        }

      }
      else if ( item.PrecioDestacado && item.PrecioDestacado != 0.00) {
        return item.PrecioDestacado * item.qty
        //return this.CurrencyPipe.transform(item.PrecioDestacado * item.qty);

      }
      else {
        return item.qty * item.PrecioConIVA
        //return this.CurrencyPipe.transform(item.qty * item.PrecioConIVA); //precio regular si no tiene array de precios
      }
    }
  }





  findItemByIdInCart(id) {
    let carrito = JSON.parse(localStorage.getItem('carrito'));
    let existe = false;
    let yaRespondio = false; // Esto es para evitar respuestas múltiples del each
    let itemEncontrado = false;
    if(carrito != null) {
      carrito.forEach(item => {
        if(item.prdProductoId == id && !yaRespondio) {  
          //si ya hay un item en el carro con el mismo id, incremento 1 la cantidaD (qty)
          yaRespondio = true;
          itemEncontrado = item;
        } 
      });
    }
    if(yaRespondio) { 
      return itemEncontrado;
    } else {
      return existe;
    }
    
  }


  getItems() {
    let carrito = JSON.parse(localStorage.getItem('carrito'));
    return carrito;
  }
  getItemsLenth() {
    let carrito = JSON.parse(localStorage.getItem('carrito'));
    if (carrito) {
      return carrito.length;
    }
  }

  // Gramos Update
  getProductosTotales(){
    let carrito = JSON.parse(localStorage.getItem('carrito'));
    let numProd: number = 0;
    if (carrito) {
      for( let i = 0; i < carrito.length; i++ ) {
        //if carrito[i].qty tiene decimal entonces sumo uno
        //sino sumo qty
        if(this.number_test(carrito[i].qty)) {
         numProd = numProd + 1;
        }else {
          numProd = numProd + carrito[i].qty;
        } 
      }
    }
    
    return numProd;
  }

  number_test(n) {
    var result = (n - Math.floor(n)) !== 0;
   if (result)
     return true;
   else
      return false;
}
  
  clearCart() {
    this.items = [];
    localStorage.removeItem('carrito');
  }


  removeItem(id) {
  localStorage.setItem(
      "carrito",
      JSON.stringify(
          JSON.parse(localStorage.getItem("carrito")).filter(item => {
              return item.prdProductoId !== id;
          })
      )
  );
  }
}
