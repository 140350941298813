import { Component, OnInit, Input, HostListener, ElementRef, ChangeDetectorRef } from '@angular/core';
import { CarritoSharedService } from '../../services/carrito-shared.service';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/es-AR';
import { DomSanitizer } from '@angular/platform-browser';
import { IfStmt } from '@angular/compiler';
import { CommonService } from 'src/app/services/common.service';
registerLocaleData(localeFr, 'es-AR');


@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss']
})
export class ProductoComponent implements OnInit {
  public noClickeado = true;
  @Input() producto: any;
  @Input() horizontal: boolean;
  @Input() disabled: any;
  mySubscription: any;
  loading: boolean = true;
  mobile: boolean;
  qty = 1;
  QtyGramos = 100;
  urlShare = 'https://web.whatsapp.com/send?text=https://drautoservicio.com.ar/#/productos';
  urlShareMobile = 'https://wa.me/send?text=https://drautoservicio.com.ar/#/productos';
  

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this.elRef.nativeElement.value;
    this.elRef.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if ( initalValue !== this.elRef.nativeElement.value) {
      event.stopPropagation();
    }
  }
  @HostListener('window:resize', ['$event'])
    onResize(event) {
      
      if (event.target.innerWidth < 768) {
        this.mobile = true;
      }
      else {
        this.mobile = false;
        
      }
    }
  constructor(
    private carritoService: CarritoSharedService,
     private elRef: ElementRef,
      private Router: Router, 
      public cd: ChangeDetectorRef,
      private toastr: ToastrService,
      public commonService: CommonService,
       private sanitizer: DomSanitizer) {
      this.Router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.mySubscription = this.Router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // Trick the Router into believing it's last link wasn't previously loaded
          this.Router.navigated = false;
        }
      });
     
       // this.producto.prcDto = 5;
    

     }

  ngOnInit(): void {
 
   // this.producto.prcDto = 5;
    if (window.screen.width < 768) {
      this.mobile = true;
    }
    this.initProd();
    
    
    this.producto.PrecioDestacado = 0; 
   console.log(this.producto);

 
   this.onProductoConDescuentoX1();
    
   
/*
    this.producto.precio.map(m => {
        m.prcCantidad
    });
*/

  }



  onProductoConDescuentoX1() {
    if(this.producto.precios.length !== 0) {
      for(var _i = 0; _i < this.producto.precios.length; _i++) {
      
        if(this.producto.precios[_i].prcCantidad == 1) {
            
            this.producto.precio_anterior = true;
            console.log(this.producto.precios[_i]);  
          }    
      }
    }
  }



  ngOnChanges(): void {}
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
  initProd(){
    let productoEnCarrito = this.carritoService.findItemByIdInCart(this.producto.prdProductoId);
    if(productoEnCarrito) {
     
      this.noClickeado = false;
      this.cd.detectChanges();
      this.qty = productoEnCarrito['qty'];
     // this.QtyGramos = // productoEnCarrito['gramos'];
    }
  }
  addProducto(producto) {
    let productoEnCarrito;
    productoEnCarrito = this.carritoService.findItemByIdInCart(producto.prdProductoId);
    //lo buscamos si está en el carrito para agregarle la qty, sino encuentra, es porque se agrega de 0
    
    if(productoEnCarrito) {
      producto.qty = productoEnCarrito.qty;
    }
    this.carritoService.agregarProducto(producto);
    this.noClickeado = false;
    this.cd.detectChanges();
    this.agregarToast(producto.prdNombre);
  }
  
  goProducto(seo,id){ 
    let param1 = seo.split(' ').join('_');
    let param2 = id.split(' ').join('_');
    this.Router.navigateByUrl('/productos/' + param1 + '/' + param2);
  }
  


  minusQty(id, qty, selector, qtyInput){
    var $id = selector;
    var input = <HTMLInputElement>document.getElementById($id);
    var num:any = parseFloat((<HTMLInputElement>document.getElementById($id)).value);

    
    if( num > 0){ //Para que no haya pedidos negativo      
      this.carritoService.substractQtyToItem(id, qty);
      input.stepDown();
      this.promoToast(id);
      // this.restarToast(selector)
    }
  }
  plusQty(id, qty, selector, producto){
    //console.log("debug", id, selector, producto);
    var $id = selector;
    var input = <HTMLInputElement>document.getElementById($id);
    input.stepUp();
    let productoEnCarrito;
    productoEnCarrito = this.carritoService.findItemByIdInCart(producto.prdProductoId);
    if(productoEnCarrito) {
      producto.qty = productoEnCarrito.qty;
      //producto.qty = qty;
      //console.log("qty", producto.qty);
      //console.log(productoEnCarrito.qty);
      this.carritoService.addQtyToItem(id, qty);
      //this.promoToast(id);
    }
    else {
      producto.qty = qty;
  
      this.addProducto(producto);
    }
    // this.sumarToast(selector);
  }

  /* Productos por Gramos */
  plusQtyGramos(id, qty, selector, producto) {
    let currentGramos = 100 + this.OnQtyGramos();
    this.QtyGramos = currentGramos;
    let productoEnCarrito;
    productoEnCarrito = this.carritoService.findItemByIdInCart(producto.prdProductoId);
 
      if (!productoEnCarrito) {
      this.addProducto(producto);
      }
      this.carritoService.addQtyToItem(id, qty);
     //console.log("debug", id, selector, producto);
     /*
     var $id = selector;
     var input = <HTMLInputElement>document.getElementById($id);
     input.stepUp();
     let productoEnCarrito;
     productoEnCarrito = this.carritoService.findItemByIdInCart(producto.prdProductoId);
     if(productoEnCarrito) {
       producto.qty = productoEnCarrito.qty;
       //console.log("qty", producto.qty);
       //console.log(productoEnCarrito.qty);
       this.carritoService.addQtyToItem(id, qty);
       this.promoToast(id);
     }
     else {
       this.addProducto(producto);
     }
     */
     // this.sumarToast(selector);
  }


  onLoad() {
    this.loading = false;
  }
  agregarToast(nombre) {
    this.toastr.success(nombre, 'Se ha agregado exitosamente');
  }
  sumarToast(nombre) {
    this.toastr.success(nombre, 'Se ha sumado una unidad');
  }
  restarToast(nombre) {
    this.toastr.success(nombre, 'Se ha restado una unidad');
  }
  sumarTres(num) {

    return Number(num) + 3;
  }
  enterChange(id, qty): void {  
    let cant: number = Number(qty);
    this.carritoService.setQtyToItem(id, cant);
  }
  checkIfNumber(event) {
  }
  inputValidator(e) {
    if(parseInt(e.key) || parseInt(e.key) === 0 || e.key === "Backspace"){
      return true;

    }else{
      return false;

    }
  }
  promoToast(id) {
    console.log('oferta semanal');
    let item: any = this.carritoService.findItemByIdInCart(id);
    let prcCantidad = 0;
    let uFaltantes = 0;
    let i = 0;
    let quedaPromo = false;
    let string: string;
    if (item.precios.length > 0 && item.TieneOfertaSemanal == 1) {
      for (let element of item.precios) {
        //asegurarnos  que tome la primer promo
        if (1 == (item.precios.length)) {
          prcCantidad = element.prcCantidad;
        }
        //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
        if (item.qty >= element.prcCantidad) {

        } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra
          quedaPromo = true;
          prcCantidad = element.prcCantidad;
          break;

        }
      }
      uFaltantes = prcCantidad - item.qty;
    }
    else if(item.TieneOfertaSemanal == 1) {
      for (let element of item.oferta_semanal) {
        //asegurarnos  que tome la primer promo
        if (1 == (item.oferta_semanal.length)) {
          prcCantidad = element.Cant;
        }
        //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
        if (item.qty >= element.Cant) {

        } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra
          quedaPromo = true;
          prcCantidad = element.Cant;
          break;

        }
      }
      uFaltantes = prcCantidad - item.qty;
    }
    else {
      quedaPromo = false;
    }
    if (quedaPromo) {
      string = "faltan " + uFaltantes + " unidades para obtener descuento x" + prcCantidad;
      this.toastr.success(string);
      string = '';
    }

  }
  sanitizarUrl(param1, param2) {
    param1 = param1.split(' ').join('_');
    param2 = param2.split(' ').join('_');
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.urlShare + '/' + param1 + '/' + param2);
  }
  sanitizarUrlMobile(param1, param2) {
    param1 = param1.split(' ').join('_');
    param2 = param2.split(' ').join('_');
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.urlShareMobile + '/' + param1 + '/' + param2);
  }
  Qty() {
    let producto = this.carritoService.findItemByIdInCart(this.producto.prdProductoId);
    let qty:any = producto['qty']; 
   
    if(qty == 1.3877787807814457e-16) {
      this.carritoService.removeItem(producto['prdProductoId']);
      this.noClickeado = true;
      this.cd.detectChanges();
    }
   if(producto['EsDeBalanza'] === '1') {
    qty = Number.parseFloat(producto['qty']).toFixed(1);
   }
   if(producto['EsDeBalanza'] !== '1') {
    qty = producto['qty'];
   }
   return qty;
  }

  OnQtyGramos() {
    return this.QtyGramos;
  }
}

