
<div 
style="width: 100%;"
#quaggaDiv
id="quagga2Example"
> </div>


<div class="card text-center" style="width: 90%; margin: 1rem">
   
    <div class="card-body">
    <p class="card-text" style="margin-bottom: 2px !important">Asegúrese de acercar lo más posible el código a leer. </p>
    <p class="card-text" style="margin-bottom: 2px !important">
      Si el código no puede ser leido, intente cambiando de camara
    </p>
      <button  style="margin-bottom: 0.5rem; margin-top: 0px;" class="btn btn-light" (click)="this.reOpenDialog()" >
      <span style="margin: 0.5rem;">Selecionar camara <i class="fas fa-cog"></i></span>  
      </button>
   
    <p class="card-text" style="margin-bottom: 2px !important">
        En caso de “Producto no encontrado”, verifique que el código leído coincida con el código impreso,  caso contrario, intente nuevamente.
      </p>
      <p class="card-text" >
        Gracias!!
      </p>
    
  


    </div>
  </div>




  <ng-template #barcodeTemplate>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Selecionar Dispositivo de Camera</h4>
      <button  style="margin-bottom: 0.5rem;" class="btn btn-light" (click)="this.changingValue()" >
        <span style="margin: 0.5rem;"><i class="fas fa-times"></i></span>  
        </button>
    </div>
    <div class="modal-body">
      <select class="col-md-2 
       custom-select"
       id="barcodeModal"
       (change)="changingValue($event)" 
        [(ngModel)]="selectedDeviceId">
       <option *ngFor="let device of this.devices" 
        [value]="device.deviceId">{{device.labelES}}
      </option>
     </select>
    </div>


  </ng-template>
