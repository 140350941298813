import { Component, OnInit } from '@angular/core';
import { CategoriasService } from '../services/categorias.service';
import { from, of, forkJoin } from 'rxjs';
import { ProductosService } from '../services/productos.service';
import { CarritoSharedService } from '../services/carrito-shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})

export class ProductosComponent implements OnInit {
  public categorias = [];
  public producto = [];
  public id:string;
  public string:string;
  private noClickeado = true;
  public singleSlideOffset = true;
  public noWrap = true;
  public loading: boolean = true;


  constructor(
    private Router : Router,
    private categoriasService: CategoriasService,
    private carritoService: CarritoSharedService,
    private productosService: ProductosService,
    private active: ActivatedRoute,
    private spinner: NgxSpinnerService
    ) {
      // this.id = parseInt(this.active.snapshot.params.id);
      this.active.paramMap.subscribe(ParamMap => {
        this.id = ParamMap.get('id');
        this.string = ParamMap.get('string');
        this.string = this.quitarGuion(this.string);
  });
    }

  ngOnInit(): void {
    this.getProd();
  }
  async getProd(){
    await this.spinner.show();
    //Logica de categorias
    let httpCalls = await forkJoin( //permite hacer varias llamadas de una
      this.productosService.getProducto(this.id)
    );
    
    await httpCalls.subscribe( //para cualquier respuesta 
      (data: any) => {
        this.spinner.hide();
        this.producto = data[0].data; //cada posición de data[i] coincide con las posiciones del array que mandamos con forkjoin
        this.loading = false;
      },
      (error) => { //en caso de error http
        //console.log("error " + error);
        this.spinner.hide();
        this.loading = false;      
      }
    );

  }
  
  quitarGuion(nombre){
    return nombre.split('_').join(' ');
  }
  
}
