<a
  href="https://wa.me/5493415952021"
  target="_blank"
  id="flotante_whatsapp"
  class="d-lg-inline flotante_whatsapp"
  *ngIf="WSP && this.isOnBarcodeRoute == false"
>
  <img src="../../assets/img/Recurso_155.png" class="img-fluid" alt="" />
</a>

<a
  href="https://wa.me/5493415952021"
  target="_blank"
  id="flotante_whatsapp"
  class="d-lg-inline flotante_whatsapp"
  *ngIf="!WSP && this.isOnBarcodeRoute == false"
  style="bottom: 25px !important; z-index: 999"
>
  <img src="../../assets/img/Recurso_155.png" class="img-fluid" alt="" />
</a>

<a
  routerLink="/bar-code"
  *ngIf="this.isMobile && this.isOnBarcodeRoute == false && !this.isOnCarrito"
  id="flotante_appBarcode"
  class="d-lg-inline"
  style="z-index: 999; bottom: 25px !important"
>
  <img src="../../assets/img/barcodeapp.png" class="img-fluid" alt="" />
</a>

<a
  routerLink="/bar-code"
  *ngIf="this.isMobile && this.isOnBarcodeRoute == false && this.isOnCarrito"
  id="flotante_appBarcode"
  class="d-lg-inline"
  style="z-index: 999; bottom: 140px !important"
>
  <img src="../../assets/img/barcodeapp.png" class="img-fluid" alt="" />
</a>

<!--
     <div class="container-fluid">
    <div class="row justify-content-between ">
        <div class="col-12 py-2 d-flex text-light" style="background: #202a5d;">
            <span class="px-3 d-none d-md-inline">Pavón 5068 [casi esq. Lamadrid]</span>
            <span class="px-3 d-none d-md-inline">Caferatta 3025</span>
            <span class="px-3 d-none d-md-inline">Av. Pellegrini 4820</span>
            <span class="px-3 mx-auto mx-md-0"><img style="width: 20px;" src="../assets/img/iconos-02.png" alt="">WhatsApp: +54 9 3415 95-2021</span>
        </div>
    </div>
</div> 
-->
<header class="p-0">
  <div class="container-fluid">
    <div class="row justify-content-between pb-2" id="menu">
      <div class="col-12 py-1 d-flex carousel" style="background: #202a5d">
        <span class="px-3 d-none d-md-inline">Av. Pellegrini 4820</span>
        <span class="px-3 d-none d-md-inline">Pavón 5068</span>
        <span class="px-3 d-none d-md-inline">Cafferata 3025</span>
        <span class="px-3 d-none d-md-inline">Santa Fe 5080</span>
        <span class="px-3 d-none d-md-inline mx-auto mx-md-0"
          ><img
            style="width: 20px; height: auto"
            src="../assets/img/iconos-02.png"
            alt=""
          />WhatsApp: +54 9 3415 95-2021</span
        >
        <carousel
          class="d-block d-md-none carousel-prod w-100"
          [showIndicators]="false"
          [itemsPerSlide]="1"
          [interval]="2500"
        >
          <slide class="mx-auto">
            <div class="d-flex">
              <span class="mx-auto">Av. Pellegrini 4820</span>
            </div>
          </slide>
          <slide class="mx-auto">
            <div class="d-flex">
              <span class="mx-auto">Pavón 5068</span>
            </div>
          </slide>
          <slide class="mx-auto">
            <div class="d-flex">
              <span class="mx-auto">Cafferata 3025</span>
            </div>
          </slide>
          <slide class="mx-auto">
            <div class="d-flex">
              <span class="mx-auto">Santa Fe 5080</span>
            </div>
          </slide>
          <slide class="mx-auto">
            <div class="d-flex">
              <span class="mx-auto"
                ><img
                  style="width: 20px"
                  src="../assets/img/iconos-02.png"
                  alt=""
                />WhatsApp: +54 9 3415 95-2021</span
              >
            </div>
          </slide>
        </carousel>
      </div>
      <div class="col-3 d-flex align-items-center px-md-5">
        <a class="hambur-btn btn p-0 mr-3 d-none">
          <div
            class="btn container"
            type="button"
            data-toggle="collapse"
            data-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            onclick="myFunction(this)"
          >
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
          </div>
        </a>
        <div class="d-inline-block" id="main-logo">
          <a routerLink="/" routerLinkActive="active">
            <img
              [ngStyle]="{ width: this.isOnBarcodeRoute == true ? '10em' : '' }"
              class="img-fluid logo p-0 pt-3"
              [ngClass]="{ show: this.isOnBarcodeRoute }"
              src="../assets/img/DR_AUTO-LOGO-OKblanco_copia.png"
              alt="Distribuciones Rosario"
            />
          </a>
        </div>
      </div>

      <div class="col-8 col-md-7 p-0 offset-1 align-self-center mt-3 mr-4">
        <div class="ml-auto" *ngIf="this.isOnBarcodeRoute == false">
          <div *ngIf="this.isMobile == false || this.isTable">
            <app-buscador [onlySearch]="'default'"></app-buscador>
          </div>
          <div *ngIf="isMobile">
            <app-buscador [onlySearch]="'onlySarch'"></app-buscador>
          </div>
          <!--
                    <div class="d-inline d-lg-none dropdown-toggle" href="#" role="button" id="example" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (mouseenter)="mouseEnter(true) " (mouseleave)="mouseLeave(false)">
                        <button class="btn btn-secondary d-inline" style="background: #b7570c; border: #b7570c; padding: 2.5px 0!important;" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-bars" style="padding: 2.5px 5px;"></i>
                        </button>
                        <div class="dropdown-menu" attr.aria-labelledby="example" style="background: #fab782;">
                            <a class="dropdown-item">
                                <button class="btn btn-secondary my-1 login-btn d-inline m-0 d-lg-none" routerLink="/login" *ngIf="!isLogged()">Login</button>
                            </a>
                            <a class="dropdown-item">
                                <button class="btn btn-secondary login-btn d-inline mx-2 my-0 d-lg-none" *ngIf="isLogged()" routerLink="/perfil"><i class="far fa-user"></i></button>
                            </a>
                            <a class="dropdown-item">
                                <button class="btn btn-secondary login-btn d-inline mx-2 my-0 d-lg-none" (click)="logOut()" *ngIf="isLogged()"><i class="fas fa-sign-out-alt"></i></button>
                                <button class="btn btn-secondary my-1 login-btn d-inline m-0  d-lg-none" routerLink="/registro" *ngIf="!isLogged()">Registrarse</button>
                            </a>
                            <a class="dropdown-item">
                                <button class="btn btn-secondary my-1 login-btn d-block" (click)="downloadMyFile()"><i class="fa fa-download"></i> Lista precio</button>
                            </a>
                             <a class="dropdown-item">
                                <button class="btn btn-secondary my-1 login-btn d-block" routerLink="/instrucciones-pwa">Instalar PWA</button>
                            </a> -->

          <!-- <ng-container *ngFor="let item of categorias">
                                <a class="dropdown-item"  (click)="goCat(item.catDescripcion, item.catCategoriaId)">{{item.catDescripcion}}</a>
                            </ng-container> 
                        </div>
                    </div>
                -->

          <div class="loginBTNGroup">
            <button
              class="btn btn-secondary m-1 login-btn d-none d-lg-inline"
              (click)="downloadMyFile()"
            >
              <i class="fa fa-download"></i> Lista precio
            </button>

            <button
              class="btn btn-secondary m-1 login-btn d-none d-lg-inline"
              *ngIf="isLogged()"
              routerLink="/perfil"
            >
              <i class="fa fa-user"></i> Perfil
            </button>

            <button
              class="btn btn-secondary m-1 login-btn d-none d-lg-inline"
              routerLink="/registro"
              *ngIf="!isLogged()"
            >
              <i class="fa fa-user-plus"></i> Registrarse
            </button>

            <button
              class="btn btn-secondary m-1 login-btn d-none d-lg-inline"
              routerLink="/login"
              *ngIf="!isLogged()"
            >
              <i class="fa fa-sign-in"></i> Login
            </button>

            <button
              class="btn btn-secondary m-1 login-btn d-none d-lg-inline"
              (click)="logOut()"
              *ngIf="isLogged()"
            >
              <i class="fa fa-sign-out-alt"></i> Logout
            </button>
          </div>

          <!-- <button class="btn btn-secondary my-1 login-btn d-none d-lg-inline" routerLink="/instrucciones-pwa">Instalar PWA</button> -->

          <!-- <a routerLink="/carrito" routerLinkActive="active">carrito</a> | -->
          <!--<a routerLink="/login" routerLinkActive="active">login</a>-->
        </div>
      </div>
      <!-- <div class="col-12 d-block d-md-none">

            </div> -->
    </div>

    <nav
      *ngIf="this.isMobile"
      class="navbar navbar-expand-lg navbar-light"
      style="padding: 0"
    >
      <button
        *ngIf="this.isMobile"
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars" style="color: #fff; font-size: 1.2em"></i>
      </button>

      <div>
        <app-buscador [onlySearch]="'onlyCarrito'"></app-buscador>
        <div
          class="d-inline d-lg-none dropdown-toggle"
          href="#"
          role="button"
          id="example"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          (mouseenter)="mouseEnter(true)"
          (mouseleave)="mouseLeave(false)"
        >
          <button
            class="btn btn-secondary d-inline"
            style="
              background: #b7570c;
              border: #b7570c;
              padding: 2.5px 0 !important;
            "
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-bars" style="padding: 2.5px 5px"></i>
          </button>
          <div
            class="dropdown-menu"
            attr.aria-labelledby="example"
            style="background: #fab782; left: auto !important"
          >
            <a class="dropdown-item">
              <button
                class="btn btn-secondary my-1 login-btn d-block"
                (click)="downloadMyFile()"
              >
                <i class="fa fa-download"></i> Lista precio
              </button>
            </a>

            <a class="dropdown-item">
              <button
                class="btn btn-secondary login-btn d-inline mx-2 my-0 d-lg-none"
                *ngIf="isLogged()"
                routerLink="/perfil"
              >
                <i class="fa fa-user"></i> Perfil
              </button>
            </a>
            <a class="dropdown-item">
              <button
                class="btn btn-secondary my-1 login-btn d-inline m-0 d-lg-none"
                routerLink="/registro"
                *ngIf="!isLogged()"
              >
                <i class="fa fa-user-plus"></i> Registrarse
              </button>
            </a>
            <a class="dropdown-item">
              <button
                class="btn btn-secondary my-1 login-btn d-inline m-0 d-lg-none"
                routerLink="/login"
                *ngIf="!isLogged()"
              >
                <i class="fa fa-sign-in"></i> Login
              </button>
            </a>
            <a class="dropdown-item">
              <button
                class="btn btn-secondary login-btn d-inline mx-2 my-0 d-lg-none"
                (click)="logOut()"
                *ngIf="isLogged()"
              >
                <i class="fa fa-sign-out-alt"></i> Logout
              </button>
            </a>
            <!-- <a class="dropdown-item">
                            <button class="btn btn-secondary my-1 login-btn d-block" routerLink="/instrucciones-pwa">Instalar PWA</button>
                        </a> -->

            <!-- <ng-container *ngFor="let item of categorias">
                            <a class="dropdown-item"  (click)="goCat(item.catDescripcion, item.catCategoriaId)">{{item.catDescripcion}}</a>
                        </ng-container> -->
          </div>
        </div>
        <!--
                <div *ngIf="i <= 2">
                    <a 
                   style="border-bottom: 1px solid white;"
                    (click)="goCat(item.catDescripcion, item.catCategoriaId)"> #{{item.catDescripcion}}</a>
                </div>
            -->
      </div>

      <div
        class="collapse navbar-collapse"
        style="z-index: 99999"
        id="navbarNav"
      >
        <ng-container *ngFor="let item of categorias; index as i">
          <ul class="d-inline dropdown menu-categoria">
            <li style="text-decoration: none; display: block"></li>
            <li
              style="background-color: transparent; border: none"
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              <!--
                                (click)="goCat(item.catDescripcion, item.catCategoriaId,spanColapsable)"
                            -->
              <a
                style="text-decoration: none; color: white"
                class="text-left"
                data-toggle="collapse"
                [attr.href]="'#collapseExample-' + i"
                (click)="onPlusMinusSpanClick($event, icon)"
              >
                <img
                  style="width: 20px"
                  [src]="switch(item.catDescripcion)"
                  alt=""
                />
                {{ item.catDescripcion }}
              </a>
              <span
                (click)="onPlusMinusSpanClick($event, icon)"
                #spanColapsable
                class="text-right"
                data-toggle="collapse"
                [attr.href]="'#collapseExample-' + i"
              >
                <i
                  #icon
                  class="fas fa-plus"
                  style="color: #fff; font-size: 1.2em; padding-right: 0.7em"
                ></i>
              </span>
            </li>

            <div
              style="max-height: 50vh; overflow-y: auto"
              class="collapse"
              attr.aria-labelledby="{{ item.catDescripcion }}"
              id="collapseExample-{{ i }}"
            >
              <a
                style="cursor: pointer"
                class="dropdown-item"
                *ngFor="let sub of item.subcategotias"
                (click)="
                  goSubCat(
                    item.catDescripcion,
                    item.catCategoriaId,
                    sub.scaDescripcion,
                    sub.scaSubCategoriaId
                  )
                "
                >{{ sub.scaDescripcion }}</a
              >
            </div>
          </ul>
        </ng-container>
        <!--
              <ul class="navbar-nav" style="margin-top: 0; padding: 0;">
                <li 
                *ngFor="let item of categorias; index as i"
                style="background-color: transparent;"
                [ngStyle]="{'border-bottom':  (categorias.length - 1) !== i ? '1px solid white' : '' }"
                class="nav-item active" 
                >
                 <a (click)="goCat(item.catDescripcion, item.catCategoriaId)">{{item.catDescripcion}}</a>
                </li>
              </ul>
            -->
      </div>
    </nav>
    <div *ngIf="this.isTable && this.isMobile == false" class="text-right">
      <div
        class="d-inline dropdown-toggle"
        href="#"
        role="button"
        id="example"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        (mouseenter)="mouseEnter(true)"
        (mouseleave)="mouseLeave(false)"
      >
        <button
          class="btn btn-secondary d-inline"
          style="
            background: #b7570c;
            border: #b7570c;
            padding: 2.5px 0 !important;
          "
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fas fa-bars" style="padding: 2.5px 5px"></i>
        </button>
        <div
          class="dropdown-menu"
          attr.aria-labelledby="example"
          style="background: #fab782; left: auto !important"
        >
          <a class="dropdown-item">
            <button
              class="btn btn-secondary my-1 login-btn d-block"
              (click)="downloadMyFile()"
            >
              <i class="fa fa-download"></i> Lista precio
            </button>
          </a>
          <a class="dropdown-item">
            <button
              class="btn btn-secondary login-btn d-inline mx-2 my-0"
              *ngIf="isLogged()"
              routerLink="/perfil"
            >
              <i class="fa fa-user"></i> Perfil
            </button>
          </a>
          <a class="dropdown-item">
            <button
              class="btn btn-secondary my-1 login-btn d-inline m-0"
              routerLink="/registro"
              *ngIf="!isLogged()"
            >
              <i class="fa fa-user-plus"></i> Registrarse
            </button>
          </a>
          <a class="dropdown-item">
            <button
              class="btn btn-secondary my-1 login-btn d-inline m-0"
              routerLink="/login"
              *ngIf="!isLogged()"
            >
              <i class="fa fa-sign-in"></i> Login
            </button>
          </a>
          <a class="dropdown-item">
            <button
              class="btn btn-secondary login-btn d-inline mx-2 my-0"
              (click)="logOut()"
              *ngIf="isLogged()"
            >
              <i class="fa fa-sign-out-alt"></i> Logout
            </button>
          </a>
        </div>
      </div>
    </div>

    <!--this.isOnBarcodeRoute == false  202a5d || this.isMobile == true  -->
    <div
      *ngIf="isMobile == false || this.isTable"
      class="row align-items-end d-lg-block"
      id="menu-collap"
      [ngClass]="{ show: true }"
      style="background-color: #202a5d"
    >
      <div class="col-12 mt-4 p-0 d-flex">
        <div
          class="collapse mx-auto"
          id="collapseExample"
          [ngClass]="{ show: true }"
        >
          <ul class="m-0 mx-auto">
            <li class="ofertas">
              <a style="cursor: pointer" (click)="onNavigateOfertas()"
                >Ofertas</a
              >
            </li>
            <ng-container *ngFor="let item of categorias; index as i">
              <div class="d-inline dropdown menu-categoria">
                <li
                  style="cursor: pointer"
                  [ngClass]="{ active: selectedItem == item?.catDescripcion }"
                  class="dropdown-toggle"
                  href="#"
                  role="button"
                  id="{{ item.catDescripcion }}"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  (mouseenter)="mouseEnter(true)"
                  (mouseleave)="mouseLeave(false)"
                >
                  <!-- (click)="goCat(item.catDescripcion, item.catCategoriaId)" -->
                  <a>
                    <img
                      style="width: 20px"
                      [src]="switch(item.catDescripcion)"
                      alt=""
                    />

                    {{ item.catDescripcion }}</a
                  >
                </li>
                <div
                  class="dropdown-menu"
                  attr.aria-labelledby="{{ item.catDescripcion }}"
                >
                  <a
                    style="cursor: pointer"
                    class="dropdown-item"
                    *ngFor="let sub of item.subcategotias"
                    (click)="
                      goSubCat(
                        item.catDescripcion,
                        item.catCategoriaId,
                        sub.scaDescripcion,
                        sub.scaSubCategoriaId
                      )
                    "
                    >{{ sub.scaDescripcion }}</a
                  >
                </div>
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
<div *ngIf="this.isOnBarcodeRoute == false" class="separador"></div>

<router-outlet></router-outlet>

<section class="pre-footer py-5" *ngIf="this.isOnBarcodeRoute == false">
  <div class="container pb-5">
    <div class="row justify-content-center">
      <div class="col-10">
        <h4
          class="text-uppercase font-weight-bold pb-3 text-center medios-pago-texto"
        >
          Medios de pago
        </h4>
      </div>
      <div class="col-9 mx-auto">
        <div class="row justify-content-center">
          <div class="col-6 col-md-2 d-flex justify-self-center">
            <div class="mx-auto" style="width: 50px">
              <img
                class="mx-auto py-3"
                style="width: 100%; height: auto !important"
                src="../../../assets/medios-pagos/342-3421828_imagenes-de-diners-club-hd-png-download.png"
                alt=""
              />
            </div>
          </div>
          <div class="col-6 col-md-2 d-flex justify-self-center">
            <div class="mx-auto" style="width: 50px">
              <img
                class="mx-auto py-3"
                style="width: 100%; height: auto !important"
                src="../../../assets/medios-pagos/1200px-American_Express_logo_(2018).svg.png"
                alt=""
              />
            </div>
          </div>
          <div class="col-6 col-md-2 d-flex justify-self-center">
            <div class="mx-auto" style="width: 50px">
              <img
                class="mx-auto py-3"
                style="width: 100%; height: auto !important"
                src="../../../assets/medios-pagos/1200px-Mastercard-logo.svg.png"
                alt=""
              />
            </div>
          </div>
          <div class="col-6 col-md-2 d-flex justify-self-center">
            <div class="mx-auto" style="width: 50px">
              <img
                class="mx-auto py-3"
                style="width: 100%; height: auto !important"
                src="../../../assets/medios-pagos/cabal_credito.png"
                alt=""
              />
            </div>
          </div>
          <div class="col-6 col-md-2 d-flex justify-self-center">
            <div class="mx-auto" style="width: 50px">
              <img
                class="mx-auto py-3"
                style="width: 100%; height: auto !important"
                src="../../../assets/medios-pagos/Cencosud.png"
                alt=""
              />
            </div>
          </div>
          <div class="col-6 col-md-2 d-flex justify-self-center">
            <div class="mx-auto" style="width: 50px">
              <img
                class="mx-auto py-3"
                style="width: 100%; height: auto !important"
                src="../../../assets/medios-pagos/CMR_Falabella.svg"
                alt=""
              />
            </div>
          </div>
          <div class="col-6 col-md-2 d-flex justify-self-center">
            <div class="mx-auto" style="width: 50px">
              <img
                class="mx-auto py-3"
                style="width: 100%; height: auto !important"
                src="../../../assets/medios-pagos/como-obtener-resument-tarjeta-naranja_foro.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="col-6 col-md-2 d-flex justify-self-center">
            <div class="mx-auto" style="width: 50px">
              <img
                class="mx-auto py-3"
                style="width: 100%; height: auto !important"
                src="../../../assets/medios-pagos/descarga.jpeg"
                alt=""
              />
            </div>
          </div>
          <div class="col-6 col-md-2 d-flex justify-self-center">
            <div class="mx-auto" style="width: 50px">
              <img
                class="mx-auto py-3"
                style="width: 100%; height: auto !important"
                src="../../../assets/medios-pagos/nativa-nacion-logo.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="col-6 col-md-2 d-flex justify-self-center">
            <div class="mx-auto" style="width: 50px">
              <img
                class="mx-auto py-3"
                style="width: 100%; height: auto !important"
                src="../../../assets/medios-pagos/tarjeta-argencard-logo.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="col-6 col-md-2 d-flex justify-self-center">
            <div class="mx-auto" style="width: 50px">
              <img
                class="mx-auto py-3"
                style="width: 100%; height: auto !important"
                src="../../../assets/medios-pagos/unnamed.png"
                alt=""
              />
            </div>
          </div>
          <div class="col-6 col-md-2 d-flex justify-self-center">
            <div class="mx-auto" style="width: 50px">
              <img
                class="mx-auto py-3"
                style="width: 100%; height: auto !important"
                src="../../../assets/medios-pagos/visa.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center py-3">
      <div class="col-6 col-md-3 d-flex my-3">
        <a
          class="btn btn-secondary btn-block text-uppercase font-weight-bold mx-auto"
          target="_blank"
          href="https://www.mercadopago.com.ar/ayuda/medios-de-pago-cuotas-promociones_264"
          >ver todos</a
        >
      </div>
    </div>
  </div>
</section>
<div class="footer" *ngIf="showButton">
  <div
    id="prompt"
    class="prompt bg-light border-top"
    [ngClass]="[showButton ? 'show' : 'hide']"
  >
    <div class="font-weight-bold">Agregar a la pantalla de inicio</div>
    <small>Esta app puede instalarse en la pantalla de inicio</small>
    <div class="text-center">
      <button
        id="buttonCancel"
        type="button"
        class="font-weight-bold text-muted btn-sm btn btn-link"
        (click)="closeHomeScreen()"
      >
        Cancelar
      </button>
      <button
        id="buttonAdd"
        type="button"
        class="font-weight-bold text-primary btn-sm btn btn-link"
        (click)="addToHomeScreen()"
      >
        Agregar
      </button>
    </div>
  </div>
  <!-- <button (click)="addToHomeScreen()" >Agregar al escritorio</button> -->
</div>

<footer *ngIf="this.isOnBarcodeRoute == false">
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-md-3">
        <img
          class="img-fluid logo-footer p-0 mb-4"
          src="../assets/img/DR_AUTO-LOGO-OKblanco_copia.png"
          alt="Distribuciones Rosario"
        />
      </div>
      <div class="col-md-3 mb-3 mb-0">
        <h4 class="font-weight-bold">Contacto</h4>
        <span class="d-block pl-2"
          ><img
            style="width: 20px"
            src="../assets/img/iconos-03.png"
            alt=""
          /><span class="font-weight-bold">Pavón 5068</span> [casi esq.
          Lamadrid]</span
        >
        <span class="d-block pl-2"
          ><img style="width: 20px" /><span class="font-weight-bold"
            >Cafferata 3025</span
          ><br
        /></span>
        <span class="d-block pl-2"
          ><img style="width: 20px" /><span class="font-weight-bold"
            >Av. Pellegrini 4820</span
          ><br
        /></span>
        <span class="d-block pl-2"
          ><img style="width: 20px" /><span class="font-weight-bold"
            >Santa Fe 5080</span
          ><br /><img style="width: 20px" />2000 Rosario - Santa Fe</span
        >
        <span class="d-block mb-2 pl-2 py-1">
          <img style="width: 20px" src="../assets/img/iconos-01.png" alt="" />
          info&#64;drautoservicio.com.ar
        </span>
        <div class="d-flex">
          <a
            href="https://www.instagram.com/drautoserviciomayorista/?hl=es"
            class="d-flex py-1 pl-2 align-items-center"
            id="texto-iconos"
            ><i class="fab fa-instagram pr-1" style="font-size: 20px"></i
          ></a>
          <a
            href="https://www.facebook.com/DRAmayorista/"
            class="d-flex py-1 pl-2 align-items-center"
            id="texto-iconos"
            ><i class="fab fa-facebook pr-1" style="font-size: 20px"></i
          ></a>
        </div>
      </div>

      <!--
            <div class="col-md-3 mb-3 mb-0">
                <h4 class="font-weight-bold">Envío a domicilio:</h4>
                <span class="d-block pl-2">Los envíos a domicilio los realizamos de lunes a viernes de 9 a 17:00</span>
                <span class="d-block pl-2">Vas a recibir tu pedido 24 hs después de que el mismo haya ingresado a nuestro sistema y podrás seleccionar entre 2 franjas horarias para recibirlo antes de terminar tu compra:</span>
                <span class="d-block pl-2">- Por la mañana: de 9 a 13.</span>
                <span class="d-block mb-2 pl-2 py-1">- Por la tarde: de 13 a 17.</span>
                <span class="d-block mb-2 pñ-2 py-1">Te pedimos que tengas en cuenta que si tu pedido fue realizado después de las 16:00 va a ser ingresado a primera hora del día siguiente. Por lo tanto, las 24hs comienzan a correr a partir de ese momento.</span>
  
                <div class="d-flex">
                    <a href="https://www.instagram.com/drautoserviciomayorista/?hl=es" class="d-flex py-1 pl-2 align-items-center" id="texto-iconos"><i class="fab fa-instagram pr-1" style="font-size: 20px; "></i></a>
                    <a href="https://www.facebook.com/DRAmayorista/" class="d-flex py-1 pl-2 align-items-center" id="texto-iconos"><i class="fab fa-facebook pr-1" style="font-size: 20px; "></i></a>
                </div>
            </div>
 -->
      <!--
            <div class="col-md-3 mb-3 mb-0">
                <h4 class="font-weight-bold">Nuestras sucursales:</h4>
                <span class="d-block pl-2">DR Autoservicio Mayorista cuenta con tres locales ubicados en la ciudad de Rosario. Si preferís la compra en el local, podés acercarte a cualquiera de nuestras dos direcciones: </span>
              <br>
                <span class="d-block pl-2">   
                    <img style="width: 20px;" src="../assets/img/iconos-03.png" alt="">
                    <span class="font-weight-bold">
                        Pavón 5068: De lunes a sábados de 9 a 17:30.
                    </span>
                </span>
                <span class="d-block pl-2">   
                    <img style="width: 20px;" src="../assets/img/iconos-03.png" alt="">
                    <span class="font-weight-bold">
                        Cafferata 3025: De lunes a sábados de 9 a 17:30.
                    </span>
                </span>
                <span class="d-block pl-2">   
                    <img style="width: 20px;" src="../assets/img/iconos-03.png" alt="">
                    <span class="font-weight-bold">
                        Av. Pellegrini 4820: De lunes a sábados de 9 a 19:00.
                    </span>
                </span>
            
               <span class="pl-2 py-1">Redes sociales</span> 
                <div class="d-flex">
                    <a href="https://www.instagram.com/drautoserviciomayorista/?hl=es" class="d-flex py-1 pl-2 align-items-center" id="texto-iconos"><i class="fab fa-instagram pr-1" style="font-size: 20px; "></i></a>
                    <a href="https://www.facebook.com/DRAmayorista/" class="d-flex py-1 pl-2 align-items-center" id="texto-iconos"><i class="fab fa-facebook pr-1" style="font-size: 20px; "></i></a>
                </div>
            </div>
        -->

      <!--
            <div class="col-md-3 mb-3 mb-0">
                <h4 class="font-weight-bold">Contacto</h4>
                <span class="d-block pl-2"><img style="width: 20px;" src="../assets/img/iconos-03.png" alt=""><span class="font-weight-bold">Pavón 5068</span> [casi esq. Lamadrid]</span>
                <span class="d-block pl-2"><img style="width: 20px;"><span class="font-weight-bold">Cafferata 3025</span><br></span>
                <span class="d-block pl-2"><img style="width: 20px;"><span class="font-weight-bold">Av. Pellegrini 4820</span><br><img style="width: 20px;">2000 Rosario - Santa Fe</span>
                <span class="d-block mb-2 pl-2 py-1"><img style="width: 20px;" src="../assets/img/iconos-01.png" alt="">info@drautoservicio.com.ar</span>
              
                <div class="d-flex">
                    <a href="https://www.instagram.com/drautoserviciomayorista/?hl=es" class="d-flex py-1 pl-2 align-items-center" id="texto-iconos"><i class="fab fa-instagram pr-1" style="font-size: 20px; "></i></a>
                    <a href="https://www.facebook.com/DRAmayorista/" class="d-flex py-1 pl-2 align-items-center" id="texto-iconos"><i class="fab fa-facebook pr-1" style="font-size: 20px; "></i></a>
                </div>
            </div>
        -->

      <div class="col-md-3 mb-3 mb-0">
        <h4 class="font-weight-bold">DR AUTOSERVICIO MAYORISTA</h4>
        <!-- <span class="d-block pl-2 seccion" routerLink="/quienes-somos">Quienes Somos</span>
            <span class="d-block pl-2 seccion" routerLink="/bases-condiciones">Bases y condiciones</span>
            <span class="d-block pl-2 seccion" routerLink="/informacion">Información</span> -->
        <span class="d-block pl-2 seccion" routerLink="/nosotros"
          >Nosotros</span
        >
        <span class="d-block pl-2 seccion" routerLink="/monto-minimo-compra"
          >Monto mínimo de compra</span
        >
        <span class="d-block pl-2 seccion" routerLink="/envio-domicilio"
          >Envío a domicilio</span
        >
        <span class="d-block pl-2 seccion" routerLink="/retiro-tienda"
          >Retiro en tienda</span
        >
        <span class="d-block pl-2 seccion" routerLink="/medios-de-pago"
          >Medios de pago</span
        >
        <span class="d-block pl-2 seccion" routerLink="/nuestras-sucursales"
          >Nuestras sucursales</span
        >
        <span class="d-block pl-2 seccion" routerLink="/terminos-y-condiciones"
          >Términos y condiciones</span
        >
        <span
          class="d-block pl-2 seccion"
          routerLink="/forma-parte-de-nuestro-equipo"
          >Formá parte de nuestro equipo</span
        >
        <span class="d-block pl-2 seccion" routerLink="/contacto"
          >Contacto</span
        >
      </div>

      <div class="col-md-3 mb-5 mb-0">
        <h4 class="font-weight-bold">Servicio al cliente</h4>
        <span class="d-block pl-2 seccion" routerLink="/faqs"
          >Preguntas frecuentes</span
        >
        <span class="d-block pl-2 seccion" routerLink="/promociones-bancarias"
          >Promociones bancarias</span
        >
        <span class="d-block my-1 pl-2 seccion" routerLink="/instrucciones-pwa"
          >Instalar PWA</span
        >

        <a
          href="http://qr.afip.gob.ar/?qr=UD_WFmJdrc_sr36O0Rcj2w,,"
          target="_F960AFIPInfo"
          ><img
            style="max-width: 6rem; max-height: 6rem"
            src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
            border="0"
        /></a>
      </div>

      <!--
        <span class="d-block pl-2 seccion" routerLink="/faqs">Preguntas frecuentes</span>
        <span class="d-block pl-2 seccion" routerLink="/promociones-bancarias">Promociones bancarias</span>
        <span class="d-block my-1 pl-2  seccion" routerLink="/instrucciones-pwa">Instalar PWA</span>

        <a  href="http://qr.afip.gob.ar/?qr=UD_WFmJdrc_sr36O0Rcj2w,," target="_F960AFIPInfo"><img style="max-width: 6rem; max-height: 6rem" src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0"></a>
-->
    </div>
  </div>
</footer>

<ng-template #modalInicioTemplate class="modalInicioTemplate">
  <div class="modal-body">
    <button id="modalInicioBTN" class="close" (click)="this.closeModal()">
      <span style="margin: 0.5rem"
        ><i style="color: white" class="fas fa-4x fa-times"></i
      ></span>
    </button>
    <img
      style="width: 100% !important; height: 100% !important"
      class="img-fluid"
      [src]="this.modalImgUrl"
    />
  </div>
</ng-template>
