<!-- <img src="../../assets/img/sitioConstruccion.jpg" style="width: 100%; height: 100%;" alt=""> -->
<div class="div">

</div>
<style>
    *, body {
        overflow-y: hidden;
    }
    .div {
        background-image: url("../../assets/img/sitioConstruccion.jpg");
        background-size: cover;
        background-position: center;
        width: 100vw;
        height: 100%;
        position: fixed;
        z-index: 20000000000000000000000000000000000;
        background-attachment: fixed;
        top: 0;
    }
</style>