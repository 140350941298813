import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { of } from 'rxjs';
import { addPedido, registro } from '../interface/modelos';
import { CrearHashService } from './crear-hash.service';
@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  
  constructor(private http: HttpClient, private hashSvc: CrearHashService) { }
  
 
  getAllByCat(catid, orden = '0', offset, limit, str_marcas) {
    let headers = new HttpHeaders();
    let hash = this.hashSvc.crearHash('dr-prodbycat-');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Cache-Control', 'no-cache');
    headers.append('authorization', hash);
    
    let params = new HttpParams();
    params = params.append('hash', hash);
    params = params.append('catid', catid);
    params = params.append('limit', limit);
    params = params.append('offset', offset);
    params = params.append('orden', orden);
    params = params.append('str_marcas', str_marcas);
    //str_marcas
    const options = { params: params, headers: headers };
    return this.http.post<Response>(`${environment.API_URL2}`+ "/getproductosbycatid", "" , options);
  }

  getAllBySubCat(
   subcatid,
   orden = '0',
   offset,
   limit,
   str_marcas
   ) {
    let headers = new HttpHeaders();
    let hash = this.hashSvc.crearHash('dr-prodbysubcat-');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Cache-Control', 'no-cache');
    headers.append('authorization', hash);
    
    let params = new HttpParams();
    params = params.append('hash', hash);
    params = params.append('subcatid', subcatid);
    params = params.append('limit', limit);
    params = params.append('offset', offset);
    params = params.append('orden', orden);
    params = params.append('str_marcas', str_marcas);
    const options = { params: params, headers: headers };

    return this.http.post<Response>(`${environment.API_URL2}`+ "/getproductosbysubcatid", "" , options);
  }

  getDestacados() {
    let headers = new HttpHeaders();
    let hash = this.hashSvc.crearHash('dr-destacados-');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Cache-Control', 'no-cache');
    headers.append('authorization', hash);
    
    let params = new HttpParams();
    params = params.append('hash', hash);
    params = params.append('limit', '36');
    const options = { params: params, headers: headers };
    return this.http.post<Response>(`${environment.API_URL2}` + "/getproductosdestacados", "" , options);
  }
  
  getProductos(term, orden, offset, limit,str_marcas){ 
    let headers = new HttpHeaders();
    let hash = this.hashSvc.crearHash('dr-productos-');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Cache-Control', 'no-cache');
    headers.append('authorization', hash);
    
    let params = new HttpParams();
    params = params.append('hash', hash);
    params = params.append('buscar', term);
    params = params.append('limit', limit);
    params = params.append('offset', offset);
    params = params.append('orden', orden);
    params = params.append('str_marcas', str_marcas);

    const options = { params: params, headers: headers };
    return this.http.post<Response>(`${environment.API_URL2}`+ "/getproductos", "" , options);
  }
  mpWs(term, term2, term3, term4){ 
    const entidad = new HttpParams()
			.set('monto', term)
			.set('referencia', term2)
			.set('titulo', term3)
			.set('description', term4);
		return this.http.post('https://drautoservicio.com.ar/mp/mp-ws.php', entidad.toString(), { 
			headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') 
		});
  }
  addPedido(pedido){
    const entidad = pedido;
    // console.log("CONSOLE.LOG PEDIDO POR SEBA: parametro addpedido ", entidad);
		return this.http.post(`${environment.API_URL2}`+ '/addpedido', entidad, { 
			headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') 
		});

  }
  registro(usuario) {
    const entidad:registro = {
      correo: usuario.correo,
      nombre: usuario.nombre + ' ' + usuario.apellido,
      telefono: usuario.telefono,
      direccion: usuario.direccion,
      direccion_entrega: usuario.dirEntrega,
      condicion_iva_id: usuario.condIva,
      tipo_doc_id: usuario.tipoDoc,
      nro_doc:usuario.numDoc,
      localidad_id:usuario.ciudad,
      provincia_id: 1
    };
		return this.http.post(`${environment.API_URL2}`+ '/registrar', entidad, { 
			headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') 
		});

  }
  addPago(term, term2){
    const entidad = new HttpParams()
			.set('PedidoId', term)
			.set('Extras', term2);
		return this.http.post(`${environment.API_URL2}`+ '/addpago', entidad.toString(), { 
			headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') 
		});

  }

  getProducto(idProd){ 
    let headers = new HttpHeaders();
    let hash = this.hashSvc.crearHash('dr-prodbyid-');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Cache-Control', 'no-cache');
    headers.append('authorization', hash);
    
    let params = new HttpParams();
    params = params.append('hash', hash);
    params = params.append('prdid', idProd);
    const options = { params: params, headers: headers };
    return this.http.post<Response>(`${environment.API_URL2}`+ "/getproductosbyid", "" , options);
  }

  getMasVendidos() {
    let headers = new HttpHeaders();
    let hash = this.hashSvc.crearHash('dr-masvendidos-');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Cache-Control', 'no-cache');
    headers.append('Authorization', hash);
    
    let params = new HttpParams();
    params = params.append('hash', hash);
    params = params.append('limit', '100');
    const options = { params: params, headers: headers };
    return this.http.post<Response>(`${environment.API_URL2}` + "/getproductosmasvendidos", "" , options);
  }
  getofertasemanal() {
    let headers = new HttpHeaders();
    let hash = this.hashSvc.crearHash('dr-oferta-semanal-');
    // let hash = this.hashSvc.crearHash('dr-oferta-semanal-', 'yy-mm-d');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Cache-Control', 'no-cache');
    headers.append('Authorization', hash);
    
    let params = new HttpParams();
    params = params.append('hash', hash);
    params = params.append('limit', '0');
    const options = { params: params, headers: headers };
    return this.http.post<Response>(`${environment.API_URL2}` + "/getofertasemanal", "" , options);
  }

  getMarcasCategorias(
    idProd:string
  ) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Cache-Control', 'no-cache');
    let params = new HttpParams();
    params = params.append('catid', idProd);
    const options = { params: params, headers: headers };
    return this.http.post<Response>(`${environment.API_URL2}` + "/getmarcasxcatid", "" , options);
  }

  getMarcasSubCategorias(
    subcatid:string
  ) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Cache-Control', 'no-cache');
    let params = new HttpParams();
    params = params.append('subcatid', subcatid);
    const options = { params: params, headers: headers };
    return this.http.post<Response>(`${environment.API_URL2}` + "/getmarcasxsubcatid", "" , options);
  }
  getMarcasBusqueda(
    name:string
  ) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Cache-Control', 'no-cache');
    let params = new HttpParams();
    params = params.append('name', name);
    const options = { params: params, headers: headers };
    return this.http.post<Response>(`${environment.API_URL2}` + "/getmarcasxprdname", "" , options);
  }


 






}
/*
const PRODUCTOS = 
{
  "data": [
      {   "prdProductoId":"10114",
         "0":"10114",
         "prdNombre":"GALL SALADIX PIZZA PAQ X 30GS",
         "1":"GALL SALADIX PIZZA PAQ X 30GS",
         "prdCodigo":"CO",
         "2":"CO",
         "prdNroCodigo":"02254",
         "3":"02254",
         "CODIGO":"CO02254",
         "4":"CO02254",
         "PrecioSinIVA":"19.39",
         "5":"19.39",
         "prcCantidad":"1",
         "6":"1",
         "prcDto":"0.00",
         "7":"0.00",
         "impAlicuota":"21.00",
         "8":"21.00",
         "PrecioConIVA":"23.46",
         "9":"23.46"
      },
      {
         "prdProductoId":"10115",
         "0":"10115",
         "prdNombre":"LENTEJA FRUTAL BANDEJA",
         "1":"LENTEJA FRUTAL BANDEJA",
         "prdCodigo":"PF",
         "2":"PF",
         "prdNroCodigo":"00227",
         "3":"00227",
         "CODIGO":"PF00227",
         "4":"PF00227",
         "PrecioSinIVA":"41.32",
         "5":"41.32",
         "prcCantidad":"1",
         "6":"1",
         "prcDto":"0.00",
         "7":"0.00",
         "impAlicuota":"21.00",
         "8":"21.00",
         "PrecioConIVA":"50.00",
         "9":"50.00"
      },
      {
        "prdProductoId":"10112",
        "0":"10115",
        "prdNombre":"LENTEJA2 FRUTAL BANDEJA",
        "1":"LENTEJA FRUTAL BANDEJA",
        "prdCodigo":"PF",
        "2":"PF",
        "prdNroCodigo":"00227",
        "3":"00227",
        "CODIGO":"PF00227",
        "4":"PF00227",
        "PrecioSinIVA":"41.32",
        "5":"41.32",
        "prcCantidad":"1",
        "6":"1",
        "prcDto":"0.00",
        "7":"0.00",
        "impAlicuota":"21.00",
        "8":"21.00",
        "PrecioConIVA":"50.00",
        "9":"50.00"
     },
     {
      "prdProductoId":"101121",
      "0":"10115",
      "prdNombre":"LENTEJA3 FRUTAL BANDEJA",
      "1":"LENTEJA FRUTAL BANDEJA",
      "prdCodigo":"PF",
      "2":"PF",
      "prdNroCodigo":"00227",
      "3":"00227",
      "CODIGO":"PF00227",
      "4":"PF00227",
      "PrecioSinIVA":"41.32",
      "5":"41.32",
      "prcCantidad":"1",
      "6":"1",
      "prcDto":"0.00",
      "7":"0.00",
      "impAlicuota":"21.00",
      "8":"21.00",
      "PrecioConIVA":"50.00",
      "9":"50.00"
   }
  ]
}
*/