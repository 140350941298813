import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  url = environment.API_URL2 + "/auth";
  urlreset = environment.API_URL2 + "/resetpassword";
  public isLogged: any = false;
  userToken: string;
  userArray: string;
  //ACA
  private http: HttpClient;




  constructor(handler: HttpBackend) { 
    this.http = new HttpClient(handler);
    if (this.leerToken() === '') {
      this.isLogged = false;
    } else {
      this.isLogged = true;
    }
  }
    // LOGIN
    login(user) {
      const raw = '0={\"usr\":\"'+ user.email +'\",\"pass\":\"'+ user.password +'\"}';
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        })
      };
      return this.http.post < any > (this.url, raw, httpOptions)
        .pipe(
          map(resp => {
            if(resp['dataUser']['accessToken']){
              this.guardarToken(resp['dataUser']['accessToken']);
            }
            this.guardarArray(resp);
            return resp;
          })
        );
    }
    resetPass(user) {
      const raw = '{\"mail\":\"'+ user.email +'\",\"currentpass\":\"'+ user.password +'\",\"newpass\":\"'+ user.newPassword +'\"}';

      let token = this.getToken();
      const headers = new HttpHeaders({
        'authorization': `${token}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      });
      return this.http.post < any > (this.urlreset, raw, {headers})
        .pipe(
          map(resp => {
            if(resp['dataUser']['accessToken']){
              this.guardarToken(resp['dataUser']['accessToken']);
            }
            this.guardarArray(resp);
            return resp;
          })
        );
    }
  showAPi(){
    return this.url;
  }
  leerToken() {
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }
  private guardarToken(idToken: string) {
    this.userToken = idToken;
    localStorage.setItem('token', idToken);
  }
  private guardarArray(array: string) {
    this.userArray = array;
    localStorage.setItem('array', JSON.stringify(array));
  }
  estaAutenticado(): boolean {
    if(this.userToken) {
      return this.userToken.length > 2;
    }
    else {
      return false;
    }
  }
  logOut(){
    localStorage.removeItem('token');
    window.location.reload();
  }
  getToken() {
    return localStorage.getItem('token')
  }
}
