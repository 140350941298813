import { Component, OnInit, Input, ViewChild, ElementRef, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { CarritoSharedService } from '../services/carrito-shared.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EnviarPedidoService } from '../services/enviar-pedido.service';
import { Router } from '@angular/router';
import { DatePipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ProductosService } from '../services/productos.service';
import { forkJoin, of } from 'rxjs';
import { HttpHandler } from '@angular/common/http';
import * as $ from "jquery";
import { get } from 'scriptjs';
import { addPedidoBeta, RENG, addPedido } from '../interface/modelos';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as jwt_decode from "jwt-decode";
import { LoginService } from '../services/login.service';
// import {jsPDF} from 'jspdf';
import jspdf from 'jspdf';
import * as html2canvas from 'html2canvas';
import { CustomCurrencyPipe } from '../pipe/custom-currency.pipe';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/es-AR';
import { Columns, PdfMakeWrapper, Table } from 'pdfmake-wrapper';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
registerLocaleData(localeFr, 'es-AR');




@Component({
  selector: 'app-detalle-carrito',
  templateUrl: './detalle-carrito.component.html',
  styleUrls: ['./detalle-carrito.component.scss'],

})
export class DetalleCarritoComponent implements OnInit {
  items: any;
  prefInit: any;
  referencia: any;
  math = Math;
  enviarpedido:boolean = false;
  preference: any;
  modalRef: BsModalRef;
  pedido: addPedido; 
  mercadoPago: boolean;
  forma: FormGroup;
  tipoentregaid: number;
  formadepagoId: number;
  myDate: Date = new Date();
  Date: string;
  isConsmudorFinal: any;
  subscription: any;
  disableBtn: boolean = false;
  costoEnvioVal: any = 0;
  compraMinima: number;
horarioAcordarCon = "Acordar con el operador"
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;


  constructor(
    private cartService: CarritoSharedService,
    private modalService : BsModalService,
    private enviarPedidoService : EnviarPedidoService,
    private router: Router,
    private CurrencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    private toastr: ToastrService,
    public cd: ChangeDetectorRef,
    private prodSvc: ProductosService,
    private fb: FormBuilder,
    private loginSvc: LoginService
    ) { 
      this.items = this.cartService.getItems();
      
 
    }

  ngOnInit() {
  
    this.onCompraMinimaDeCompra();
    this.crearFormulario();
   
    this.scrollTop();
    this.mpWs();
    this.subscription = this.forma.get('condIva').valueChanges.subscribe(checked => {
      if(checked){

        if(checked == 3){
          this.isConsmudorFinal = true;
          this.forma.get('cuit').disable();
          this.forma.get('dni').enable();
        }
        else {
          this.isConsmudorFinal = false;
          this.forma.get('dni').disable();
          this.forma.get('cuit').enable();
        }
      }
    });
    if(this.items.length !== 0) {
      this.items.map(m => {
        this.onProductoConDescuentoX1(m);
      });
    }
  }

  onProductoConDescuentoX1(producto) {
    console.log('on producto con descuentoX1')
    if(producto.precios.length !== 0) {
      for(var _i = 0; _i < producto.precios.length; _i++) {
      
        if(producto.precios[_i].prcCantidad == 1) {
            
           producto.precio_anterior = true;
            console.log(producto.precios[_i]);  
          }    
      }
    }
  }
  


  ngOnDestroy(): void {
    this.subscription.unsubscribe();    
  }
  afterViewInit() {
  
  }
  ngOnChanges(): void {
    this.items = this.cartService.getItems();
  
    this.mpWs();
  }
  //Funciones del carrito

  total(): number {
    let items = this.cartService.getItems();
    for(let producto of items) {
      let qty = parseFloat(parseFloat(producto.qty).toFixed(2));
      if(qty <=0 ) {
        this.quitarProd(producto['prdProductoId']);
        this.cd.detectChanges();
      }
      if(producto['EsDeBalanza'] === '1') {
          producto.qty = Number.parseFloat(producto['qty']).toFixed(1);
      }
      if(producto['EsDeBalanza'] !== '1') {
          producto.qty = producto['qty'];
      }
  
    }

 
   

    
   return this.cartService.totalCal();
    /*
      return items.reduce(function (acc, item) {
        console.log(item);
        if (item.precios.length > 0 && item.qty > 2) {
          for (let element of item.precios) {
            //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
            if (item.qty >= element.prcCantidad) {   
              prcDto = element.prcDto;
              prcCantidad = element.prcCantidad;
              //en caso que sea la ultima promocion calcula el valor
              if (i >= (item.precios.length - 1)) {
                //console.log("1", item.Nombre);
                return acc + (item.qty * (item.PrecioConIVA * (1 - prcDto / 100)));
              }
              i++;
            } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
              //console.log("2", item.Nombre);
       
              return acc + (item.qty * (item.PrecioConIVA * (1 - prcDto / 100)));
            }
          }
        }
        else if (item.oferta_semanal && item.oferta_semanal.length > 0 && item.qty > 0) {
        for (let element of item.oferta_semanal) {
          //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
          if (item.qty >= element.Cant) {    
            //en caso que sea la ultima promocion calcula el valor
            if (i >= (item.oferta_semanal.length - 1)) {
            //console.log("3", item.Nombre);
      
              return acc + (item.qty * element.Precio);
            }
            i++;
          } else { 
            //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
            //console.log("4", item.Nombre);
    
      
          
            return acc + (item.qty * item.PrecioConIVA);
          }
        }

      }
      else if (item.PrecioDestacado && item.PrecioDestacado != 0.00) {
        //console.log("5", item.Nombre);
    
   
        return acc + (item.PrecioDestacado * item.qty);

      } 
      else {
        //console.log("6", item.Nombre);
    
        return acc + (item.PrecioConIVA * item.qty);
      }
      }, 0);
      */
    
  }
  clearCart() {
    this.cartService.clearCart();
    this.router.navigateByUrl('/inicio');
  }
  minusQty(id, qty, selector, item) {
    
    var $id = selector;
    var input = < HTMLInputElement > document.getElementById($id);
    var num = parseFloat(( < HTMLInputElement > document.getElementById($id)).value);
    if (num > 0) { //Para que no haya pedidos negativo      
      this.cartService.substractQtyToItem(id, qty);
      input.stepDown();
      //this.promoToast(id);
      var num = parseFloat((<HTMLInputElement>document.getElementById($id)).value);
    }

  }

 
  plusQty(id, qty, selector, item) {
 
    var $id = selector;
    var input = < HTMLInputElement > document.getElementById($id);

    this.cartService.addQtyToItem(id, qty);
    input.stepUp();
  //  this.promoToast(id);
  }
  mostrarRadios(boolean) {
    this.enviarpedido = boolean;
    if (boolean) {
      this.tipoentregaid = 2;
      let total = this.total();
      this.cd.detectChanges();
      total = this.determinarPrecioEnvio(total);
      // costo de envio selecionando 
      this.forma.controls['costoEnvio'].setValue(0);
     // Obtener Costos de Envio
     
      this.forma.get('hsEntrega').enable();
      this.forma.controls['hsEntrega'].setValidators([Validators.required]);
    }
    else {
      this.tipoentregaid = 1;
      this.forma.controls['costoEnvio'].setValue(0);
      this.forma.controls['hsEntrega'].setValue("");
      this.forma.get('hsEntrega').disable();
    }
  }
  quitarProd(id) {
    this.cartService.removeItem(id);
    this.items = this.cartService.getItems();
  }
  getQty(item){
    return item.qty;
  }

  /*Qty INPUT */
  Qty(item) {
   let producto = this.cartService.findItemByIdInCart(item.prdProductoId);
   let qty:any = producto['qty'];
/*
   if(qty <=0 ) {
    this.quitarProd(producto['prdProductoId']);
    this.cd.detectChanges();
  }

   /*
   if(qty == 1.3877787807814457e-16) {
    this.quitarProd(producto['prdProductoId']);
    this.cd.detectChanges();
  }
  */
    if(producto['EsDeBalanza'] === '1') {
      qty = Number.parseFloat(producto['qty']).toFixed(1);
     }
     if(producto['EsDeBalanza'] !== '1') {
      qty = producto['qty'];
     }
   
   return qty;
  }

  promoToast(id) {
    let item: any = this.cartService.findItemByIdInCart(id);
    let prcCantidad = 0;
    let uFaltantes = 0;
    let i = 0;
    let quedaPromo = false;
    let string: string;
    if (item.precios.length > 0 || item.TieneOfertaSemanal == 1) {
      for (let element of item.precios) {
        //asegurarnos  que tome la primer promo
        if (1 == (item.precios.length)) {
          prcCantidad = element.prcCantidad;
        }
        //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
        if (item.qty >= element.prcCantidad) {

        } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra
          quedaPromo = true;
          prcCantidad = element.prcCantidad;
          break;

        }
      }
      uFaltantes = prcCantidad - item.qty;
    }
    else if(item.TieneOfertaSemanal == 1) {
      for (let element of item.oferta_semanal) {
        //asegurarnos  que tome la primer promo
        if (1 == (item.oferta_semanal.length)) {
          prcCantidad = element.Cant;
        }
        //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
        if (item.qty >= element.Cant) {

        } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra
          quedaPromo = true;
          prcCantidad = element.Cant;
          break;

        }
      }
      uFaltantes = prcCantidad - item.qty;
    }
    else {
      quedaPromo = false;
    }
    if (quedaPromo) {
      string = "faltan " + uFaltantes + " unidades para obtener descuento x" + prcCantidad;
      this.toastr.success(string);
      string = '';
    }
  }
  totalByItemV2(itemId):number {
   return this.cartService.totalPriceByItem(itemId);
  }

  totalByItem(itemId) {
    let item: any = this.cartService.findItemByIdInCart(itemId);
    
    let prcDto = 0;
    let prcCantidad = 0;
    let uFaltantes = 0;
    let i = 0;
    if (item.precios) {
      if (item.precios.length > 0 && item.qty > 0) {
        for (let element of item.precios) {
          //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
          if (item.qty >= element.prcCantidad) {

            prcDto = element.prcDto;
            prcCantidad = element.prcCantidad;
            //en caso que sea la ultima promocion calcula el valor
            if (i == (item.precios.length - 1)) {
              return this.CurrencyPipe.transform(item.qty * (item.PrecioConIVA * (1 - element.prcDto / 100)));
            }
            i++;
          } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
            return this.CurrencyPipe.transform(item.qty * (item.PrecioConIVA * (1 - prcDto / 100)));
          }
        }
        uFaltantes = prcCantidad - item.qty;
        // });
      } 
      else if (item.oferta_semanal && item.oferta_semanal.length > 0 && item.qty > 0) {
        for (let element of item.oferta_semanal) {
          //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
          if (item.qty >= element.Cant) {    
            //en caso que sea la ultima promocion calcula el valor
            if (i >= (item.oferta_semanal.length - 1)) {
              return this.CurrencyPipe.transform(item.qty * element.Precio);
            }
            i++;
          } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
            //return this.CurrencyPipe.transform(item.qty * element.Precio);
            return this.CurrencyPipe.transform(item.qty * item.PrecioConIVA);
          }
        }

      }
      else if ( item.PrecioDestacado && item.PrecioDestacado != 0.00) {
        return this.CurrencyPipe.transform(item.PrecioDestacado * item.qty);

      }
      else {
        return this.CurrencyPipe.transform(item.qty * item.PrecioConIVA); //precio regular si no tiene array de precios
      }
    }
    
  }
  promo(qty, prc, dto){
    return (qty * (prc * (1 - dto / 100)));
  }
  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }
  //Circuito de pago

onCompraMinimaDeCompra() {
  this.enviarPedidoService.getCostoMinimoDeCompra().subscribe(res => {
    console.log(res['data']);
    this.compraMinima = res['data'];
  });
}
  //Abro modal
  openModal(template: TemplateRef < any > ) {
    
    // Limite De Precio Compra Total
    let errCostMinMsg = 'El monto no es mayor a ' + this.compraMinima;
    if(this.total() < this.compraMinima) {
      this.toastr.error(errCostMinMsg);
      return;
    }
  
    //actualizo items del carrito 
    this.items = this.cartService.getItems();
    //Abre el modal
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-fix'
    });
    //Carga datos jwt
    let token = this.loginSvc.leerToken();
    if(token !== ""){
      let jwt = this.getDecodedAccessToken(token);
      this.cargarDataAlFormulario(jwt);
    }
    else {
      // sino esta loggeado solo carga valor del total
      this.cargarTotalFormulario();
    }
  }

  confirmarPedido(contraEntrega: TemplateRef < any >, mp: TemplateRef < any >){
    //Marca todos los campos como touched
    this.guardar();
  
    //Ejecuta si no todos los campos son validos
    if(!this.forma.invalid ) {
      // obtiene id pedido
      this.disableBtn = true;

      this.addPedido()
      .subscribe(resp => {
        this.referencia = resp['data'].pedidoid;
        
        //una vez agregado el pedido cheuquea forma de pago
        if(this.mercadoPago === true) {
          // consigue link de mp
          this.disableBtn = false;
          setTimeout(() => {
            this.modalRef.hide();
            //espera un segundo y muestra codigo de seguimiento 
            this.modalRef = this.modalService.show(mp, {
              class: 'modal-lg'
            })       
          }, 1000);
          }
          else {
            //si es por contra entrega oculta modal actual
            this.modalRef.hide();
            setTimeout(() => {
            //espera un segundo y muestra codigo de seguimiento 
            this.disableBtn = false;
            this.modalRef = this.modalService.show(contraEntrega, {
              class: 'modal-lg'
            })       
          }, 1000);
          this.clearCart();
        }
      },
      error => {
        //console.log(error);
      }); 
       
    }
  }
  //Metedo para obtener mp link
  mpLink() {
    this.mpWs()
    .subscribe(resp => {
      this.prefInit = resp;     
      this.clearCart();
      window.open(this.prefInit.preference_init_point, "_blank");
      this.modalRef.hide();
      
    },
    error =>{
      this.disableBtn = false;
      //console.log("error"+error);
    });
  }
  //si no inicio sesion
  cargarTotalFormulario() {
    let total = this.total();
    this.cd.detectChanges();
    this.costoEnvioVal = 0;
    this.getCostosDeEnvio();
    this.forma.reset({ 
      totalConIva: this.dosDecimales(total),
      totalSinIva: this.quitarIva(total),
      costoEnvio:  this.costoEnvioVal, // this.determinarPrecioEnvio(total),
      hsEntrega: "",
      aclaraciones: "",
    });

  }
  //si inicio sesion
  cargarDataAlFormulario(jwt){
    let total = this.total();
    this.getCostosDeEnvio();
    this.forma.reset({ 
      condIva: jwt.civCondIvaId,
      nombre: jwt.cliNombre,
      dni: "",
      email: jwt.cliUsuWeb,
      ciudadID: jwt.cddCiudadId,
      direccion: jwt.cliDireccion,
      tel: jwt.cliTelefono,
      hsEntrega: "",
      aclaraciones: "",
      costoEnvio: this.costoEnvioVal, //this.determinarPrecioEnvio(total),
      totalConIva: this.dosDecimales(total),
      totalSinIva: this.quitarIva(total),
  });
  }

  addPedido() {
      let reng = this.getReng();
      this.pedido = {
        condicionIVA: this.forma.value.condIva,
        nombrecliente: this.forma.value.nombre,
        nrodoc: this.forma.value.dni ? this.forma.value.dni : this.forma.value.cuit,
        email: this.forma.value.email,
        ciudadid: this.forma.value.ciudadID,
        direccion: this.forma.value.direccion,
        telefono: this.forma.value.tel,
        totalconiva: this.dosDecimales(this.forma.value.totalConIva),
        totalsiniva: this.forma.value.totalSinIva,
        costoenvio: this.forma.value.costoEnvio,
        nota: this.forma.value.aclaraciones,
        horarioentrega: this.forma.value.hsEntrega ? this.forma.value.hsEntrega : "",
        criterioId: 0,//this.forma.value.criterioId,
        tipoentregaId: this.tipoentregaid,
        formadepagoId: this.forma.value.formaPago,
        RENG: reng,
      };
    //  return of(this.pedido);
      return this.prodSvc.addPedido(this.pedido);
      //  return this.prodSvc.addPedido(this.pedido);
  }

    mpWs() {
    // let monto = this.total();
    let monto = '1';
    let referencia = this.referencia;
    return this.prodSvc.mpWs(monto, referencia, 'carrito 1', 'asdasda');
  }

  onCostoDeEnvioChange(e) {
    console.log(e);
    this.forma.controls['costoEnvio'].setValue(this.costoEnvioVal);
  }

getCostosDeEnvio() {
  this.enviarPedidoService.getCostosDeEnvio().subscribe(res => {
    console.log(res['data']);
    this.forma.controls['costoEnvio'].setValue(this.costoEnvioVal);;
    this.costoEnvioVal = res['data'];
    // this.forma.controls['costoEnvio'].patchValue()
  });
}

  enviarPedido() {
    this.enviarPedidoService.enviar().subscribe();

    //limpiar carrito
    this.cartService.clearCart();
    alert('enviando pedido!');

  }
  getReng(){
    //formateo de items en el carito
    let RENG: RENG = [];
    let items = this.cartService.getItems();
    let j = 0;
    //recorro el arreglo y agrego las propiedades

    for (let i of items) {
      RENG[j] = {
        ProductoID: Number(items[j].prdProductoId),
        Cantidad: items[j].qty,
        PrecioU: this.quitarIva(items[j].PrecioConIVA),
        PUCImp: this.getTotal(items[j].prdProductoId),
        Dto: this.getDto(items[j].prdProductoId),
        prcDto: items[j].prcDto,
        // PrecioU con descuento aplicado
        PUCDto: this.getPUCDto(items[j].prdProductoId),
        SubTotal: this.getSubTotal(items[j].prdProductoId, items[j].qty),
        TOTAL: this.getTotal(items[j].prdProductoId),
        OfertaId: Number(items[j].prdNroCodigo),
      };
      j++;
    }
    return RENG;
  }
  getPrefInit(){
    return this.prefInit.preference_init_point;
  }
  bifurcarPago(valor){
    this.mercadoPago = valor; 
  }
  crearFormulario(){
    this.forma = this.fb.group({
      //primer valor por defecto, segundo valor validados sincronos tercer valor asincronos
      condIva: ['', [Validators.required, Validators.pattern('[1-5]') ] ],
      nombre: ['', [Validators.required, Validators.minLength(5) ] ],
      dni: ['', [Validators.required, Validators.pattern('[0-9][0-9][0-9][0-9][0-9][0-9][0-9]?[0-9]') ] ],
      cuit: ['', [Validators.required, Validators.pattern('[0-9][0-9][0-9][0-9][0-9][0-9][0-9]?[0-9][0-9][0-9][0-9]') ] ],
      email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$') ] ],
      ciudadID: ['', [Validators.required ] ],
      direccion: ['', [Validators.required] ],
      tel: ['', [Validators.required] ],
      totalConIva: ['' ],
      totalSinIva: ['' ],
      costoEnvio: [''],
      aclaraciones: ['' ],
      hsEntrega: ['', [Validators.required]],
      criterioId: 0,//['', [Validators.required, Validators.pattern('[1-2]')] ],
      formaPago: ['', [Validators.required] ],
      formaEnvio: ['', [Validators.required]],
    });
    
  }
  guardar(){
    if ( this.forma.invalid ) {
      return Object.values( this.forma.controls ).forEach( control => {
        // validacion para campos anidados
        if ( control instanceof FormGroup) {
          Object.values( control.controls ).forEach( control => control.markAllAsTouched());
        } else {
          control.markAllAsTouched();
        }
      });
    }


  }
  //decodifica el jwt
  getDecodedAccessToken(token: string): any {
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }
  getSubTotal(id, qty){
    let subTot = this.getPUCDto(id);
    subTot = subTot * qty;
    subTot = Math.round((subTot + Number.EPSILON) * 100) / 100;
    return subTot;

  }
  dosDecimales(value){
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }
  determinarPrecioEnvio(total) {
    let caso = 0;
    if(4000 <= total) {
      caso = 1;
    }
    if (6000 <= total) {
      caso = 2;

    }
    if (10000 <= total) {
      caso = 3;
    }
    switch(caso) {
      case (1):
        return 250.00;
      case (2):
        return 200.00;
      case (3):
        return 0.00;
      default:
        return +250.00;
    }
  }
  quitarIva(total){
     let totalSinIva= total / 1.21;
     totalSinIva = Math.round((totalSinIva + Number.EPSILON) * 100) / 100;
     return totalSinIva;
  }

  getPUCDto(itemId) {
    let item: any = this.cartService.findItemByIdInCart(itemId);
    let prcDto = 0;
    let prcCantidad = 0;
    let uFaltantes = 0;
    let i = 0;
    if (item.precios){
      if (item.precios.length > 0 && item.qty > 2) {
        for (let element of item.precios) {
          //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
          if (item.qty >= element.prcCantidad) {

            prcDto = element.prcDto;
            prcCantidad = element.prcCantidad;
            //en caso que sea la ultima promocion calcula el valor
            if (i == (item.precios.length - 1)) {
              return 1 * ( this.quitarIva(item.PrecioConIVA) * (1 - element.prcDto / 100));
            }
            i++;
          } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
            return 1 * ( this.quitarIva(item.PrecioConIVA) * (1 - prcDto / 100));
          }
        }
        uFaltantes = prcCantidad - item.qty;
        // });
      } 
      else if ( item.oferta_semanal && item.oferta_semanal.length > 0 && item.qty > 0) {
      for (let element of item.oferta_semanal) {
        //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
        if (item.qty >= element.Cant) {    
          //en caso que sea la ultima promocion calcula el valor
          if (i >= (item.oferta_semanal.length - 1)) {
          return this.quitarIva(element.Precio);
          }
          i++;
        } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
          return this.quitarIva(element.Precio);
        }
      }

    }
    else if ( item.PrecioDestacado && item.PrecioDestacado != 0.00) {
      return this.quitarIva(item.PrecioDestacado);

    }
      else {
        return 1 * item.PrecioSinIVA; //precio regular si no tiene array de precios
      }
    }
  }
  /*
  getTotal(itemId){
      let item: any = this.cartService.findItemByIdInCart(itemId);
    
      let prcDto = 0;
      let prcCantidad = 0;
      let uFaltantes = 0;
      let i = 0;
      if (item.precios){
        if (item.precios.length > 0 && item.qty > 2) {
          for (let element of item.precios) {
            //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
            if (item.qty >= element.prcCantidad) {
    
              prcDto = element.prcDto;
              prcCantidad = element.prcCantidad;
              //en caso que sea la ultima promocion calcula el valor
              if (i == (item.precios.length - 1)) {
                return this.dosDecimales (item.qty * (item.PrecioConIVA * (1 - element.prcDto / 100)));
              }
              i++;
            } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
              return this.dosDecimales (item.qty * (item.PrecioConIVA * (1 - prcDto / 100)));
            }
          }
          uFaltantes = prcCantidad - item.qty;
          // });
        }
        else if ( item.oferta_semanal && item.oferta_semanal.length > 0 && item.qty > 0) {
          for (let element of item.oferta_semanal) {
            //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
            if (item.qty >= element.Cant) {    
              //en caso que sea la ultima promocion calcula el valor
              if (i >= (item.oferta_semanal.length - 1)) {
              return this.dosDecimales(item.qty * element.Precio);
              }
              i++;
            } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
              return this.dosDecimales(item.qty * element.Precio);
            }
          }

        }
        else if (item.PrecioDestacado && item.PrecioDestacado != 0.00) {
          return this.dosDecimales(item.PrecioDestacado * item.qty);

        }
        else {
          return this.dosDecimales (item.qty * item.PrecioConIVA); //precio regular si no tiene array de precios
        }
      }
  }
  */
  getDto(itemId) {
    let item: any = this.cartService.findItemByIdInCart(itemId);
    let prcDto = 0;
    let prcCantidad = 0;
    let uFaltantes = 0;
    let i = 0;
    if (item.precios){
      if (item.precios.length > 0 && item.qty > 2) {
        for (let element of item.precios) {
          //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
          if (item.qty >= element.prcCantidad) {

            prcDto = element.prcDto;
            prcCantidad = element.prcCantidad;
            //en caso que sea la ultima promocion calcula el valor
            if (i == (item.precios.length - 1)) {
              return Number(element.prcDto);
            }
            i++;
          } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
            return Number(prcDto);
          }
        }
        uFaltantes = prcCantidad - item.qty;
        // });
      } else {
        return 0; //precio regular si no tiene array de precios
      }
    }
  }

  getTotal(itemId) {
    //this.cartService.findItemByIdInCart(itemId);
    let item: any = this.cartService.findItemByIdInCart(itemId);

    //let item: any = this.findItemByIdInCart(itemId);
    let prcDto = 0;
    let prcCantidad = 0;
    let uFaltantes = 0;
    let i = 0;
    if (item.precios) {
      //if (item.precios.length > 0 && item.qty > 2) {
      if (item.precios.length > 0 && item.qty > 0) {
        for (let element of item.precios) {
     
          //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
          if (item.qty >= element.prcCantidad) {

            prcDto = element.prcDto;
            prcCantidad = element.prcCantidad;
            //en caso que sea la ultima promocion calcula el valor
            if (i == (item.precios.length - 1)) {
              let  nFixed = item.qty * (item.PrecioConIVA * (1 - element.prcDto / 100));
             
              return Number.parseFloat(nFixed.toFixed(2));
              //return this.CurrencyPipe.transform(item.qty * (item.PrecioConIVA * (1 - element.prcDto / 100)));
            }
            i++;
          } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
            let  nFixed = item.qty * (item.PrecioConIVA * (1 - prcDto / 100));
           
            return Number.parseFloat(nFixed.toFixed(2));
            //return this.CurrencyPipe.transform(item.qty * (item.PrecioConIVA * (1 - prcDto / 100)));
          }
        }
        uFaltantes = prcCantidad - item.qty;
        // });
      } 
      else if (item.oferta_semanal && item.oferta_semanal.length > 0 && item.qty > 0) {
        for (let element of item.oferta_semanal) {
          //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
          if (item.qty >= element.Cant) {    
            //en caso que sea la ultima promocion calcula el valor
            if (i >= (item.oferta_semanal.length - 1)) {
              //aca
              return item.qty * element.Precio;
              //return this.CurrencyPipe.transform(item.qty * element.Precio);
            }
            i++;
          } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
            //return this.CurrencyPipe.transform(item.qty * element.Precio);

            return item.qty * item.PrecioConIVA;
            //return this.CurrencyPipe.transform(item.qty * item.PrecioConIVA);
          }
        }

      }
      else if ( item.PrecioDestacado && item.PrecioDestacado != 0.00) {
        return item.PrecioDestacado * item.qty
        //return this.CurrencyPipe.transform(item.PrecioDestacado * item.qty);

      }
      else {
        return item.qty * item.PrecioConIVA
        //return this.CurrencyPipe.transform(item.qty * item.PrecioConIVA); //precio regular si no tiene array de precios
      }
    }

  }

    
/*
  getPUCImp(itemId) {
    let item: any = this.cartService.findItemByIdInCart(itemId);
    let prcDto = 0;
    let prcCantidad = 0;
    let uFaltantes = 0;
    let i = 0;
    if (item.precios) {
      //if (item.precios.length > 0 && item.qty > 2) {
        if (item.precios.length > 0 && item.qty > 0) {
        for (let element of item.precios) {
          //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
          if (item.qty >= element.prcCantidad) {

            prcDto = element.prcDto;
            prcCantidad = element.prcCantidad;
            //en caso que sea la ultima promocion calcula el valor
            if (i == (item.precios.length - 1)) {
              return this.dosDecimales(1 * (item.PrecioConIVA * (1 - element.prcDto / 100)));
            }
            i++;
          } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
            return this.dosDecimales(1 * (item.PrecioConIVA * (1 - prcDto / 100)));
          }
        }
        uFaltantes = prcCantidad - item.qty;
        // });
      }
      else if (item.oferta_semanal && item.oferta_semanal.length > 0 && item.qty > 0) {
      for (let element of item.oferta_semanal) {
        //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
        if (item.qty >= element.Cant) {    
          //en caso que sea la ultima promocion calcula el valor
          if (i >= (item.oferta_semanal.length - 1)) {
          return this.dosDecimales(item.qty * element.Precio);
          }
          i++;
        } else { //cuankdo no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
          return this.dosDecimales(item.qty * element.Precio);
        }
      }

    }
    else if ( item.PrecioDestacado && item.PrecioDestacado != 0.00) {
      return this.dosDecimales(item.PrecioDestacado * item.qty);

    } 
      else {
        return this.dosDecimales(1 * item.PrecioConIVA); //precio regular si no tiene array de precios
      }
    }
  }
  */
  enterChange(id, qty): void {  
    let cant: number = Number(qty);
    this.cartService.setQtyToItem(id, cant);
  }
  inputValidator(e) {
    if(parseInt(e.key) || parseInt(e.key) === 0 || e.key === "Backspace" || e.key === "Delete"){
      return true;

    }else{
      return false;

    }
  }

  //Getters para valdidar formulario
  get condIvaNoValido() {return this.forma.get('condIva').invalid && this.forma.get('condIva').touched}
  get nombreNoValido() {return this.forma.get('nombre').invalid && this.forma.get('nombre').touched}
  get cuitNoValido() {return this.forma.get('cuit').invalid && this.forma.get('cuit').touched}
  get dniNoValido() {return this.forma.get('dni').invalid && this.forma.get('dni').touched}
  get emailNoValido() {return this.forma.get('email').invalid && this.forma.get('email').touched}
  get ciudadIdNoValido() {return this.forma.get('ciudadID').invalid && this.forma.get('ciudadID').touched}
  get direccionNoValido() {return this.forma.get('direccion').invalid && this.forma.get('direccion').touched}
  get telNoValido() {return this.forma.get('tel').invalid && this.forma.get('tel').touched}
  get totalConIvaNoValido() {return this.forma.get('totalConIva').invalid && this.forma.get('totalConIva').touched}
  get totalSinIvaNoValido() {return this.forma.get('totalSinIva').invalid && this.forma.get('totalSinIva').touched}
  get costoEnvioNoValido() {return this.forma.get('costoEnvio').invalid && this.forma.get('costoEnvio').touched}
  get aclaracionesNoValido() {return this.forma.get('aclaraciones').invalid && this.forma.get('aclaraciones').touched}
  get hsEntregaNoValido() {return this.forma.get('hsEntrega').invalid && this.forma.get('hsEntrega').touched}
  get criterioIdNoValido() {return this.forma.get('criterioId').invalid && this.forma.get('criterioId').touched}
  get formaPagoNoValido() {return this.forma.get('formaPago').invalid && this.forma.get('formaPago').touched}
  get formaEnvioNoValido() {return this.forma.get('formaEnvio').invalid && this.forma.get('formaEnvio').touched}

}
