import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-monto-minimo-compra',
  templateUrl: './monto-minimo-compra.component.html',
  styleUrls: ['./monto-minimo-compra.component.scss']
})
export class MontoMinimoCompraComponent implements OnInit, AfterViewInit {
  htmlScript;
  constructor(public commonService: CommonService, public domSanitizer: DomSanitizer) { }

ngOnInit(): void {
    
}

  ngAfterViewInit(): void {
    this.getHtmlFile();
  }


  getHtmlFile() {
    this.commonService.getHtmlFileMontoMinimoCompra().subscribe(res => {
      console.log(res);
      let safeEscaped = this.domSanitizer.bypassSecurityTrustHtml(res);
      this.htmlScript = safeEscaped;
    });
  }


}
