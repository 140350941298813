<p>info-envio works!</p>
<div class="container mt-5 pt-5">
    <div class="row mt-5">
        <div class="col-12">
            <h5>Envío a domicilio</h5>
            <p>El mínimo de compra para recibir el producto en tu comercio o domicilio es de $4000. En Rosario los envíos son de lunes a viernes de 9 a 21 hs. En Funes, jueves y sábados de 9 a 21 hs. A Baigorria vamos los viernes de 9 a 17 hs y a Villa Diego los miércoles de 9 a 17 hs.</p>
            <p>Los costos del envío variarán de acuerdo a los montos de tu compra. ¡Más compras, menos envío pagas!De 4000$ a $6000 el envío es de $250. De $6000 a $10000 el envío es de $200.Más de $10000 envío sin cargo.</p>
            <p>Vas a recibir tu pedido 24 hs después de que el mismo haya ingresado a nuestro sistema y podrás seleccionar entre 3 franjas horarias para recibirlo antes de terminar tu compra:</p>
            <ul>
                <li>Primer turno de 9 a 14 hs</li>
                <li>Segundo turno de 15 a 19 hs</li>
                <li>Tercer turno de 19 a 21 hs</li>
            </ul>
        </div>   
        <div class="col-12">
            <h5>Retiro en local</h5>
            <p>Una vez finalizada la compra, tenés la opción de elegir “retiro en local”. Podes retirar tu pedido en <strong>Lamadrid 220</strong> a partir de las 6 hs de haber ingresado tu pedido al sistema. También tenemos 3 franjas horarias para que retires tu pedido que te informaremos una vez el mismo haya ingresado al sistema: </p>
            <ul>
                <li>Primer turno: de 9 a 19 hs, opción disponible para quienes hayan realizado la compra entre las 14 hs y las 17hs del día anterior.</li>
                <li>Segundo turno: de 14 a 19 hs, opción disponible para quienes hayan realizado la compra entre las 17 y las 24 hs del día anterior.</li>
                <li>Tercer turno: de 18 a 19 hs, opción disponible quienes compraron ese mismo día entre las 00 y las 13 hs.</li>
            </ul>
            <p>
                ¿Cómo se en que momento debo acercarme a retirar mi pedido?Primero, tené en cuenta el horario en el que lo hiciste. Además, te enviaremos un mensaje por WhatsApp para confirmarte la franja horaria en la que tenes que venir a retirarlo. En el caso de que la franja horaria no sea de tu comodidad, evaluaremos el caso. 
            </p>
        </div>
        <div class="col-12">
            <h5>Medios de pago</h5>
            <h5>Podes acceder a: </h5>
            <p>Podes abonar tu compra con tarjeta de crédito, débito, envío de dinero o QR a través de Mercado Pago. También, podés elegir “transferencia bancaria” y solo deberás enviarnos el comprobante de la transferencia por foto a través de WhatsApp. Importante: una misma compra solo puede ser abonada con un único medio de pago. Si sos cliente de DR Autoservicio Mayorista recordá que podés seleccionar “Acordar medio de pago con el vendedor”.</p>
            <!-- <img src="" alt=""> -->

        </div>
        <div class="col-12">
            <h5>Nuestras sucursales</h5>
            <p>DR Autoservicio Mayorista cuenta con dos locales ubicados en la ciudad de Rosario. Si preferísla compra en el local, podés acercarte a cualquiera de nuestras dos direcciones: Pavón 5068: De lunes a sábados de 9 a 17:30.Cafferata 3025: De lunes a sábados de 9 a 17:30. En esta opción estaría bueno poner un mapa en donde aparezcan las dos sucursales.</p>
        </div>
    </div>   
</div>   