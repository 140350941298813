<div class="container">
  <form class="form-signin m-3 w-100" [formGroup]="forma" (submit)="registrar(forma)">
    <div class="row justify-content-center py-md-5 ">
      <div class="col-10 card my-3">
        <div class="row justify-content-center pb-md-5 ">
          <div class="col-12 my-5">
            <h1 class="h3 mb-3 font-weight-bold text-center text-md-left">Registro</h1>
          </div>
          <div class="col-10 col-md-6">
              <div class="form-group">
                <label>Correo</label>
                <input 
                      type="email" 
                      name="correo" 
                      class="form-control"
                      formControlName="correo"
                      [class.is-invalid]="correoNoValido" />
                      <small *ngIf="correoNoValido" class="text-danger">Complete su correo</small>
              </div>
              <div class="form-group">
                <label>Nombre</label>
                <input 
                      type="text" 
                      name="nombre" 
                      class="form-control"
                      formControlName="nombre"
                      [class.is-invalid]="nombreNoValido" />
                      <small *ngIf="nombreNoValido" class="text-danger">Complete su Nombre</small>
    
              </div>
              <div class="form-group">
                <label>Apellido</label>
                <input 
                      type="text" 
                      name="nombre" 
                      class="form-control"
                      formControlName="apellido"
                      [class.is-invalid]="apellidoNoValido" />
                      <small *ngIf="apellidoNoValido" class="text-danger">Complete su Apellido</small>
    
              </div>
              <div class="form-group">
                <label>Teléfono</label>
                <input 
                      type="number" 
                      name="telefono" 
                      class="form-control"
                      formControlName="telefono"
                      [class.is-invalid]="telefonoNoValido" />
                      <small *ngIf="telefonoNoValido" class="text-danger">Complete su teléfono</small>
    
              </div>
              <div class="form-group">
                <label>Dirección</label>
                <input 
                      type="text" 
                      name="direccion" 
                      class="form-control"
                      formControlName="direccion"
                      [class.is-invalid]="direccionNoValido" />
                      <small *ngIf="direccionNoValido" class="text-danger">Complete su dirección</small>
    
              </div>
            
          </div>
          <div class="col-10 col-md-6">
              <div class="form-group">
                <label >Condición IVA</label>
                      <select class="custom-select form-control"
                              id="condIva"
                              formControlName="condIva"
                              name="condIva" 
                              [class.is-invalid]="condIvaNoValido" >
                        <option [value]="1">Resp. Inscripto</option>
                        <option [value]="3">Consumidor Final</option>
                        <option [value]="4">Exento</option>
                        <option [value]="5">Resp. Monotributo</option>
                      </select>
                      <small *ngIf="condIvaNoValido" class="text-danger">complete el campo</small>
              </div>
              <div class="form-group">
                <label>Tipo de documento</label>
                      <select class="custom-select form-control"
                              id="tipoDoc"
                              formControlName="tipoDoc"
                              name="tipoDoc" 
                              [class.is-invalid]="tipoDocNoValido" >
                        <option [value]="80">CUIT</option>
                        <option [value]="96">CUIL</option>
                        <option [value]="96">DNI</option>
                      </select>
                      <small *ngIf="tipoDocNoValido" class="text-danger">Complete su tipo de documento</small>
    
              </div>
              <div class="form-group">
                <label>Número de documento</label>
                <input 
                      type="number" 
                      name="numDoc" 
                      class="form-control"
                      formControlName="numDoc"
                      [class.is-invalid]="numDocNoValido" />
                      <small *ngIf="numDocNoValido" class="text-danger">Complete su número de documento</small>
    
              </div>
              <div class="form-group">
                <label>Ciudad</label>
                      <select class="custom-select form-control"
                              id="ciudad"
                              formControlName="ciudad"
                              name="ciudad" 
                              [class.is-invalid]="ciudadNoValido" >
                        <option [value]="1">Rosario</option>
                        <option [value]="2">Granadero Baigorria</option>
                        <option [value]="36">Funes</option>
                        <option [value]="33">Villa Gobernador Galvez</option>
                      </select>
                      <small *ngIf="ciudadNoValido" class="text-danger">Complete su ciudad</small>
    
              </div>
              <div class="form-group">
                <label>Provincia</label>
                <input 
                      type="text" 
                      name="pcia" 
                      class="form-control"
                      disabled="true"
                      value="Santa Fe"
                      formControlName="pcia"
                      [class.is-invalid]="pciaNoValido" />
                      <small *ngIf="pciaNoValido" class="text-danger">Complete su provincia</small>
    
              </div>
              <div class="form-group">
                <label>Dirección de entrega</label>
                <input 
                      type="text" 
                      name="dirEntrega" 
                      class="form-control"
                      formControlName="dirEntrega"
                      [class.is-invalid]="dirEntregaNoValido" />
                      <small *ngIf="dirEntregaNoValido" class="text-danger">Complete su dirección de entrega</small>
              </div>
              
              <button class="btn btn-lg btn-primary btn-block"  type="submit" (click)="singIn()">Registrarse</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>