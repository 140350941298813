import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegistroComponent } from './registro/registro.component';
import { LoginComponent } from './login/login.component';
import { ProductoItemComponent } from './producto-item/producto-item.component';
import { DetalleCarritoComponent } from './detalle-carrito/detalle-carrito.component';
import { ConfirmarPedidoComponent } from './confirmar-pedido/confirmar-pedido.component';
import { ListaClientesComponent } from './lista-clientes/lista-clientes.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppHttpInterceptor } from './app-http.interceptor';
import { InicioComponent } from './inicio/inicio.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { SliderCategoriasTextoComponent } from './slider-categorias-texto/slider-categorias-texto.component';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CategoriasComponent } from './categorias/categorias.component';
import { ProductoComponent } from './componentesAtomizados/producto/producto.component';
import { ProductosComponent } from './productos/productos.component';
import { BuscadorComponent } from './buscador/buscador.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusquedaComponent } from './busqueda/busqueda.component';
import { SubcategoriasComponent } from './subcategorias/subcategorias.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { ToastrModule } from 'ngx-toastr';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { ContadorCarritoComponent } from './componentesAtomizados/contador-carrito/contador-carrito.component';
import { QuienesSomosComponent } from './quienes-somos/quienes-somos.component';
import { FaqsComponent } from './faqs/faqs.component';
import { BasesCondicionesComponent } from './bases-condiciones/bases-condiciones.component';
import { ContadorTotalComponent } from './componentesAtomizados/contador-total/contador-total.component';
import { ExcerptPipe } from './pipe/excerpt.pipe';
import { ProductoMobileComponent } from './componentesAtomizados/producto-mobile/producto-mobile.component';
import { NavCategoriasComponent } from './componentesAtomizados/nav-categorias/nav-categorias.component';
import { InfoEnvioComponent } from './info-envio/info-envio.component';
import { SitioConstruccionComponent } from './sitio-construccion/sitio-construccion.component';
import { PromocionesBancariasComponent } from './promociones-bancarias/promociones-bancarias.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { MontoMinimoCompraComponent } from './monto-minimo-compra/monto-minimo-compra.component';
import { EnvioDomicilioComponent } from './envio-domicilio/envio-domicilio.component';
import { RetiroTiendaComponent } from './retiro-tienda/retiro-tienda.component';
import { MediosPagoComponent } from './medios-pago/medios-pago.component';
import { NuestrasSucursalesComponent } from './nuestras-sucursales/nuestras-sucursales.component';
import { TerminosYCondicionesComponent } from './terminos-y-condiciones/terminos-y-condiciones.component';
import { FormaParteDeNuestroEquipoComponent } from './forma-parte-de-nuestro-equipo/forma-parte-de-nuestro-equipo.component';
import { ContactoComponent } from './contacto/contacto.component';
import { MediosDePagoComponent } from './medios-de-pago/medios-de-pago.component';
import { LoginSesionComponent } from './componentesAtomizados/login-sesion/login-sesion.component';
import { InstruccionesPwaComponent } from './instrucciones-pwa/instrucciones-pwa.component';
import { CustomCurrencyPipe } from './pipe/custom-currency.pipe';
import { PerfilComponent } from './perfil/perfil.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment.prod';
import { PdfMakeWrapper } from 'pdfmake-wrapper';
import pdfFonts from "pdfmake/build/vfs_fonts";
import { RecuperarPassComponent } from './recuperar-pass/recuperar-pass.component';
import { InterceptorService } from './services/interceptor.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { BarcodeComponent} from './barcode/barcode.component';
// Set the fonts to use
PdfMakeWrapper.setFonts(pdfFonts);

@NgModule({
  declarations: [
    AppComponent,
    RegistroComponent,
    LoginComponent,
    ProductoItemComponent,
    DetalleCarritoComponent,
    ConfirmarPedidoComponent,
    ListaClientesComponent,
    InicioComponent,
    SliderCategoriasTextoComponent,
    CategoriasComponent,
    ProductoComponent,
    ProductosComponent,
    ContadorCarritoComponent,
    BuscadorComponent,
    BusquedaComponent,
    SubcategoriasComponent,
    QuienesSomosComponent,
    FaqsComponent,
    BasesCondicionesComponent,
    ContadorTotalComponent,
    ExcerptPipe,
    ProductoMobileComponent,
    NavCategoriasComponent,
    InfoEnvioComponent,
    SitioConstruccionComponent,
    PromocionesBancariasComponent,
    NosotrosComponent,
    MontoMinimoCompraComponent,
    EnvioDomicilioComponent,
    RetiroTiendaComponent,
    MediosPagoComponent,
    NuestrasSucursalesComponent,
    TerminosYCondicionesComponent,
    FormaParteDeNuestroEquipoComponent,
    ContactoComponent,
    MediosDePagoComponent,
    LoginSesionComponent,
    InstruccionesPwaComponent,
    CustomCurrencyPipe,
    PerfilComponent,
    RecuperarPassComponent,
    BarcodeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CarouselModule,
    ButtonsModule,
    ModalModule.forRoot(),
    FormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule, 
    ToastrModule.forRoot(),
    InfiniteScrollModule,
    ReactiveFormsModule,
    NgSelectModule,
   // NgMultiSelectDropDownModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      registrationStrategy: "registerImmediately"
  })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    CurrencyPipe,
    DatePipe,
    CustomCurrencyPipe,
    DecimalPipe
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
