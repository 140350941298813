<div class="container">
    <div class="row py-md-5 my-md-5 justify-content-center">
        <div class="col-12">
            <h1 class="text-center py-5">Instala la app de DR Autoservicio Mayorista</h1>
        </div>
        <div class="col-4 col-md-2">
            <div class="btn  btn-block btn-secondary font-weight-bold" [ngClass]="{'btn-active': ios == false}" (click)="getPlatform()">Android</div>
        </div>
        <div class="col-4 col-md-2">
            <div class="btn  btn-block btn-secondary font-weight-bold" [ngClass]="{'btn-active': ios == true}" (click)="getPlatform()">Ios</div>
        </div>
    </div>
</div>
<!-- IOS -->
<ng-container *ngIf="ios">
    <div class="container-fluid">
        <div class="row justify-content-center align-items-center paso-2 py-5">
            <div class="col-12 col-md-4 d-flex">
                <figure>
                    <img class="mx-auto" src="../../assets/pwa/DR Capturas-01.png">
                </figure>
            </div>
            <div class="col-12 col-md-4 d-flex justify-content-center">
                <div>
                    <h4>Abrí la web de <strong class="h4">DR Autoservicio Mayorista</strong> desde tu dispositivo IOS</h4>
                    <p>Cuando ingreses al sitio, en Safari, desplegá la primer opción que se encuentra en la parte inferior.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row justify-content-center align-items-center paso-1 py-5">
            <div class="col-12 col-md-4 d-flex">
                <figure>
                    <img class="mx-auto" src="../../assets/pwa/DR Capturas-02.png">
                </figure>
            </div>
            <div class="col-12 col-md-4 d-flex justify-content-center">
                <div>
                    <h4>Agregá el sitio al inicio</h4>
                    <p>Deslizá hasta ver la opción. Cuando agregues el sitio al inicio vas a poder nombrarlo. Una vez que termines, seleccioná <br> <strong>"Agregar"</strong></p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row justify-content-center align-items-center paso-2 py-5 ">
            <div class="col-12 col-md-4 d-flex">
                <figure>
                    <img class="mx-auto" src="../../assets/pwa/DR Capturas-03.png">
                </figure>
            </div>
            <div class="col-12 col-md-4 d-flex justify-content-center">
                <div>
                    <h4>¡Usá la<strong class="h4"> APP</strong>!</h4>
                    <p>Ahora podés disfrutar de todas las funciones de <strong>DR Autoservicio Mayorista</strong></p>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!-- android -->
<ng-container *ngIf="!ios">
    <div class="container-fluid">
        <div class="row justify-content-center align-items-center paso-2 py-5">
            <div class="col-12 col-md-4 d-flex">
                <figure>
                    <img class="mx-auto" src="../../assets/pwa/DR Capturas-04.png">
                </figure>
            </div>
            <div class="col-12 col-md-4 d-flex justify-content-center">
                <div>
                    <h4>Abrí la web de <strong class="h4">DR Autoservicio Mayorista</strong> desde tu smartphone</h4>
                    <p>Cuando ingreses al sitio vas a ver un pequeño botón al pie de la página, que te permite agregar la aplicación a tu smartphone</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row justify-content-center align-items-center paso-1 py-5">
            <div class="col-12 col-md-4 d-flex">
                <figure>
                    <img class="mx-auto" src="../../assets/pwa/DR Capturas-05.png">
                </figure>
            </div>
            <div class="col-12 col-md-4 d-flex justify-content-center">
                <div>
                    <h4>Hacé click en el botón <strong class="h4">"Agregar a la pantalla principal"</strong></h4>
                    <p>¡Listo! Ya contás con la Progressive Web App. No tenés que descargar nada desde ninguna tienda ni preocuparte por actualizaciones o el espacio libre de tu dispositivo.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row justify-content-center align-items-center paso-2 py-5 ">
            <div class="col-12 col-md-4 d-flex">
                <figure>
                    <img class="mx-auto" src="../../assets/pwa/DR Capturas-06.png">
                </figure>
            </div>
            <div class="col-12 col-md-4 d-flex justify-content-center">
                <div>
                    <h4>¡Usá la<strong class="h4"> APP</strong>!</h4>
                    <p>Ahora podés disfrutar de todas las funciones de <strong>DR Autoservicio Mayorista</strong></p>
                </div>
            </div>
        </div>
    </div>
</ng-container>