import { Component, OnInit } from '@angular/core';
import { cambiarPass } from '../interface/modelos';
import { LoginService } from '../services/login.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-recuperar-pass',
  templateUrl: './recuperar-pass.component.html',
  styleUrls: ['./recuperar-pass.component.scss']
})
export class RecuperarPassComponent implements OnInit {
  mensajeError = "El usuario o contraseña son incorrectos";
  constructor(private router: Router, private login: LoginService, private toastr: ToastrService) { }
  usuario: cambiarPass;

  ngOnInit(): void {
  }
  onSubmit(form: NgForm) {
    if (form.invalid) { return; }
  }
  onLogin() {
    this.login.login(this.usuario)
      .subscribe( async res => {
        if ( this.login.estaAutenticado() === true ) {
          //console.log('Succesfully!');
          this.router.navigate(['/inicio']);
        }
        else {
          //console.log('Not succesfully!');
          this.toastr.error(this.mensajeError);
        }
      }, (err) => {
        //console.log("error" + err);
      });
  }

}
