import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class BuscadorService {

  
  constructor(private http: HttpClient) { }
  
 
  getAll(searchTerm) {
    let header = new Headers({ "authorization": environment.HASH_API});
   
    return this.http.post(`${environment.API_URL2}`, { name : "web_getproductos" } );
   // return of(PRODUCTOS);    
  }
}