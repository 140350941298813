<div class="main-search d-inline">
  <div
    class="searchContainer mr-sm-2 busqueda"
    *ngIf="onlySearch == 'default' || onlySearch == 'onlySarch'"
  >
    <input
      class="searchBox"
      type="search"
      name="search"
      placeholder="Buscar"
      (keyup.enter)="buscar(searchTerm)"
      [(ngModel)]="searchTerm"
    />
    <i
      class="fa fa-search searchIcon rlink"
      (keyup)="onKey($event)"
      (click)="buscar(searchTerm)"
    ></i>
  </div>
  <div
    class="d-inline"
    *ngIf="onlySearch == 'default' || onlySearch == 'onlyCarrito'"
  >
    <i
      class="fa fa-shopping-cart px-2 text-white rlink m-0 p-0"
      style="font-size: 1.6em;"
      routerLink="/carrito"
      routerLinkActive="active"
    ></i>
    <app-contador-carrito
      [qty]="getNum()"
      class="contador px-1"
    ></app-contador-carrito>
    <app-contador-total [total]="totalCal()"></app-contador-total>
  </div>
</div>
