import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nav-categorias',
  templateUrl: './nav-categorias.component.html',
  styleUrls: ['./nav-categorias.component.scss']
})
export class NavCategoriasComponent implements OnInit {
  id: any;
  idNum: any;
  string: any;
  constructor(private active: ActivatedRoute) {      

    }
  ngOnInit(): void {
    this.active.paramMap.subscribe(ParamMap => {
      this.id = ParamMap.get('id');
      this.idNum = ParamMap.get('idnumerico');
      this.string = ParamMap.get('string');
    });
  }

}
