<ng-template #template >
  <div  style="z-index:9999">
     <div class="modal-header" v>
       <h4 class="modal-title pull-left">Filtrar Por</h4>
       <button type="button"
        class="close pull-right" 
        aria-label="Close"
        (click)="modalRef.hide()">
         <span aria-hidden="true">&times;</span>
       </button>
     </div>
     <div class="modal-body">
       <div class="row">
         <div class="col-12">
           <div class="form-group">

               <div  *ngIf="this.mobileMode == 1" 
               class="input-group justify-content-end">
                  <div class="input-group-prepend ">
                    <label style="height: 2.4rem;"
                      class="input-group-text select-orden" 
                    for="inputGroupSelect02">Filtrar</label>
                  </div>
               
                 
                  <ng-select
                  (remove)="onFilterRemove($event)"
                  [searchable]="true"
                  (clear)="onMultiSelectClear()"
                  [closeOnSelect]="false"
                    class="multiSelect"
                  [multiple]="true"
                  (change)="onItemSelect($event)"
                   [items]="multiSelectList"
                   [(ngModel)]="selectedItems" 
                   placeholder="Marca"
                    bindLabel="name"
                    bindValue="id">
                  
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/>  {{item.name}}
                      </ng-template>
                      <ng-template ng-footer-tmp>
                       <label  
                       
                       style="cursor: pointer; justify-content: center; color: #202a5d; font-weight: 500;"
                      (click)="onFilter()" 
                      class="m-1  input-group-text select-orden"
                      for="filtrar">Filtrar</label>
                      </ng-template>
                  </ng-select>
          </div> 


           </div>
         </div>
       </div>
   </div>
  
</div>
</ng-template>

<div class="container-fluid" infiniteScroll [infiniteScrollDistance]="2"  (scrolled)="onScroll()">
  <div class="row justify-content-center">



    <div class="col-12 pt-5 mt-md-5 ios-mt">
      <div class="row row-prod">
        <div class="col-6  ">
                 <div  class="input-group">
                    <div class="input-group-prepend ">
                      <label class="input-group-text select-orden" for="inputGroupSelect01">Ordenar por</label>
                    </div>
                    <select class="col-md-2 custom-select"
                     id="inputGroupSelect01"
                      (change)="ChangingValue($event)" 
                     [(ngModel)]="selectedProduct">
                      <option [value]="0"> A->Z</option>
                      <option [value]="1">Z->A </option>
                      <option [value]="2">Precio menor</option>
                      <option [value]="3">Precio mayor</option>
                    </select>
                 </div>
                </div>

      <div class="col-6 pt-1 d-flex justify-content-end">
        <div class="d-flex justify-content-end">
            <div  *ngIf="this.mobileMode == 0" 
             class="input-group justify-content-end">
                <div class="input-group-prepend ">
                  <label style="height: 2.4rem;"
                    class="input-group-text select-orden" 
                  for="inputGroupSelect02">Filtrar por</label>
                </div>
             
<ng-select
(remove)="onFilterRemove($event)"
[searchable]="true"
[clearable]="true"   
(clear)="onMultiSelectClear()"
[closeOnSelect]="false"
class="multiSelect"
[multiple]="true"
(change)="onItemSelect($event)"
[items]="multiSelectList"
[(ngModel)]="selectedItems" 
placeholder="Marca"
bindLabel="name"
bindValue="id">

<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
</ng-template>
<ng-template ng-footer-tmp>
<label  
style="cursor: pointer; justify-content: center; color: #202a5d; font-weight: 500;"
(click)="onFilter()" 
 class="m-1  input-group-text select-orden"
for="filtrar">Filtrar</label>
</ng-template>
</ng-select>                 
            </div> 
            <label [ngClass]="{'filterMarcasMobile': filterActive}"  (click)="onDialogOpen(template)" *ngIf="this.mobileMode == 1" style="height: 2.4rem; width: 7rem; cursor: pointer;" class="input-group-text">Filtrar por <i [ngStyle]="{'color': filterActive ? '#202a5d' : '' }" class="pl-2 fas fa-sliders-h"></i></label>   
        </div>
      </div>

      
      
      <h1>Buscando: <small>{{ terminos }}</small></h1>
      <hr>
    </div>
      <div class="row animated fadeIn fast " *ngIf="productos.length === 0 && loading">
          <div class="col-md-12 justify-content-center d-flex">
                <img src="/assets/img/loading.gif" class="gif mx-auto" alt="loading" />
          </div>
      </div>
      <div class="row animated fadeIn fast" *ngIf="productos.length === 0 && loading === false">
          <div class="col-md-12">
              <div class="alert alert-info">
                  No existen resultados con el término : {{ terminos }}
              </div>
          </div>
      </div>

    </div>
    <div class="col-12 py-5">
      <div class="row justify-content-center p-0">
        <div class="col-6">
        
          <ngx-spinner
            bdColor="rgba(51,51,51,0.8)"
            size="medium"
            color="#fff"
            type="ball-scale-multiple"
          >
            <p style="font-size: 20px; color: white">Cargando...</p>
          </ngx-spinner>    
      
        </div>
      </div>
      <div class="row justify-content-center justify-content-md-start p-0">
        <ng-container  *ngFor="let producto of arregloProd">
          <div class="col-12 col-md-4 col-lg-3 col-xl-2 mx-0 my-md-3 p-0" *ngIf="producto">
            <div class="producto-item p-md-3">
              <app-producto [producto]="producto"></app-producto>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<button [ngClass]="'no-hidden'"
*ngIf="showGoUpButton" class="btn btn-dark"
(click)="scrollTop()"><i class="fas fa-arrow-circle-up"></i></button>
