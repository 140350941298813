import { AfterViewInit, Component, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-envio-domicilio',
  templateUrl: './envio-domicilio.component.html',
  styleUrls: ['./envio-domicilio.component.scss']
})
export class EnvioDomicilioComponent implements OnInit, AfterViewInit {
  htmlScript;
  constructor(public commonService: CommonService, public domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    this.getHtmlFile();
  }


  getHtmlFile() {
    this.commonService.getHtmlFileEnviosDomicilio().subscribe(res => {
      console.log(res);
      let safeEscaped = this.domSanitizer.bypassSecurityTrustHtml(res);
      this.htmlScript = safeEscaped;
    });
  }


  

}
