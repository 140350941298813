import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from './login.service';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';


@Injectable({
  providedIn: 'root'
})
export class InterceptorService {
  constructor(public loginSvc: LoginService){}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }
    let token = this.loginSvc.getToken();
    const headers = new HttpHeaders({
      'authorization': `${token}`
    });
    const reqClone = req.clone({
      headers
    });
    return next.handle(reqClone).pipe(
      catchError( this.manejarError)
    );
  }
  manejarError( error: HttpErrorResponse) {
    //console.log('Sucedio un error');
    //console.log('Registrado en el log file');
    //console.log(error);
    return throwError('Error personalizado');
  }
}
