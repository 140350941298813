import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss']
})
export class NosotrosComponent implements OnInit, AfterViewInit {
  htmlScript;
  constructor(public commonService: CommonService, public domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    this.getHtmlFile();
  }


  getHtmlFile() {
    this.commonService.getHtmlFileNosotros().subscribe(res => {
      console.log(res);
      let safeEscaped = this.domSanitizer.bypassSecurityTrustHtml(res);
      this.htmlScript = safeEscaped;
    });
  }

}
