import { Component, OnInit, Host, Input } from '@angular/core';
import { BuscadorService } from '../services/buscador.service';
import { forkJoin } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { CarritoSharedService } from '../services/carrito-shared.service';
@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.scss'],
})
export class BuscadorComponent implements OnInit {
  @Input('onlySearch') public onlySearch?;
  public searchTerm;
  public num = 2;
  public total: number;
  public searchResults = [];
  
  constructor(
    private router: Router,
    private buscadorService : BuscadorService, private Router: Router,
    public CarritoSvc: CarritoSharedService
  ) { 
    Router.events.subscribe( (event) => {
      if (event instanceof NavigationEnd ) {
        let url = event.url;
        if( url.startsWith('/busqueda', 0)){
        }
        else {
          this.searchTerm = '';          
        }
      }
    });
  }

  ngOnInit() {
    console.log(this.onlySearch);
    this.num = this.CarritoSvc.getItemsLenth();
    this.total = this.totalCal();
  }
  ngOnChanges() {
    this.num = this.CarritoSvc.getItemsLenth();
    this.total = this.totalCal();
  }
  
  buscar(termino:string) {
    this.router.navigate(['/busqueda', termino] );
  }

  onKey(event: any) { //TODO ejecutar la búsqueda al hacer click enter!
  }

  totalCal():number {
    return this.CarritoSvc.totalCal();
  }
    /*
    let items = this.CarritoSvc.getItems();
    let prcDto = 0;
    let prcCantidad = 0;
    let i = 0;
    let total = 0;
    if(items){
      for (let productos of items) {
        total = total + this.CarritoSvc.totalPriceByItem(productos.prdProductoId); 
      } 
      return total;
*/
      /*
      return items.reduce(function (acc, item) {
        if (item.precios.length > 0 && item.qty > 2) {
          for (let element of item.precios) {
            //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
            if (item.qty >= element.prcCantidad) {    
              prcDto = element.prcDto;
              prcCantidad = element.prcCantidad;
              //en caso que sea la ultima promocion calcula el valor
              if (i >= (item.precios.length - 1)) {
                return acc + (item.qty * (item.PrecioConIVA * (1 - prcDto / 100)));
              }
              i++;
            } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuent0
              return acc + (item.qty * (item.PrecioConIVA * (1 - prcDto / 100)));
            }
          }
        } else if (item.oferta_semanal && item.oferta_semanal.length > 0 && item.qty > 0) {
          for (let element of item.oferta_semanal) {
            //Recorre el arreglo hasta no poder superar una cantidad de unidades de promocion
            if (item.qty >= element.Cant) {    
              //en caso que sea la ultima promocion calcula el valor
              if (i >= (item.oferta_semanal.length - 1)) {
                return acc + (item.qty * element.Precio);
              }
              i++;
            } else { //cuando no puede superar un numero de unidades sabemos en que promocion se encuentra saca el calculo con descuento
              
              return acc + (item.qty * item.PrecioConIVA);
            }
          }

        }
        else if ( item.PrecioDestacado && item.PrecioDestacado != 0.00) {
          return acc + (item.PrecioDestacado * item.qty);
          
        }
        else {
          return acc + (item.PrecioConIVA * item.qty);
        }
      }, 0);
         */
  getNum(){
    return this.CarritoSvc.getProductosTotales();
  }
}
