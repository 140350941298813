<div class="container">
  <div class="row justify-content-center py-5">
      <div class="col-10 col-md-4 my-5 card p-3">
          <form class="form-signin m-3">
              <h1 class="h3 mb-3 font-weight-normal">Ingrese:</h1>
              <label for="inputEmail" class="sr-only">Mail</label>
              <input 
                type="email" 
                id="inputEmail" 
                class="form-control my-3" 
                placeholder="Mail" 
                required 
                autofocus
                [(ngModel)]="usuario.email"
                name="email"
                [class.danger]="email.invalid && email.touched"
                email
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                #email="ngModel">
              <label for="inputPassword" class="sr-only">Clave</label>
              <input 
                type="password"
                id="inputPassword" 
                class="form-control mb-3" 
                placeholder="Clave" 
                required
                [(ngModel)]="usuario.password"
                [class.danger]="pass.invalid && pass.touched"
                name="password"
                #pass="ngModel"
                minlength="3">
              <div class="checkbox mb-3">
                <label>
                  <input type="checkbox" value="Recuérdame">   
                </label>
                <label class="pl-2">
                  ¿No tienes cuenta? <a routerLink="/registro">Registrate</a>
                </label>
              </div>
              <button class="btn btn-lg btn-primary btn-block"  type="submit" (click)="onLogin()">Iniciar sesión</button>
            </form>

      </div>
  </div>
</div>
        