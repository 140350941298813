import { Injectable } from '@angular/core';
import { ProductosService } from './productos.service';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusquedaService {
  public productos: any;
  constructor(private productosService: ProductosService) {
    let httpCalls = forkJoin( //permite hacer varias llamadas de una
      // this.productosService.getAll()
    );
   
    httpCalls.subscribe( //para cualquier respuesta 
      (data: any) => {
        this.productos = data[0].data; //cada posición de data[i] coincide con las posiciones del array que mandamos con forkjoin

      },
      (error) => { //en caso de error http
      
      }
    );
   }

   buscarProductos( termino:string ){
    let prodArr:Productos[] = [];
    termino = termino.toLowerCase();

    for( let i = 0; i < this.productos.length; i++ ){
      let producto = this.productos[i];
      let nombre = producto.prdNombre.toLowerCase();
      
      if( nombre.indexOf(termino) >= 0 ){
        producto.idx = i;
        prodArr.push( producto )
      }
    }
    return prodArr;
  }
   
}

export interface Productos{
  prdProductoId: number; 
  prdNombre: string;
  prdCodigo: string;
  prdNroCodigo: number;
  CODIGO: string;
  PrecioSinIVA: number;
  prcCantidad: number;
  prcDto: number;
  impAlicuota: number;
  PrecioConIVA: number;
  idx?: number;

};
