import { Injectable } from '@angular/core';
import {Md5} from 'ts-md5/dist/md5';
import { DatePipe } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class CrearHashService {
  myDate = new Date();
  Date: string;
  hashFinal: any;
  constructor(
    private datePipe: DatePipe) {}

    crearHash(param, date?) {
      const md5 = new Md5();
      if(date){
        this.Date = this.datePipe.transform(this.myDate, date);
      }
      else {
        this.Date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
      }
      let hash = param;
      hash = hash.concat(this.Date.toString());
      //console.log("hash: ",hash);
      this.hashFinal = md5.appendStr(hash).end();
      return this.hashFinal;
    }
}
