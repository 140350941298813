import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-medios-pago',
  templateUrl: './medios-pago.component.html',
  styleUrls: ['./medios-pago.component.scss']
})
export class MediosPagoComponent implements OnInit, AfterViewInit {
  htmlScript;
  constructor(public commonService: CommonService, public domSanitizer: DomSanitizer) { }
 
 ngOnInit(): void {
     
 }
 
  ngAfterViewInit(): void {
    this.getHtmlFile();
  }


  getHtmlFile() {
    this.commonService.getHtmlFileMediosDePago().subscribe(res => {
      console.log(res);
      let safeEscaped = this.domSanitizer.bypassSecurityTrustHtml(res);
      this.htmlScript = safeEscaped;
    });
  }

}
