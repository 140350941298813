<div class="container">
    <div class="row justify-content-center py-5">
        <div class="col-10 col-md-4 my-5 card p-3">
            <form class="form-signin m-3">
                <h1 class="h3 mb-3 font-weight-normal">Actualizar contraseña</h1>
                <label for="inputEmail" class="sr-only">Mail</label>
                <input type="email" id="inputEmail" class="form-control my-3" placeholder="Mail" required autofocus [(ngModel)]="usuario.email" name="email" [class.danger]="email.invalid && email.touched" email pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" #email="ngModel">
                <label for="inputPassword" class="sr-only">Clave Actual</label>
                <input type="password" id="inputPassword" class="form-control mb-3" placeholder="Clave Actual" required [(ngModel)]="usuario.password" [class.danger]="pass.invalid && pass.touched" name="password" #pass="ngModel" minlength="3">
                <label for="inputNewPassword" class="sr-only">Clave Nueva</label>
                <input type="password" id="inputNewPassword" class="form-control mb-3" placeholder="Clave Nueva" required [(ngModel)]="usuario.newPassword" [class.danger]="newPass.invalid && newPass.touched" name="newPassword" #newPass="ngModel" minlength="3">
                <button class="btn btn-lg btn-primary btn-block" type="submit" (click)="onUpdate()">Modificar</button>
            </form>

        </div>
    </div>
</div>