import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instrucciones-pwa',
  templateUrl: './instrucciones-pwa.component.html',
  styleUrls: ['./instrucciones-pwa.component.scss']
})
export class InstruccionesPwaComponent implements OnInit {
  ios = false;
  constructor() { }

  ngOnInit(): void {
  }
  getPlatform() {
    this.ios = !this.ios;
    return this.ios;
  }
}
