<div class="container mt-5">
    <div class="row mt-5">
        <div class="col-12 mt-md-5 p-md-3">
            <h5>Términos y Condiciones</h5>
            <p>Los <b>Términos y Condiciones</b> (en adelante, las “Términos y Condiciones”) que se establecen a continuación  son  aplicables  a  todas  las  personas  físicas  y/o  jurídicas  que  accedan  a  los contenidos  y  servicios  que  DR  Comercializadora  S.A.  (en  adelante,  “DR  Autoservicio”  o “Autoservicio  Mayorista")  preste  mediante  la  página  web  _____  (en  adelante  indistintamente,  “DR Autoservicio Mayorista”).<br>El  registro  personal,  suministro  de  datos,  utilización  y  navegación  en  Autoservicio  Mayorista significa  que  Ud.  ha  leído,  entendido  y  aceptado,  plena  y  expresamente,  los  Términos  y Condiciones que aquí se establecen. Si no está de acuerdo con lo anterior, debe abstenerse de usar  DR  Autoservicio  Mayorista  y/o  de  brindar  información  personal  a  través  de  la  misma.<br>Autoservicio Mayorista podrá modificar y/o interrumpir el servicio ofrecido mediante la web, en forma permanente o transitoria, sin aviso previo y/o consentimiento del Usuarios.</p>
        </div>
        <div class="col-12">
            <p><b>1. Usuario - Contraseña:</b> se entiende por Usuario a las personas físicas, mayor de 18 años, o jurídicas,  que  se  registre  en  este  sitio  web,  completando,  en  forma  libre  y  veraz,  los  datos  de identificación personal que se le soliciten, con el objeto de acceder a los contenidos y servicios que brinda DR Autoservicio Mayorista.<br><br>Cada Usuario deberá asociar una contraseña de uso personal. Será exclusiva responsabilidad del  Usuario  mantener  en  secreto  la  contraseña,  no  divulgarla  a  terceros,  así  como  también verificar   la   seguridad   y   protección   de   las   computadoras   que   utilice   contra   software malintencionado.<br><br>En   caso   de   ser   necesario,   DR   Autoservicio   Mayorista   podrá   solicitar   documentación complementaria o datos adicionales con el fin de verificar los datos personales de los Usuarios, así  como  también  podrá  suspender  a  aquellos  Usuarios  cuyos  datos  no  hayan  podido  ser confirmados.</p>
            <p><b>2.</b> La información personal que Usted suministra es totalmente confidencial y será reservada en forma  estricta  entre  el  Usuario  y  Autoservicio  Mayorista.  En  ningún  caso  será  entregada  a terceros</p>
            <p><b>3.</b> DR Autoservicio Mayorista, es un canal de ventas mayoristas, por ello las compras tienen un mínimo. Para acceder al servicio de compras on line, se requiere una compra mínima de $4000.</p>
            <p><b>4.</b> Los productos que se comercializan, se encuentran sujetos a la disponibilidad de stock. En caso de faltantes de productos o de falta de stock, Ud. podrá optar por:</p>
            <ol style="list-style-type: lower-alpha;">
                <li>No sustituir producto.</li>
                <li>Admitir  su  reemplazo  a  criterio  de  DR  Autoservicio  Mayorista.</li>
                <li>Requerir  consulta  de sustitución mediante llamado telefónico.</li>
            </ol>
            <p><b>5.</b> Los productos que se adquieran por peso podrán variar en su peso y por tanto en su precio final. Las fotos / imágenes de los productos son meramente ilustrativas.</p>
            <p><b>6.</b> Nos se expenderán bebidas alcohólicas a menores de 18 años (Ley 24.788).</p>
            <p><b>7.</b> Los precios de los productos incluyen IVA. Si usted necesita que se discrimine el IVA deberá registrar  sus  datos  fiscales  al  momento  de  generar  el  Usuario  y  adjuntar  la  constancia  de inscripción en la AFIP. <br><br>Los precios de los productos se facturarán conforme a las condiciones comerciales y vigentes al momento en que el usuario confirme la compra respectivamente en DR Autoservicio Mayorista. En tal sentido, se establece que el día de compra corresponde a la fecha en que se confirma y cierra el pedido en la Autoservicio Mayorista. <br><br> Mientras no se confirme y cierre el pedido los productos que permanezcan el carrito se regirán por  las  condiciones  comerciales  (precios  y  promociones)  correspondientes  al  día  en  que  se confirma, cierra y abona el pedido.</p>
            <p><b>8.</b> Los  pagos  de  los  productos  pueden  hacerse  con  tarjeta  de  crédito  o  débito  por  medio  de Mercado  Pago.  Se  admite  también  el  envío  de  dinero  vía  Mercado  Pago  o  Transferencia Bancaria. <br><br>Quienes  sean  clientes  de  la  empresa  deben  seleccionar  “si  sos  cliente,  podes  optar  por  otros medios  de  pago”  dentro  de  los  que  se  encuentra:  pago  en  efectivo  en  el  domicilio,  pago  con tarjeta en el domicilio, pago en efectivo o con tarjeta al momento del retiro o pago con cuenta corriente.  Estas  opciones  SÓLO  ESTÁN  DISPONIBLES  para  clientes  de  DR  Autoservicio Mayorista  previamente  autorizados,  una  vez  finalizada  la  compra,  nos  contactaremos  via WhatsApp para cerrar el pago. <br><br>Es indispensable para abonar con tarjeta de crédito o débito presentar tarjeta y el dni del titular de la compra, debido a que el reapartidor deberá tomar el relieve de la tarjeta en el momento en que te entregan el pedido, caso contrario no se podrá entregar el pedido. <br><br>Si  el  pago  se  efectúa  con  tarjetas  de  crédito  en  la  entrega  se  deberá  exhibir  el  documento. <br><br> <b>El pago con tarjeta de DéBITO (emisión y firma del cupón) se concreta en el momento de la  entrega  del  pedido  en  el  domicilio  del  cliente,  quien  deberá  presentar  además  de  la tarjeta, el correspondiente DNI del titular de la misma.</b> <br><br><b>Para las compras siguientes, si el cliente decide que otra persona en su nombre reciba el pedido  en  su  domicilio,  el  cliente  deberá  dejar  explícito  quién  recibirá  el  pedido  (adulto mayor   de   18   años).   La   persona   que   recibe   el   pedido   deberá   hacerlo   con   su correspondiente identificación a fin de verificar su identidad (DNI) y presentar al cadete la tarjeta de crédito y una copia del documento de identidad del titular.</b></p>
            <p><b>9. Entregas :</b><br><br><b>A domicilio,</b> se efectuarán siempre que se encuentre dentro de las zonas previamente definidas: Rosario, Funes, Baigorria y Villa Diego. <br><br><b>Retiro en Sucursal,</b> se retirarán en Lamadrid 220, Rosario</p>
            <p><b>10. Monto mínimo de Compra y Costo de entrega de los productos objeto de pedidos:</b> El monto mínimo de compra a través del Sitio Web es de $4000.- para las compras con entrega a domicilio  y  entregas  en  la  Sucursal.  El  costo  del  envío  a  domicilio  variará  (ver  en  envío  a domicilio) de acuerdo al monto de compra.</p>
            <p><b>11. Horario de entregas. Ver en envíos a domicilio y retiro en sucursal.</b></p>
            <p><b>12.</b> DR Comercializadora S.A es agente de retención / percepción, por lo que en los casos que corresponda,   se   efectuarán   las   retenciones   o   percepciones   que   fijen   las   autoridades competentes.</p>
            <p><b>13.</b> No se permite la utilización del Sitio Web para para fines distintos a los aquí establecidos. Las  cuentas  y  usuarios  son  personales,  por  lo  que  no  se  admite  la  utilización  de  cuentas  de terceros.  Autoservicio  Mayorista  podrá  a  su  solo  arbitrio  y  discreción,  dar  de  baja  cuentas  o Usuarios cuyos nombres considere ofensivo o ilegal.</p>
            <p><b>14.</b> Ud. reconoce y acepta que todo el material y contenidos publicados en el Sitio Web, incluidos -  pero  no  limitado  a  -  textos,  software,  gráficos,  fotografías,  audio,  música,  videos,  contenidos interactivos  y  similares,  pertenecen  a  DR  Autoservicio  Mayorista  o  le  han  sido  otorgados Autoservicio  Mayorista  bajo  licencia,  encontrándose  protegidos  por  derechos  de  propiedad intelectual.</p>
            <p><b>15.</b> Su registración en el Sitio Web y la aceptación de estas Condiciones de Uso, implica que Ud. presta su consentimiento expreso, libre e informado para la inclusión, uso y tratamiento de sus datos personales por DR Autoservicio Mayorista en los términos de la ley 25.326.</p>
            <p><b>16.</b> Domicilio, ley aplicable y jurisdicción: El domicilio legal de DR Comercializadora S.A es el de la  calle  <b>Pavón  4058</b>  de  Rosario,  Provincia  de  Santa  Fe.  Estas  Condiciones  de  Uso  han  sido redactadas y deberán ser interpretadas de acuerdo con las leyes de la República Argentina. Ud. acuerda que cualquier disputa, conflicto o divergencia que pudiera derivarse de la utilización del Sitio Web y de las Condiciones de Uso se someterá a la jurisdicción de los Tribunales Ordinarios de  la  ciudad  de  Rosario,  Provincia  de  Santa  Fe,  con  exclusión  de  cualquier  otro  fuero  o jurisdicción que pudiere corresponder.</p>
            <p><b>17.</b> Atención al Cliente: <br><br>Por  cualquier  consulta  comunícate  con  el  CENTRO  DE  ATENCIÓN  AL  CLIENTE  de  lunes  a sábado, de 9.00 a 20.30 hs. <br><br> <b>Para   consultas   técnicas   comunícate   con   el   4642198   o   envíanos   un   e-mail   a drautoserviciomayorista@gmail.com</b></p>
        </div>
    </div>
</div>