import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider-categorias-texto',
  templateUrl: './slider-categorias-texto.component.html',
  styleUrls: ['./slider-categorias-texto.component.scss']
})
export class SliderCategoriasTextoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
