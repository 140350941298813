import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-sesion',
  templateUrl: './login-sesion.component.html',
  styleUrls: ['./login-sesion.component.scss']
})
export class LoginSesionComponent implements OnInit {
  islogged: boolean;
  constructor(private LoginService: LoginService, private Router: Router) { }

  ngOnInit(): void {
  }
  isLogged() {
    this.islogged = this.LoginService.estaAutenticado();
    return this.LoginService.estaAutenticado();
  }
  logOut() {
    this.LoginService.logOut();
    this.Router.navigateByUrl["/inicio"];
  }
}
