import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class EnviarPedidoService {

  constructor(private http : HttpClient) { }

  enviar() {
    return this.http.post(environment.API_URL2, "test");
  }

  getCostosDeEnvio():Observable<any> {
      return this.http.get(environment.API_URL2 + '/costoenvio');
  }

  getCostoMinimoDeCompra() {
    return this.http.get(environment.API_URL2 + '/compraminima');
  }  


}
