import { Component, OnInit, HostListener, AfterViewInit, ChangeDetectorRef, TemplateRef, ViewChild, Renderer2 } from '@angular/core';
import { CategoriasService } from './services/categorias.service';
import { forkJoin, Observable, of } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import * as $ from "jquery";
import { ScrollTopServiceService } from './services/scroll-top-service.service';
import { LoginService } from './services/login.service';
import { DomSanitizer} from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from './services/common.service';

//import fs 'im'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent implements OnInit, AfterViewInit {
  modalImgUrl:any;
  modalRef: BsModalRef;
  title = 'distribuciones-rosario';
  home: boolean;
  WSP: boolean;
  string: string;
  stringPadre: string;
  selectedItem: any;
  public categorias = [];
  islogged: boolean;
  hover: boolean;
  pathParam: Observable<string>;
  subcategorias = ['subcat1', 'subcat2', 'subcat3'];
  isMobile = false;
  isTable = false;
  isOnBarcodeRoute = false;
  isOnCarrito = false;
  @ViewChild('modalInicioTemplate') modalInicioTemplate: any; 

  @ViewChild('spanColapsable') spanColapsable: any; 
  plusMin;
  constructor(
      public render2: Renderer2,
      public cd: ChangeDetectorRef,
      public CategoriasService: CategoriasService,
      private Router: Router, private active: ActivatedRoute,
      public modalService: BsModalService,
      public commonService: CommonService,
      public scrollTopService: ScrollTopServiceService, 
      private LoginService: LoginService,
      public renderer2: Renderer2, 
      private sanitizer: DomSanitizer){
    Router.events.subscribe( (event) => {
      if (event instanceof NavigationEnd ) {
        if( event.url == '/inicio' || event.url == '/' || event.url == '/inicio;url=ofertas'){
          this.home = false;
        }
        else {
          this.home = true;
        }
        if(event.url == '/bar-code') {
          console.log(event.url)
          this.isOnBarcodeRoute = true
        }else {
          this.isOnBarcodeRoute = false;
        }
        if( event.url !== '/carrito'){
          this.WSP = false;
          this.isOnCarrito = false;
        }
        else {
          this.WSP = true;
          this.isOnCarrito = true;
          console.log(this.isOnBarcodeRoute);
        }
      }
    });

   


  }
  @HostListener('mouseenter') 
  onMouseEnter() {
    this.hover = true;
  }

  @HostListener('mouseleave') 
  onMouseLeave() {
    this.hover = false;
  }
  ngOnInit(): void {

    this.pathParam = this.CategoriasService.pathParam;
 
    this.pathParam.subscribe(resp => {
    
      this.selectedItem = resp;
      this.cd.detectChanges();
    })
    this.isLogged();
    scroll();
    this.scrollTopService.setScrollTop();
    let httpCalls = forkJoin( //permite hacer varias llamadas de una
      this.CategoriasService.getAll()
    );
    httpCalls.subscribe( //para cualquier respuesta
      (data: any) => {
        this.categorias = data[0].data; //cada posición de data[i] coincide con las posiciones del array que mandamos con forkjoin
      },
      (error) => { //en caso de error http
      }
    );
  }

ngAfterViewInit() {
 this.onCheckMobile();
 
  
}

onCheckMobile() {
    const ua = navigator.userAgent;

    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
       // return "tablet";
       console.log('is tablet');
       this.isTable = true;
       this.commonService.initModal().subscribe(res => {
        console.log(res);
        var data = res['data'];
          if(data) {
            this.modalImgUrl = 'https://drautoservicio.com.ar/assets/img/DRA_popup-desktop.jpg';// this.createImageFromBlob(res);
            this.openModal();
          
          } else {
           
          }
      });
 
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      // return "mobile";
      this.isMobile = true;
      console.log('is mobile');
    //modal
      this.commonService.initModal().subscribe(res => {
        console.log(res);
        var data = res['data'];
          if(data) {
            this.modalImgUrl = 'https://drautoservicio.com.ar/assets/img/DRA_popup-mobile.jpg';// this.createImageFromBlob(res);
            this.openModal();
          
          }else {
            
          }
      });
     
    }else {
      console.log('is desktop');
      this.isMobile = false;
      this.isTable = false;
      //modal
      this.commonService.initModal().subscribe(res => {
        console.log(res);
        var data = res['data'];
          if(data) {
            this.modalImgUrl = 'https://drautoservicio.com.ar/assets/img/DRA_popup-desktop.jpg';// this.createImageFromBlob(res);
            this.openModal();
          }else {
         
          }
      });
     // return "desktop";
    }

    if ( screen.width == 1024 &&  screen.height == 1366 ){
      //  Ipad Pro
          this.isTable = true;
          this.isMobile = false;
      }
    

    
      console.log(this.isTable);
      console.log(this.isMobile);

}

/*
createImageFromBlob(imageBlob: Blob) {
  const reader = new FileReader();
  reader.readAsDataURL(imageBlob); 
  let objectURL = URL.createObjectURL(imageBlob);   
  return this.sanitizer.bypassSecurityTrustUrl(objectURL);
}
*/

  
  ngOnChanges() {
    this.isLogged();
  }

  onPlusMinusSpanClick(e, icon) {
  

      if(icon.classList.contains('fa-plus')) {
        this.renderer2.removeClass(icon, 'fa-plus');
        this.renderer2.addClass(icon, 'fa-minus');
        console.log(icon);
        return;
      }
      if(icon.classList.contains('fa-minus')) {
        this.renderer2.removeClass(icon, 'fa-minus');
        this.renderer2.addClass(icon, 'fa-plus');
        console.log(icon);
        return;
      }
  }

  goCat(seo, id, element){
    var mobileNavbar = document.getElementById('navbarNav');
    this.renderer2.removeClass(mobileNavbar, 'show');
    console.log(mobileNavbar);
    this.Router.navigateByUrl('/categorias/' + seo + '/' +id);
  }
  goSubCat(seoPadre, idPadre, seo, id){
    if(document.getElementById('navbarNav')) {
      var mobileNavbar = document.getElementById('navbarNav');
      this.renderer2.removeClass(mobileNavbar, 'show');
    }



    
   
    console.log(mobileNavbar);

    this.Router.navigateByUrl('/subcategoria/' + seoPadre + '/' + idPadre +'/' + seo + '/' + id);

    
  }

  switch(expr){
    switch (expr) {
      case 'BEBIDAS':
        return '../assets/cat-rubro/bebidas.png';
        break;
      case 'COMESTIBLES':
        return '../assets/cat-rubro/comestibles.png';
        break;
      case 'GOLOSINAS':
        return '../assets/cat-rubro/golosinas.png';
        break;
      case 'LIMPIEZA E INSECTICIDA':
        return '../assets/cat-rubro/limpieza.png';
        break;
      case 'PERFUMERIA E HIG. PERS.':
        return '../assets/cat-rubro/perfumeria.png';
        break;
      case 'PRODUCTOS DE FIESTAS':
        return '../assets/cat-rubro/productos-fiesta.png';
        break;
      case 'MASCOTAS':
        return '../assets/cat-rubro/mascotas.png';
        break;
      default:
        return '../assets/cat-rubro/autoservicio.png';
      break;
    }
  }

  scroll(){
    $(window).scroll(function () {
      if (screen.width > 768) {
          if ($("#menu").offset().top > 56 ) {
              $("#menu-cat").addClass("d-none");
              $("header").addClass("fixed-top");
              $("#menu-cat").removeClass("d-block");
          } else {
              $("#menu-cat").addClass("d-block");
              $("#menu-cat").removeClass("d-none");
              // $("#menu").removeClass("shadow-sm");
          }
      } else {
          $("#menu").addClass("footerVioleta");

      }
  });
  }
  isLogged() {
    return this.LoginService.estaAutenticado();
  }
  mouseEnter(boolean) {
    this.hover = boolean;
  }
  mouseLeave(boolean) {
    this.hover = boolean;
  }
  logOut() {
    this.LoginService.logOut();
  }
  sanitizarUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  downloadMyFile(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'https://drautoservicio.com.ar/PHP_generador_listas/lista.pdf');
    link.setAttribute('download', `lista.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }


  deferredPrompt: any;
  showButton = false;

  @HostListener('window:beforeinstallprompt', ['$event']) onbeforeinstallprompt(e) {
    //console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }


  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          //console.log('User accepted the A2HS prompt');
        } else {
          //console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }

  onNavigateOfertas() {
    this.Router.navigate(['/inicio', {url: 'ofertas'}])
  }


  closeHomeScreen(){
    this.showButton = false;
  }

 //Abro modal
 openModal() {
  console.log('opening the modal');
  console.log(this.modalInicioTemplate);
 //var template = ((document.getElementsByClassName('.modalInicioTemplate') as HTMLCollectionOf<HTMLElement>));
 // console.log(this.modalInicioTemplate);
  //Abre el modal
  this.modalRef = this.modalService.show(this.modalInicioTemplate, {
    class: 'modal-lg'
  });

}  


closeModal(event){
 this.modalRef.hide();
}










}
