import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject ,of} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class  CommonService {

    constructor(private http: HttpClient) { 
       
    }


    initModal() {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
      headers.append('Cache-Control', 'no-cache');
      const options = {headers: headers };
      return this.http.post<Response>(`${environment.API_URL2}` + "/initpopup", options);
    }



    getAllSliders() {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Cache-Control', 'no-cache');
        const options = {headers: headers };
        return this.http.post<Response>(`${environment.API_URL2}` + "/getqttyslide", options);
      }

    getProductoByBarCode(BarCode) {
        let headers = new HttpHeaders();
        let params = new FormData();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Cache-Control', 'no-cache');
        params.append('BarCode', BarCode)
        console.log(BarCode);

        const options = {headers: headers };
        return this.http.post<Response>(`${environment.API_URL2}` + "/obtenerbarcode",
        params, options );
      }



      getHtmlFileEnviosDomicilio():Observable<any> {
        return this.http.get('assets/static/envio_domicilio.html', {responseType: 'text'})
      }

      getHtmlFileNosotros():Observable<any> {
        return this.http.get('assets/static/nosotros.html', {responseType: 'text'})
      }

      getHtmlFileMontoMinimoCompra():Observable<any> {
        return this.http.get('assets/static/monto_minimo_compra.html', {responseType: 'text'})
      }

      getHtmlFileMediosDePago():Observable<any> {
        return this.http.get('assets/static/medios_de_pago.html', {responseType: 'text'})
      }

      getHtmlFileRetiroEnTienda():Observable<any> {
        return this.http.get('assets/static/retiro_en_tienda.html', {responseType: 'text'})
      }

      getHtmlFileTerminosYCondiciones():Observable<any> {
        return this.http.get('assets/static/terminos_y_condiciones.html', {responseType: 'text'})
      }
      
      getHtmlFileFormaParteDeNuestroEquipo():Observable<any> {
        return this.http.get('assets/static/forma_parte_de_nuestro_equipo.html', {responseType: 'text'})
      }
      getHtmlFileFormaPreguntasFrecuentes():Observable<any> {
        return this.http.get('assets/static/preguntas_frecuentes.html', {responseType: 'text'})
      }

      getHtmlFileSucursales():Observable<any> {
        return this.http.get('assets/static/nuestras_sucursales.html', {responseType: 'text'})
      }

      getHtmlFilePromocionesBancarias():Observable<any> {
        return this.http.get('assets/static/promociones_bancarias.html', {responseType: 'text'})
      }

      /*
      getHtmlFile():Observable<any> {
        return this.http.get('assets/static/envio_domicilio.html', {responseType: 'text'})
      }
      */



     



}