import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-retiro-tienda',
  templateUrl: './retiro-tienda.component.html',
  styleUrls: ['./retiro-tienda.component.scss']
})
export class RetiroTiendaComponent implements OnInit {

  htmlScript;
  constructor(public commonService: CommonService, public domSanitizer: DomSanitizer) { }
 
 ngOnInit(): void {
     
 }
 
  ngAfterViewInit(): void {
    this.getHtmlFile();
  }


  getHtmlFile() {
    this.commonService.getHtmlFileRetiroEnTienda().subscribe(res => {
      console.log(res);
      let safeEscaped = this.domSanitizer.bypassSecurityTrustHtml(res);
      this.htmlScript = safeEscaped;
    });
  }


}
