import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bases-condiciones',
  templateUrl: './bases-condiciones.component.html',
  styleUrls: ['./bases-condiciones.component.scss']
})
export class BasesCondicionesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.scrollTop();
  }
  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }

}
