import { Component, OnInit, Input } from '@angular/core';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/es-AR';
registerLocaleData(localeFr, 'es-AR');


@Component({
  selector: 'app-contador-total',
  templateUrl: './contador-total.component.html',
  styleUrls: ['./contador-total.component.scss']
})
export class ContadorTotalComponent implements OnInit {
  @Input() total: number;
  constructor(public CurrencyPipe: CurrencyPipe) { }

  ngOnInit(): void {
  }

}
