import { Component, OnInit, Input } from '@angular/core';
import { CarritoSharedService } from '../../services/carrito-shared.service';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-producto-mobile',
  templateUrl: './producto-mobile.component.html',
  styleUrls: ['./producto-mobile.component.scss']
})
export class ProductoMobileComponent implements OnInit {
  public noClickeado = true;
  @Input() producto: any;
  mySubscription: any;
  loading: boolean = true;


  constructor(
    private carritoService: CarritoSharedService, private Router: Router, private toastr: ToastrService) {
      this.Router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.mySubscription = this.Router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // Trick the Router into believing it's last link wasn't previously loaded
          this.Router.navigated = false;
        }
      });
     }

  ngOnInit(): void {}
  ngOnChanges(): void {}
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
  addProducto(producto) {
    let productoEnCarrito;
    productoEnCarrito = this.carritoService.findItemByIdInCart(producto.prdProductoId);
    //lo buscamos si está en el carrito para agregarle la qty, sino encuentra, es porque se agrega de 0
    
    if(productoEnCarrito) {
      producto.qty = productoEnCarrito.qty;
    }
    this.carritoService.agregarProducto(producto);
    this.noClickeado = false;
    this.agregarToast(producto.prdNombre);

  }
  
  goProducto(seo,id){
    this.Router.navigateByUrl('/productos/' + seo + '/' + id);
  }

  minusQty(id, qty, selector){
    var $id = selector;
    var input = <HTMLInputElement>document.getElementById($id);
    var num = parseFloat((<HTMLInputElement>document.getElementById($id)).value);
    
    if( num > 0){ //Para que no haya pedidos negativo      
      this.carritoService.substractQtyToItem(id, qty);
      input.stepDown();
      // this.restarToast(selector);

    }

  }
  plusQty(id, qty, selector){
    var $id = selector;
    var input = <HTMLInputElement>document.getElementById($id);
    this.carritoService.addQtyToItem(id, qty);
    input.stepUp();
    // this.sumarToast(selector);

  }
  onLoad() {
    this.loading = false;
  }
  agregarToast(nombre) {
    this.toastr.success(nombre, 'Se ha agregado exitosamente');
  }
  sumarToast(nombre) {
    this.toastr.success(nombre, 'Se ha sumado una unidad');
  }
  restarToast(nombre) {
    this.toastr.success(nombre, 'Se ha restado una unidad');
  }

}

