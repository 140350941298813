import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  usuario: UsuarioModel = new UsuarioModel();
  user: any;
  mensajeError = "El usuario o contraseña son incorrectos"
  constructor(private router: Router, private login: LoginService, private toastr: ToastrService) { }

  ngOnInit() {}
  onSubmit(form: NgForm) {
    if (form.invalid) { return; }
  }
  onLogin() {
    this.login.login(this.usuario)
      .subscribe( async res => {
        if ( this.login.estaAutenticado() === true ) {
          //console.log('Succesfully logged in!');
          this.router.navigate(['/inicio']);
        }else {
          //console.log('Not succesfully logged in!');
          this.toastr.error(this.mensajeError);
        }
      }, (err) => {
        //console.log("error" + err);
      });
  }
  asd(){
    this.login.showAPi();
  }

}
export class UsuarioModel {
  email: string;
  password: string; 
}
