import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  htmlScript;
  constructor(public commonService: CommonService, public domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.scrollTop();
  }

  ngAfterViewInit(): void {
    this.getHtmlFile();
  }


  getHtmlFile() {
    this.commonService.getHtmlFileFormaPreguntasFrecuentes().subscribe(res => {
      console.log(res);
      let safeEscaped = this.domSanitizer.bypassSecurityTrustHtml(res);
      this.htmlScript = safeEscaped;
    });
  }

  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }

}
