<!-- Carrousel de promociones en Home -->
<!--ousel>
  <slide>
    <img src="https://via.placeholder.com/200" alt="first slide" style="display: block; width: 100%;">
  </slide>
  <slide>
    <img src="https://via.placeholder.com/200" alt="second slide" style="display: block; width: 100%;">
  </slide>
  <slide>
    <img src="https://via.placeholder.com/300" alt="third slide" style="display: block; width: 100%;">
  </slide>
</>-->
<div class="container-fluid p-0 carousel-img">
    <div class="row m-0">
        <div class="col p-0 carouselInicioContainer" >

            <carousel  [isAnimated]="true"  [showIndicators]="true">
                <slide  *ngFor="let slide of this.carrouselSlideTotal">
                  <img src="../assets/img/slide{{slide + 1}}.jpg" style="display: block; width: 100%;">
                </slide>
               
              </carousel>
       
          
            <!--
            <div *ngIf="this.carrouselSlideTotal.length !== 0"
            id="carouselExampleIndicators"
            class="carousel slide carousel-padding" 
            data-ride="carousel">
                <ol
                 class="carousel-indicators">
                    <li  *ngFor="let slide of this.carrouselSlideTotal"
                     data-target="#carouselExampleIndicators" 
                     [attr.data-slide-to]="slide + 1" 
                     [ngClass]="{'active': slide == 0}"
                     ></li>
                </ol>
                <div class="carousel-inner sliders"  *ngFor="let slide of this.carrouselSlideTotal">
                    <div class="carousel-item "  [ngClass]="{'active': slide == 0}">
                        <img src="../assets/img/slide{{slide + 1}}.jpg" class="d-block w-100 img-fluid">
                    </div>
                </div>
            -->
        <!-- <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a> 
            </div>
            -->
        </div>
    </div>
</div>
<div class="container-fluid mt-0" #ofertas>
  <!--
    <div class="row py-2 det pt-0" id="det">
        <carousel class="w-100 carousel-home" [showIndicators]="false" [itemsPerSlide]="itemsPerSlide" [singleSlideOffset]="singleSlideOffset" [noWrap]="noWrap" class="py-3 mt-0">
            <slide *ngFor="let categoria of categorias" class="slide-item-img">
                <div (click)="goCat(categoria.catDescripcion, categoria.catCategoriaId)">
                    <img [src]="switch(categoria.catDescripcion)" class="mx-auto d-inline-block py-2 img-cat" />
                    <h6 class="text-uppercase font-weight-bold pb-3 mt-3 text-center d-none d-md-block w-100 rlink" >{{categoria.catDescripcion}}</h6>
                    <span class="text-center w-100 font-weight-bold d-inline d-md-none rlink">{{categoria.catDescripcion}}</span>
                </div>

            </slide>
        </carousel>
    </div>
  -->
    <div class="row" >
        <app-producto-item  class="w-100"></app-producto-item>
    </div>
</div>

<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple"
>
<p style="font-size: 20px; color: white">Cargando...</p>
</ngx-spinner>  